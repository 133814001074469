
import { defineComponent } from 'vue'
import Contact from '@/components/profile/profile_details/contact.vue'
import Financial from '@/components/profile/profile_details/financial.vue'
import Personal from '@/components/profile/profile_details/personal.vue'

export default defineComponent({
  components: {
    Contact,
    Personal,
    Financial,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
})
