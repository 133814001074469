export {}

declare global {
  interface Date {
    toGermanDate(withTime?: boolean): string
  }
}

Date.prototype.toGermanDate = function(withTime = true) {
  return this.toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: withTime ? '2-digit' : undefined,
    minute: withTime ? '2-digit' : undefined,
  })
}
