
import { defineComponent, onMounted, PropType } from 'vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import FileList from '@/components/common/list/file-list.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import CheckBox from '@/components/common/form/checkbox.vue'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { FileList, FileUploadList, CheckBox },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateLoanContractStep = payload => {
      BuyJourneyModule.updateLoanContractStep({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_loan_contract.id,
        payload,
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    const createSelfFinancedDocuments = async (
      files: File[],
      resetCallback: Function
    ) => {
      const bodyFormData = new FormData()
      files.forEach(file => bodyFormData.append(file.name, file))
      try {
        await BuyJourneyModule.createSelfFinancedDocuments({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_loan_contract.id,
          payload: bodyFormData,
        })
        toast.success(t(TC.YOUR_FILES_HAVE_BEEN_UPLOADED))
        resetCallback()
      } catch (error) {
        toast.error(getMessage(error))
      }
    }

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_loan_contract.has_visited_step) {
        updateLoanContractStep({
          has_visited_step: true,
          is_expanded: true,
        })
      }
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      updateLoanContractStep,
      createSelfFinancedDocuments,
      t,
      TC,
    }
  },
})
