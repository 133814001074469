<template>
  <div
    class="w-full mx-auto
    md:mx-0 md:max-w-full md:px-8"
  >
    <vertical-list-skeleton-loader :isLoading="isLoading" :itemHeight="40">
      <div
        v-if="buyJourneys.length > 0"
        class="md:flex md:flex-wrap md:w-full md:justify-start"
      >
        <div
          class="my-6 md:my-4 md:pr-8"
          v-for="buyJourney in buyJourneys"
          :key="buyJourney.id"
        >
          <InvestmentCard
            :buyJourney="buyJourney"
            @open="openModal"
          ></InvestmentCard>
        </div>
      </div>
      <div class="md:flex md:flex-col md:w-full md:items-center" v-else>
        <InvestmentNotFoundPlaceholder />
      </div>
    </vertical-list-skeleton-loader>

    <router-view v-slot="{ Component }">
      <transition name="fade-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script lang="ts">
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import InvestmentCard from '@/components/investments/investment-card/index.vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as Routes from '@/constants/router-constants'
import InvestmentNotFoundPlaceholder from '@/components/dashboard/investment-not-found-placeholder/index.vue'
import { LoadingModule } from '@/store/modules/loading.module'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { InvestmentCard, InvestmentNotFoundPlaceholder },
  setup() {
    const { push } = useRouter()
    const toast = useToast()
    const { getMessage } = useError()

    const isLoading = computed(() => LoadingModule.isInvestmentsListLoading)
    const buyJourneys = computed(() => BuyJourneyModule.buyJourneys)

    function openModal(buyJourney: BuyJourneyModelInterface) {
      push({
        name: Routes.INVESTMENT_DETAIL.name,
        params: { buy_journey_id: buyJourney.id },
      })
    }

    onBeforeMount(async () => {
      BuyJourneyModule.getBuyJourneys().catch(error => {
        toast.error(getMessage(error))
      })
    })

    return {
      buyJourneys,
      openModal,
      isLoading,
    }
  },
})
</script>
