
import { defineComponent, PropType } from 'vue'
import { RealEstateIconModelInterface } from '@/models/real-estate-icon.model'

export default defineComponent({
  props: {
    icon: {
      type: Object as PropType<RealEstateIconModelInterface>,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
})
