<template>
  <div class="my-2 mx-4">
    <div v-if="buyJourney.step_calculation.calculation_preset">
      <p class="font-bold">{{ t(TC.CALCULATION_PRESETS) }}</p>
      <FileList
        :documents="buyJourney.step_calculation.calculation_preset.documents"
      />
    </div>
    <p class="text-xs font-bold mt-4">
      {{ t(TC.WANT_TO_CHECK_YOUR_CALCULATIONS) }}
    </p>
    <div class="relative">
      <FileUploadList
        @on-upload="createUserCalculationDocuments"
        :placeholderText="t(TC.UPLOAD_YOUR_CALCULATIONS)"
        :disabled="buyJourney.step_calculation.is_completed"
      />
      <OverlaySpinnerLoader :isLoading="isUploading" />
    </div>
    <ClickableText @click="toggleStep" class="mt-4">
      {{
        buyJourney.step_calculation.is_completed
          ? t(TC.REOPEN_STEP)
          : t(TC.FINISH_STEP)
      }}
    </ClickableText>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from 'vue'
import * as TC from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'
import FileList from '@/components/common/list/file-list.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import useError from '@/composables/use-error'
import { useToast } from 'vue-toastification'
import OverlaySpinnerLoader from '@/components/common/loader/overlay-spinner-loader.vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import { LoadingModule } from '@/store/modules/loading.module'
import ClickableText from '@/components/common/clickable-text.vue'

export default defineComponent({
  components: {
    FileList,
    FileUploadList,
    OverlaySpinnerLoader,
    ClickableText,
  },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const isUploading = computed(
      () => LoadingModule.isUploadCalculationDocumentsLoading
    )
    const { getMessage } = useError()

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_calculation.has_visited_step) {
        BuyJourneyModule.updateCalculationStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_calculation.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    const createUserCalculationDocuments = async (
      files: File[],
      resetCallback: Function
    ) => {
      const bodyFormData = new FormData()
      files.forEach(file => bodyFormData.append(file.name, file))
      try {
        await BuyJourneyModule.createUserCalculationDocuments({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_calculation.id,
          payload: bodyFormData,
        })
        toast.success(t(TC.YOUR_FILES_HAVE_BEEN_UPLOADED))
        resetCallback()
      } catch (error) {
        toast.error(getMessage(error))
      }
    }

    const toggleStep = async () => {
      BuyJourneyModule.updateCalculationStep({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_calculation.id,
        payload: {
          is_completed: !props.buyJourney.step_calculation.is_completed,
          is_expanded: props.buyJourney.step_calculation.is_completed,
        },
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      createUserCalculationDocuments,
      isUploading,
      toggleStep,
      t,
      TC,
    }
  },
})
</script>
