
import { defineComponent, watch } from 'vue'
import { useField } from 'vee-validate'
import ErrorMessage from '@/components/common/form/error-message.vue'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String || Number,
    },
    rules: [Object, String],
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { value: v, handleInput, errorMessage } = useField(
      props.name,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.value,
      }
    )

    const updateValue = event => {
      emit('update:modelValue', event.target.value)
      emit('input', event.target.value)
      handleInput(event.target.value)
    }

    watch(props, () => {
      handleInput(props.value)
    })

    return {
      v,
      errorMessage,
      updateValue,
    }
  },
})
