<template>
  <div class="bg-primary py-8 space-y-8">
    <HeaderWithLogoAuthScreen />
    <div class="w-full flex flex-col justify-center">
      <p class="text-center text-white text-sm">{{ t(TC.RESET_PASSWORD) }}</p>
    </div>
  </div>
  <div class="w-full max-w-3xl mx-auto px-8 mt-12">
    <p class="text-sm mb-8">{{ t(TC.FORGOT_PASSWORD_DESCRIPTION) }}</p>
    <ForgotPasswordForm />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ForgotPasswordForm from '@/components/authorization/forgot-password-form.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants.ts'
import HeaderWithLogoAuthScreen from '@/components/main-header/header-with-logo-auth-screen.vue'

export default defineComponent({
  components: {
    HeaderWithLogoAuthScreen,
    ForgotPasswordForm,
  },
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
</script>
