<template>
  <ModalWrapper v-if="proposal" @click.self="closeModal">
    <content-skeleton-loader :isLoading="isLoading">
      <!-- Modal Header Bar -->
      <div
        class="top-0 left-0 w-full flex justify-center items-center h-20 bg-very-light-grey z-50"
      >
        <HeaderTitle :text="proposal.real_estate.name" />
        <div class="absolute right-0 mr-6">
          <button @click="closeModal">
            <svg-icon name="x" class="text-medium-gray"></svg-icon>
          </button>
        </div>
      </div>
      <!-- Modal Body -->
      <transition name="slide-left" mode="out-in">
        <ProposalContent
          v-if="contentPage === 1"
          :proposal="proposal"
          @clicked="contentPage++"
        />
        <ProposalConfirmation
          v-else-if="contentPage === 2"
          :text="proposal.real_estate.letter_of_interest"
          :confirmationType="t(TC.LETTER_OF_INTEREST)"
          :buttonLabel="t(TC.CONTINUE)"
          @clicked="contentPage++"
          @back="contentPage--"
        />
        <ProposalConfirmation
          v-else-if="contentPage === 3"
          :text="proposal.real_estate.consulation_protocol_and_disclaimer"
          :confirmationType="t(TC.CONSULATION_PROTOCOL_AND_DISCLAIMER)"
          :buttonLabel="t(TC.CONTINUE)"
          @clicked="contentPage++"
          @back="contentPage--"
        />
        <ProposalConfirmation
          v-else-if="contentPage === 4"
          :text="proposal.real_estate.cancellation_policy"
          :confirmationType="t(TC.CANCELLATION_POLICY)"
          :buttonLabel="t(TC.START_BUY_JOURNEY)"
          @clicked="createBuyJourney"
          @back="contentPage--"
        />
      </transition>
    </content-skeleton-loader>
  </ModalWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import ModalWrapper from '@/components/common/modal/modal-wrapper.vue'
import HeaderTitle from '@/components/common/typography/header-title.vue'
import ProposalContent from '@/components/proposals/proposal-modal/content/index.vue'
import ProposalConfirmation from '@/components/proposals/proposal-modal/confirmation/index.vue'
import { ProposalModelInterface } from '@/models/proposal.model'
import { useRouter } from 'vue-router'
import { PROPOSALS } from '@/constants/router-constants'
import useError from '@/composables/use-error'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import { useToast } from 'vue-toastification'
import * as Routes from '@/constants/router-constants'
import { LoadingModule } from '@/store/modules/loading.module'

export default defineComponent({
  components: {
    ModalWrapper,
    HeaderTitle,
    ProposalContent,
    ProposalConfirmation,
  },
  setup() {
    const { currentRoute, replace, back } = useRouter()
    const t = useTranslation()
    const toast = useToast()
    const proposal = ref<ProposalModelInterface | null>(null)
    const isLoading = computed(() => LoadingModule.isProposalLoading)
    const contentPage = ref(1)
    const { getMessage } = useError()

    const closeModal = () => {
      // Reset scroll position, that was persisted in `openModal`
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      replace({
        name: PROPOSALS.name,
      })
    }

    const createBuyJourney = async () => {
      if (proposal.value) {
        try {
          const buyJourney = await BuyJourneyModule.createBuyJourney(
            proposal.value.id
          )
          toast.success(t(TC.BUY_JOURNEY_CREATED))
          await replace({
            name: Routes.INVESTMENT_DETAIL.name,
            params: { buy_journey_id: buyJourney.id },
          })
        } catch (error) {
          toast.error(getMessage(error))
        }
      }
    }

    onBeforeMount(async () => {
      const proposalId = currentRoute.value.params['proposal_id'] as string
      const result = BuyJourneyModule.proposals.find(
        proposal => proposal.id === proposalId
      )
      if (result) {
        proposal.value = result
      } else {
        try {
          await BuyJourneyModule.getProposal(proposalId)
          proposal.value = BuyJourneyModule.openProposal
        } catch (error) {
          back()
          toast.error(getMessage(error))
        }
      }
    })

    return {
      proposal,
      closeModal,
      isLoading,
      contentPage,
      createBuyJourney,
      t,
      TC,
    }
  },
})
</script>

<style scoped>
/* taiwind has no possibility of making a child of a transparent parent opaque */
.remove-transparency {
  background-color: rgba(255, 255, 255, 1);
}
</style>
