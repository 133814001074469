interface CrispInterface {
  push(arg0: Array<string | Function>): void
  is(arg0: string): boolean
}

interface WindowInterface {
  $crisp?: CrispInterface
  CRISP_WEBSITE_ID?: string
  CRISP_READY_TRIGGER?: Function
}

interface OnCrispOpenedArgs {
  onChatOpened?: Function
}

let crispInstance: CrispInterface

export default function useCrispButton() {
  function initCrisp() {
    const win = window as WindowInterface
    return new Promise<CrispInterface>((resolve, reject) => {
      if (crispInstance) {
        return reject()
      }
      win.$crisp = {} as CrispInterface
      win.CRISP_WEBSITE_ID = 'e06558d3-8ac5-4391-b74e-f3ecf7205811'
      const crispElement = document.createElement('script')
      crispElement.src = 'https://client.crisp.chat/l.js'
      crispElement.async = true
      document.getElementsByTagName('head')[0].appendChild(crispElement)
      win.CRISP_READY_TRIGGER = function() {
        if (win.$crisp) {
          crispInstance = win.$crisp
          resolve()
        }
      }
    })
  }

  function _revealChatDelayed(onCrispOpened?: Function) {
    // Reveal with a delay to prevent the button from flickering into visibility
    return setTimeout(() => {
      crispInstance?.push(['do', 'chat:show'])
      onCrispOpened && onCrispOpened()
    }, 500)
  }

  function showCrispButton() {
    crispInstance?.push(['do', 'chat:show'])
  }

  function hideCrispButton() {
    if (crispInstance?.is('chat:opened')) {
      return
    }
    crispInstance?.push(['off', 'chat:opened', () => _revealChatDelayed()])
    crispInstance?.push(['do', 'chat:hide'])
  }

  function openChat({ onChatOpened }: OnCrispOpenedArgs = {}) {
    crispInstance?.push([
      'on',
      'chat:opened',
      () => _revealChatDelayed(onChatOpened),
    ])
    crispInstance?.push(['do', 'chat:open'])
  }

  function hideCrispButtonOnChatClose() {
    crispInstance?.push(['on', 'chat:closed', () => hideCrispButton()])
  }

  function showCrispButtonOnChatClose() {
    crispInstance?.push(['off', 'chat:closed', () => hideCrispButton()])
  }

  function onCrispOpened(callback: Function) {
    crispInstance?.push(['on', 'chat:opened', () => setTimeout(callback, 500)])
  }

  return {
    initCrisp,
    showCrispButton,
    hideCrispButton,
    openChat,
    hideCrispButtonOnChatClose,
    showCrispButtonOnChatClose,
    onCrispOpened,
  }
}
