<template>
  <div class="md:px-8">
    <Form
      @submit-form="onSubmitProfileForm"
      @changed="hasFormChanged = true"
      @keydown.enter.stop="onSubmitProfileForm"
    >
      <content-skeleton-loader :isLoading="isLoading">
        <div
          class="hidden md:flex md:items-center md:space-x-1 md:font-light md:text-xl md:text-primary md:mb-12"
        >
          <div v-if="getFullName">
            {{ t(translationConstants.PROFILE_GREETING) + getFullName }},
          </div>
          <p>
            {{ t(translationConstants.PROFILE_GREETING_END) }}
          </p>
        </div>
        <Header
          @toggleIsProfileEditable="resetFormValues"
          :user="user"
          class="md:hidden"
        />
      </content-skeleton-loader>
      <vertical-list-skeleton-loader :isLoading="isLoading">
        <TabbedView
          class="md:hidden"
          @tab-change="handleTabChange"
          :tabs="tabs"
          :invalidTabs="invalidTabs"
        />
        <div
          class="hidden md:flex md:flex-col lg:flex-row md:w-full lg:space-x-8 sm:space-x-8 md:space-x-0"
        >
          <div class="text-lg md:shadow bg-white py-8 md:w-full">
            <div class="font-normal text-primary pl-8 mb-6">
              {{ t(translationConstants.TAB_TITLE_PROFILE) }}
            </div>
            <ProfileDetails :data="user" />
          </div>
          <div class="text-lg md:shadow bg-white py-8 md:w-full">
            <div class="font-normal text-primary pl-8 mb-16">
              {{ t(translationConstants.TAB_TITLE_SEARCH_PROFILE) }}
            </div>
            <SearchProfileDetails :data="user" />
          </div>
        </div>
        <div
          class="
          fixed bottom-0 md:right-0 w-full px-24 md:pl-72 md:pr-16 md:ml-6
          transition-transform duration-500 ease-in-out transform"
          :class="
            hasFormChanged
              ? '-translate-y-12'
              : ' translate-y-0 md:translate-y-16'
          "
        >
          <div class="md:w-72 md:relative md:mx-auto md:pl-8">
            <Button class="md:h-10">
              <SpinnerLoader :isLoading="isUpdateLoading">{{
                t(translationConstants.SAVE)
              }}</SpinnerLoader>
            </Button>
          </div>
        </div>
      </vertical-list-skeleton-loader>
    </Form>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import useTranslation from '@/composables/use-translation'
import Header from '@/components/profile/header/index.vue'
import ProfileDetails from '@/components/profile/profile_details/index.vue'
import SearchProfileDetails from '@/components/profile/search_profile/index.vue'
import Button from '@/components/common/form/button.vue'
import Form from '@/components/common/form/form.vue'
import TabbedView, {
  TabInterface,
} from '@/components/common/tabbed-view/index.vue'
import * as translationConstants from '@/constants/translation-constants.ts'
import { UserModule } from '@/store/modules/user.module'
import { useForm } from 'vee-validate'
import { useToast } from 'vue-toastification'
import { keys, remove, includes } from 'lodash'
import * as propertyConstants from '@/constants/property-constants.ts'
import { UserModelInterface } from '@/models/user.model'
import { LoadingModule } from '@/store/modules/loading.module'
import SpinnerLoader from '@/components/common/loader/spinner-loader.vue'
import { SearchProfileModelInterface } from '@/models/search-profile.model'

export default defineComponent({
  components: {
    TabbedView,
    Header,
    Button,
    Form,
    SpinnerLoader,
    ProfileDetails,
    SearchProfileDetails,
  },
  setup() {
    const t = useTranslation()
    const hasFormChanged = ref(false)
    const user = computed(() => UserModule.user)
    const isLoading = computed(() => LoadingModule.isUserProfileLoading)
    const isUpdateLoading = computed(
      () => LoadingModule.isUpdateUserProfileLoading
    )
    const { resetForm, handleSubmit, errors } = useForm()
    const errorMessage = ref('')
    const invalidTabs = reactive<string[]>([])
    const tabs = computed((): TabInterface[] => [
      {
        text: t(translationConstants.TAB_TITLE_PROFILE),
        contentComponent: ProfileDetails,
        props: {
          data: UserModule.user,
        },
      },
      {
        text: t(translationConstants.TAB_TITLE_SEARCH_PROFILE),
        contentComponent: SearchProfileDetails,
        props: {
          data: UserModule.user,
        },
      },
    ])
    const getFullName = computed(() => {
      if (!user.value) {
        return ''
      }
      return `${user.value.first_name} ${user.value.last_name}`.trim()
    })

    onBeforeMount(async () => {
      await UserModule.verifyAuthentication()
    })

    onMounted(async () => {
      await UserModule.loadUserProfileSelectLists()
    })

    const toast = useToast()

    const onSubmitProfileForm = handleSubmit(async values => {
      if (!hasFormChanged.value) {
        return
      }

      const searchProfilePayload = {
        modified_at: values[propertyConstants.MODIFIED_AT],
        investment_volume: values[propertyConstants.INVESTMENT_VOLUME],
        region: values[propertyConstants.REGION],
        investment_strategy: values[propertyConstants.INVESTMENT_STRATEGY],
        investment_goal_reason:
          values[propertyConstants.INVESTMENT_GOAL_REASON],
        investment_requirements:
          values[propertyConstants.INVESTMENT_REQIUREMENTS],
        wants_newsletter: values[propertyConstants.NEWSLETTER],
        real_estate_types: values[propertyConstants.REAL_ESTATE_TYPES]
          .filter(data => Object.values(data)[0] === true)
          .map(data => Object.keys(data)[0]),
        investment_effort_types: values[
          propertyConstants.INVESTMENT_EFFORT_TYPES
        ]
          .filter(data => Object.values(data)[0] === true)
          .map(data => Object.keys(data)[0]),
      } as SearchProfileModelInterface

      const addressData = values[propertyConstants.ADDRESS].reduce(
        (map, obj) => {
          map[Object.keys(obj)[0]] = Object.values(obj)[0]
          return map
        }
      )

      const userPayload = {
        address: addressData,
        first_name: values[propertyConstants.FIRST_NAME],
        last_name: values[propertyConstants.LAST_NAME],
        email: values[propertyConstants.EMAIL],
        birthday: values[propertyConstants.BIRTHDAY],
        phone: values[propertyConstants.PHONE],
        job: values[propertyConstants.JOB],
        salary: values[propertyConstants.SALARY],
        equity: values[propertyConstants.EQUITY],
        real_estate_count: values[propertyConstants.REAL_ESTATE_COUNT],
        employment_status: values[propertyConstants.EMPLOYMENT_STATUS],
      } as UserModelInterface

      const profileData: Record<
        'userProfilePayload' | 'searchProfilePayload',
        UserModelInterface | SearchProfileModelInterface
      > = {
        userProfilePayload: userPayload,
        searchProfilePayload,
      }

      UserModule.updateUserProfileAndSearchProfile(profileData)
        .then(() => {
          toast.success(t(translationConstants.PROFILE_SAVE_SUCCESS))
          hasFormChanged.value = false
        })
        .catch(() => {
          toast.error(t(translationConstants.PROFILE_SAVE_ERROR))
        })
    })

    const handleTabChange = async (switchTabCallback: Function) => {
      switchTabCallback()
    }

    const resetFormValues = () => {
      hasFormChanged.value = false
      resetForm()
    }

    watch(errors, () => {
      const errorKeys = keys(errors.value)
      const userProfileKeys = keys(user.value)
      const userSearchProfileKeys = keys(user.value?.user_search_profile)
      const searchProfileErrorKeys = errorKeys.filter(errorKey => {
        return includes(userSearchProfileKeys, errorKey)
      })
      const profileErrorKeys = errorKeys.filter(errorKey => {
        return includes(userProfileKeys, errorKey)
      })

      if (searchProfileErrorKeys.length > 0) {
        invalidTabs.push(tabs.value[1].text)
      } else {
        remove(invalidTabs, tab => tab === tabs.value[1].text)
      }
      if (profileErrorKeys.length > 0) {
        invalidTabs.push(tabs.value[0].text)
      } else {
        remove(invalidTabs, tab => tab === tabs.value[0].text)
      }
    })

    return {
      tabs,
      translationConstants,
      t,
      user,
      onSubmitProfileForm,
      resetFormValues,
      handleTabChange,
      isLoading,
      hasFormChanged,
      errorMessage,
      invalidTabs,
      isUpdateLoading,
      getFullName,
    }
  },
})
</script>
