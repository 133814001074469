
import { defineComponent, PropType } from 'vue'
import RatingSlider from '@/components/common/rating-slider/index.vue'
import { RealEstateRatingModelInterface } from '@/models/real-estate-rating.model'

export default defineComponent({
  props: {
    rating: {
      type: Object as PropType<RealEstateRatingModelInterface>,
      required: true,
    },
  },
  components: {
    RatingSlider,
  },
})
