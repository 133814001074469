<template>
  <div
    class="hidden md:flex md:bottom-0 md:items-center md:justify-end md:space-x-2 md:pb-6 md:px-2"
  >
    <div
      class="relative flex flex-row items-center justify-center w-20 h-20 rounded-full overflow-hidden border-4 border-white md:shadow-md"
    >
      <img
        v-if="user?.avatar"
        :src="user?.avatar"
        :alt="user?.last_name"
        class="w-full h-full object-cover object-center cursor-pointer"
      />
      <svg-icon v-else name="profile" size="32px" class="text-white" />

      <input
        @input="uploadUserAvatar"
        id="avatar"
        class="absolute top-0 left-0 w-full h-full text-0 opacity-50 cursor-pointer"
        type="file"
        accept=".png, .jpg, .jpeg"
      />
    </div>

    <router-link
      tag="div"
      :to="PROFILE.path"
      class="flex-1 break-words text-center font-poppings"
    >
      <p class="line-clamp-2">
        {{ `${user?.first_name} ${user?.last_name}`.trim() }}
      </p>
    </router-link>

    <div @click="logout" class="p-2 cursor-pointer">
      <fa class="h-4" icon="sign-out-alt" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PROFILE } from '@/constants/router-constants'
import useTranslation from '@/composables/use-translation'
import { ARE_YOU_SURE_TO_LOGOUT } from '@/constants/translation-constants'
import { UserModule } from '@/store/modules/user.module'
import { SIGN_IN } from '@/constants/router-constants'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'
import { AVATAR_SUCCESSFULLY_UPDATED } from '@/constants/translation-constants'

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const { replace } = useRouter()
    const toast = useToast()
    const { getMessage } = useError()
    const uploadUserAvatar = event => {
      if (event.target.files.length) {
        const file: File = event.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        UserModule.updateAvatar(formData)
          .then(() => {
            toast.success(t(AVATAR_SUCCESSFULLY_UPDATED))
          })
          .catch(error => {
            toast.error(getMessage(error))
          })
      }
    }
    const logout = () => {
      const result = confirm(t(ARE_YOU_SURE_TO_LOGOUT))
      if (result === true) {
        UserModule.logout().finally(() => {
          replace({ name: SIGN_IN.name }).then(async () => {
            UserModule.SET_USER(null)
          })
        })
      }
    }

    return {
      PROFILE,
      logout,
      uploadUserAvatar,
    }
  },
})
</script>
