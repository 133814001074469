<template>
  <ul
    class="text-xs border mt-2 p-2 space-y-2 overflow-y-auto"
    :class="maxHeightStyle"
  >
    <li
      v-for="document of documents"
      :key="document.id"
      class="flex items-center"
    >
      <a
        class="ml-2 flex items-center overflow-x-hidden"
        :href="document.file_path"
        target="_blank"
      >
        <fa class="w-3 text-primary" :icon="downloadIcon" />
        <p class="ml-2 truncate">{{ document.name }}</p>
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as Icons from '@/constants/icon-constants'
import { BaseDocumentModelInterface } from '@/models/buy-journey-steps/base/base-document.model'

export default defineComponent({
  props: {
    documents: {
      type: Array as PropType<Array<BaseDocumentModelInterface>>,
      required: true,
    },
    maxHeightStyle: {
      type: String,
      default: 'h-auto',
    },
  },
  setup() {
    return {
      downloadIcon: Icons.DOWNLOAD,
    }
  },
})
</script>
