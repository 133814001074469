
import { computed, defineComponent, PropType } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { sortBy } from 'lodash'
import { RealEstateImageModelInterface } from '@/models/real-estate-image.model'

SwiperCore.use([Pagination, Autoplay])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array as PropType<Array<RealEstateImageModelInterface>>,
      required: true,
    },
  },
  setup(props) {
    const sortedImages = computed(() =>
      sortBy(props.images, [image => (image.is_main_preview_image ? 0 : 1)])
    )

    return {
      sortedImages,
    }
  },
})
