import { AllFieldsOptional } from '@/utils/types/transformations'
import { AddressModelInterface } from './address.model'
import { AdvisorInterfaceModel } from './advisor.model'
import { SearchProfileModelInterface } from './search-profile.model'
export interface UserModelInterface {
  id: string
  address: AddressModelInterface
  first_name: string
  last_name: string
  email: string
  phone: string
  user_search_profile: SearchProfileModelInterface
  date_joined: string
  description: string
  birthday: string
  job: string
  salary: string
  equity: string
  real_estate_count: number
  avatar?: string
  is_advisor: boolean
  is_active: boolean
  employment_status: string
  assigned_advisor?: AdvisorInterfaceModel
  tags?: Array<string>
}

export type UserModelPayloadInterface = AllFieldsOptional<UserModelInterface>

export const UserModelFunctions = {
  getFullName(
    model: UserModelInterface | AdvisorInterfaceModel | null
  ): string {
    return `${model?.first_name} ${model?.last_name}`
  },
}
