
import { defineComponent, ref, watch } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 24,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
    progress: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const zeroValue = 2 * Math.PI * (props.size / 2 - props.strokeWidth)
    const progressValue = ref({ progress: zeroValue })

    watch(
      props,
      () => {
        const newProgress = (1 - props.progress) * zeroValue
        anime({
          targets: progressValue.value,
          progress: newProgress,
          duration: 500,
          easing: 'easeInOutQuad',
        })
      },
      { immediate: true }
    )

    return {
      zeroValue,
      progressValue,
    }
  },
})
