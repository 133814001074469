<template>
  <div class="mt-8">
    <SmallSubtitle :text="t(TC.YOUR_PERSONAL_ADVISOR)" />
    <div
      v-if="advisor"
      class="flex flex-col justify-center items-center md:w-full md:shadow"
    >
      <div
        class="flex flex-col items-center justify-center bg-white bg-opacity-0
            md:flex-row md:items-start md:justify-start md:w-full md:bg-opacity-100 md:p-4"
      >
        <img
          class="w-20 h-20 rounded-full object-cover object-center md:w-24 md:h-24 md:mx-8"
          :src="advisor.avatar"
          :alt="fullName"
        />

        <div
          class="space-y-4 mt-4 md:mt-0 md:space-y-3 md:flex-col md:pr-4 text-center md:text-left"
        >
          <h3 class="text-xl font-bold md:text-2xl md:font-light">
            {{ fullName }}
          </h3>
          <p v-if="advisor.job" class="text-2xs md:text-sm md:text-primary">
            {{ advisor.job }}
          </p>
          <p
            v-if="advisor.description"
            class="w-84 text-center md:text-justify font-roboto text-sm md:text-left md:font-light md:font-light md:break-words md:w-full"
            v-html="advisor.description"
          ></p>
          <div
            class="flex flex-row items-center justify-center md:justify-start md:mb-6 md:text-primary"
          >
            <ContactItem :text="advisor.email" :icon="MAIL_ICON" />
            <VerticalDivider
              v-if="advisor.phone"
              class="md:border-l-2 md:h-4"
            />
            <ContactItem
              v-if="advisor.phone"
              :text="advisor.phone"
              :icon="PHONE_ICON"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center mt-6">
      <p class="text-xs text-center">
        {{ t(TC.WE_WILL_ASSIGN_AN_ADIVSOR) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import ContactItem from '@/components/dashboard/advisor/contact-item.vue'
import VerticalDivider from '@/components/common/vertical-divider.vue'
import SmallSubtitle from '@/components/common/typography/small-subtitle.vue'
import { computed, defineComponent } from 'vue'
import useTranslation from '@/composables/use-translation'
import { MAIL_ICON, PHONE_ICON } from '@/constants/icon-constants'
import * as TC from '@/constants/translation-constants.ts'
import { UserModule } from '@/store/modules/user.module.ts'
import { AdvisorModelFunctions } from '@/models/advisor.model'

export default defineComponent({
  components: {
    ContactItem,
    VerticalDivider,
    SmallSubtitle,
  },
  setup() {
    const t = useTranslation()
    const advisor = computed(() => UserModule.user?.assigned_advisor)
    const fullName = computed(() =>
      AdvisorModelFunctions.getFullName(UserModule.user?.assigned_advisor)
    )

    return {
      t,
      TC,
      MAIL_ICON,
      PHONE_ICON,
      advisor,
      fullName,
    }
  },
})
</script>
