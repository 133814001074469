
import { defineComponent } from 'vue'
import useResize from '@/composables/use-resize'

export default defineComponent({
  setup() {
    const { isMobile } = useResize()
    return {
      isMobile,
    }
  },
})
