<template>
  <ModalWrapper v-if="buyJourney" @click.self="closeModal">
    <content-skeleton-loader :isLoading="isLoading">
      <div
        class="modal-header absolute top-0 left-0 w-full flex justify-center items-center h-20 bg-very-light-grey z-50"
      >
        <HeaderTitle :text="buyJourney.real_estate.name" />
        <div class="absolute right-0 mr-6">
          <button @click="closeModal">
            <svg-icon name="x" class="text-medium-gray"></svg-icon>
          </button>
        </div>
      </div>
      <div class="pt-14">
        <BuyJourneyCard :realEstate="buyJourney.real_estate" />
      </div>
      <div class="flex flex-col bg-very-light-grey">
        <BuyJourney :buyJourney="buyJourney" />
      </div>
    </content-skeleton-loader>
  </ModalWrapper>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import BuyJourney from '@/components/buy-journey/index.vue'
import BuyJourneyCard from '@/components/buy-journey/buy-journey-card/index.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import HeaderTitle from '@/components/common/typography/header-title.vue'
import useError from '@/composables/use-error'
import { useToast } from 'vue-toastification'
import { LoadingModule } from '@/store/modules/loading.module'
import ModalWrapper from '@/components/common/modal/modal-wrapper.vue'

export default defineComponent({
  components: {
    BuyJourney,
    BuyJourneyCard,
    HeaderTitle,
    ModalWrapper,
  },
  setup() {
    const { currentRoute, replace, back } = useRouter()
    const isLoading = computed(() => LoadingModule.isBuyJourneyLoading)
    const toast = useToast()
    const buyJourney = computed(() => BuyJourneyModule.activeBuyJourney)
    const { getMessage } = useError()

    const closeModal = () => {
        try {
          const matchingRoutes = currentRoute.value.matched
          const secondLastRoute = matchingRoutes[matchingRoutes.length - 2]
          replace({ path: secondLastRoute.path })
        } catch {
          back()
        }
      }
      // onBeforeCreate:
    ;(async () => {
      const buyJourneyId = currentRoute.value.params['buy_journey_id'] as string
      BuyJourneyModule.getBuyJourney(buyJourneyId).catch(error => {
        closeModal()
        toast.error(getMessage(error))
        LoadingModule.SET_IS_BUY_JOURNEY_LOADING(false)
      })
    })()

    return {
      closeModal,
      buyJourney,
      isLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
.modal-scroll {
  scroll-behavior: smooth;
}
</style>
