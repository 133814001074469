<template>
  <div
    @click="$emit('open', buyJourney)"
    class="w-72 shadow-lg bg-white mx-auto cursor-pointer second-and-nth-my-6 md:w-80"
    :class="{ 'w-full': isFullWidth }"
  >
    <!-- Card Header -->
    <div class="h-24">
      <img
        class="w-full h-full object-cover object-center"
        v-if="buyJourney.real_estate.main_image"
        :src="buyJourney.real_estate.main_image.image_file_path"
        :alt="buyJourney.real_estate.main_image.alt_text"
      />
    </div>
    <!-- Card Content -->
    <div class="py-3 px-4">
      <div class="flex flex-row justify-between">
        <div class="w-1/2 text-sm">{{ buyJourney.real_estate.name }}</div>
        <div class="w-1/2 font-bold text-green text-sm text-right">
          {{ priceFormatted }}
        </div>
      </div>
      <div class="flex flex-row justify-between mt-3">
        <div v-if="buyJourney.next_step" class="w-1/2 flex items-center">
          <CircularProgress
            :progress="buyJourney.next_step.progress"
            :label="buyJourney.next_step.index.toString()"
          />
          <span class="text-2xs ml-2">{{ buyJourney.next_step.name }}</span>
        </div>
        <div v-else class="w-1/2 flex items-center">
          <fa class="w-4 text-green" icon="check" />
        </div>
        <div
          v-if="buyJourney.real_estate.address"
          class="w-1/2 flex flex-row items-center justify-end space-x-1"
        >
          <fa class="h-4 text-light-gray" icon="map-marker-alt" />
          <span class="text-2xs uppercase">{{
            buyJourney.real_estate.address.city
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import useNumbers from '@/composables/use-numbers'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import CircularProgress from '@/components/common/progress/circular-progress.vue'

export default defineComponent({
  components: { CircularProgress },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { priceFormatted } = useNumbers(props.buyJourney.real_estate.price)

    return {
      priceFormatted,
    }
  },
})
</script>
