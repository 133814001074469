<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="$props.class"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path :fill="color" :d="svgPath" />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { findIconDefinition, IconName } from '@fortawesome/fontawesome-svg-core'

export default defineComponent({
  name: 'FontAwesomeIcon',
  props: {
    icon: {
      type: String as PropType<IconName>,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },

  setup(props) {
    const definition = computed(() =>
      findIconDefinition({
        prefix: 'fas',
        iconName: props.icon,
      })
    )

    const width = computed(() => definition.value?.icon[0] ?? 24)
    const height = computed(() => definition.value?.icon[1] ?? 24)
    const svgPath = computed(() => definition.value?.icon[4] ?? '')

    return { width, height, svgPath }
  },
})
</script>
