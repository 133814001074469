
import { defineComponent, PropType } from 'vue'
import * as Icons from '@/constants/icon-constants'
import { BaseDocumentModelInterface } from '@/models/buy-journey-steps/base/base-document.model'

export default defineComponent({
  props: {
    documents: {
      type: Array as PropType<Array<BaseDocumentModelInterface>>,
      required: true,
    },
    maxHeightStyle: {
      type: String,
      default: 'h-auto',
    },
  },
  setup() {
    return {
      downloadIcon: Icons.DOWNLOAD,
    }
  },
})
