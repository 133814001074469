<template>
  <div>
    <div v-if="!hasAcceptedFinancingOffer">
      <div class="space-y-2">
        <p class="text-xs font-bold">
          1. {{ t(TC.DOWNLOAD_THE_SELF_DISCLOSURE) }}
        </p>
        <FileList
          v-if="buyJourney.step_financing.self_disclosure_preset"
          :documents="
            buyJourney.step_financing.self_disclosure_preset.documents
          "
        />
        <div v-else>
          <p class="text-xs">{{ t(TC.WE_PREPARE_THE_SELF_DISCLOSURE) }}</p>
        </div>
      </div>

      <div class="space-y-2 mt-4">
        <p class="text-xs font-bold">
          2. {{ t(TC.UPLOAD_ALL_DOCUMENT_AND_SELF_DISCLOSURE) }}
        </p>
        <div class="pl-4">
          <transition name="fade-in">
            <div
              v-if="buyJourney.step_financing.user_uploaded_documents"
              class="bg-gray bg-opacity-25 p-2"
            >
              <p
                class="text-xs"
                v-html="t(TC.THANK_YOU_FOR_THE_DOCUMENTS_YOU_WILL_BE_INFORMED)"
              ></p>
              <p
                class="text-xs mt-2"
                v-html="t(TC.WE_ARE_LOOKING_FOR_AN_FINANCING_OFFER)"
              ></p>
              <p class="text-xs mt-2">
                {{ t(TC.YOU_CAN_ADD_ADDITIONAL_DOCUMENTS) }}
              </p>
              <div class="flex space-x-2">
                <p class="text-sm mt-2 font-bold">{{ t(TC.STATUS) }}:</p>
                <p
                  v-if="
                    buyJourney.step_financing.user_uploaded_documents
                      .is_accepted_by_advisor === null
                  "
                  class="text-sm mt-2"
                >
                  {{ t(TC.CHECKING) }}
                </p>
                <div
                  v-else-if="
                    buyJourney.step_financing.user_uploaded_documents
                      .is_accepted_by_advisor === false
                  "
                  class="w-full"
                >
                  <p class="text-sm mt-2 text-light-red">
                    {{ t(TC.DENIED) }}
                  </p>
                  <div
                    v-if="
                      buyJourney.step_financing.user_uploaded_documents
                        .deny_reason
                    "
                    class="p-2 bg-white mt-2 w-full"
                  >
                    <p class="text-xs font-bold">Grund:</p>
                    <p
                      class="text-xs wysiwyg-content"
                      v-html="
                        buyJourney.step_financing.user_uploaded_documents
                          .deny_reason
                      "
                    ></p>
                  </div>
                </div>
                <p
                  v-else-if="
                    buyJourney.step_financing.user_uploaded_documents
                      .is_accepted_by_advisor === true
                  "
                  class="text-sm mt-2 text-green"
                >
                  {{ t(TC.VALIDATED) }}
                </p>
              </div>
            </div>
          </transition>

          <div class="mt-4">
            <FileUploadList
              @on-upload="emitUpload"
              placeholderText="Laden Sie alle Dokumente hier hoch"
            />
            <p class="text-xs mt-2 text-center">{{ t(TC.OR) }}</p>
            <p class="text-xs mt-2">
              {{ t(TC.SEND_ALL_DOCUMENTS_BY_EMAIL) }}
            </p>
            <a
              class="text-primary font-bold text-xs"
              href="mailto:info@herzberg-immobilien.de"
              >info@herzberg-immobilien.de
            </a>
          </div>

          <div
            v-if="buyJourney.step_financing.user_uploaded_documents"
            class="mt-4"
          >
            <hr class="my-4" />
            <p class="text-xs font-bold">
              {{ t(TC.YOUR_UPLOADED_DOCUMENTS) }}:
            </p>
            <FileList
              :documents="
                buyJourney.step_financing.user_uploaded_documents.documents
              "
              maxHeightStyle="h-32"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="space-y-2"
      :class="{
        'mt-4': !hasAcceptedFinancingOffer,
      }"
    >
      <p v-if="!hasAcceptedFinancingOffer" class="text-xs font-bold">
        3. {{ t(TC.DOWNLOAD_FINANCING_OFFER) }}
      </p>
      <FinancingOffer
        v-if="buyJourney.step_financing.financing_offer"
        :buyJourney="buyJourney"
      />
      <div v-else>
        <p class="text-xs">{{ t(TC.WE_PREPARE_THE_FINANCING_OFFER) }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import FileList from '@/components/common/list/file-list.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import FinancingOffer from '@/components/buy-journey/steps/step-financing/financing-offer.vue'

export default defineComponent({
  emits: ['on-upload'],
  components: { FileList, FileUploadList, FinancingOffer },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()
    const hasAcceptedFinancingOffer = computed(() => {
      return props.buyJourney.step_financing.financing_offer
        ?.is_accepted_by_user
    })

    const emitUpload = (files: File[], resetCallback: Function) => {
      emit('on-upload', files, resetCallback)
    }

    return {
      emitUpload,
      hasAcceptedFinancingOffer,
      t,
      TC,
    }
  },
})
</script>
