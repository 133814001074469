<template>
  <div class="text-center">
    <a
      v-if="isExternal"
      class="text-xs text-primary font-bold"
      :class="isLight ? 'text-gray' : ''"
      :href="to.path"
      target="_blank"
    >
      {{ title }}
    </a>
    <router-link
      v-else
      class="text-xs text-primary text-bold"
      :class="isLight ? 'text-medium-gray' : ''"
      :to="to"
    >
      {{ title }}
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object as PropType<string | Record<'path' | 'name', string>>,
      required: true,
    },
  },
})
</script>
