<template>
  <div
    v-if="
      buyJourney.step_buy_contract_and_notary_appointment.notary_appointment
    "
  >
    <div
      v-if="
        buyJourney.step_buy_contract_and_notary_appointment.notary_appointment
          .is_accepted_by_user !== false
      "
    >
      <p class="text-xs">{{ t(TC.YOUR_NOTARY_APPOINTMENT_IS_AT_TIME) }}:</p>
      <p class="text-sm font-bold mt-2">
        {{
          new Date(
            buyJourney.step_buy_contract_and_notary_appointment.notary_appointment.date_time
          ).toGermanDate()
        }}
        {{ t(TC.O_CLOCK) }}
      </p>
      <p class="text-xs mt-2">
        {{ t(TC.YOUR_NOTARY_APPOINTMENT_IS_AT_PLACE) }}:
      </p>
      <div class="text-sm font-bold mt-2">
        <p>
          {{
            buyJourney.step_buy_contract_and_notary_appointment
              .notary_appointment.address.country
          }}
        </p>
        <p>
          {{
            buyJourney.step_buy_contract_and_notary_appointment
              .notary_appointment.address.postcode
          }}
          {{
            buyJourney.step_buy_contract_and_notary_appointment
              .notary_appointment.address.city
          }}
        </p>
        <p>
          {{
            buyJourney.step_buy_contract_and_notary_appointment
              .notary_appointment.address.street
          }}
          {{
            buyJourney.step_buy_contract_and_notary_appointment
              .notary_appointment.address.housenumber
          }}
        </p>
      </div>
    </div>

    <div
      class="flex space-x-4 mt-2"
      v-if="
        buyJourney.step_buy_contract_and_notary_appointment.notary_appointment
          .is_accepted_by_user === null
      "
    >
      <Button @click="emitClicked(true)">{{
        t(TC.ACCEPT_NOTARY_APPOINTMENT)
      }}</Button>
      <Button @click="emitClicked(false)">{{
        t(TC.I_WANT_TO_CHANGE_THE_NOTARY_APPOINTMENT)
      }}</Button>
    </div>

    <p
      v-if="
        buyJourney.step_buy_contract_and_notary_appointment.notary_appointment
          .is_accepted_by_user === false
      "
      class="text-xs mt-2 font-bold"
    >
      {{ t(TC.WE_ARE_SORRY_THAT_YOU_ARE_NOT_SATISIFIED) }}
    </p>
  </div>
  <p v-else class="text-xs font-bold">
    {{ t(TC.NO_NOTARY_APPOINTMENT_AVAILABLE_YET) }}
  </p>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Button from '@/components/common/form/button.vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'

export default defineComponent({
  emits: ['clicked'],
  components: { Button },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()

    const emitClicked = (is_accepted_by_user: boolean) => {
      emit('clicked', { is_accepted_by_user })
    }

    return {
      emitClicked,
      t,
      TC,
    }
  },
})
</script>
