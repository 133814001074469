<template>
  <div v-if="buyJourney.step_financing.financing_offer">
    <p class="font-bold">{{ t(TC.OUR_FINANCING_OFFER) }}</p>
    <p
      v-if="
        buyJourney.step_financing.financing_offer.is_accepted_by_user === null
      "
      class="text-xs mt-2"
    >
      {{ t(TC.DOWNLOAD_AND_AGREE_DOCUMENTS) }}
    </p>

    <FileList
      :documents="buyJourney.step_financing.financing_offer.documents"
    />

    <transition name="fade-in" mode="out-in">
      <div
        v-if="
          buyJourney.step_financing.financing_offer.is_accepted_by_user === true
        "
        class="flex items-center space-x-2 mt-4"
      >
        <fa class="w-8 text-green" icon="check" />
        <p class="text-xs font-bold">
          {{ t(TC.THANK_YOU_FOR_ACCEPTING_THE_OFFER) }}
        </p>
      </div>
      <div
        v-else-if="
          buyJourney.step_financing.financing_offer.is_accepted_by_user ===
            false
        "
      >
        <p class="text-xs font-bold mt-4">
          {{ t(TC.THANK_YOU_FOR_DENYING_THE_OFFER) }}
        </p>
      </div>
      <div v-else class="mt-2">
        <p class="font-bold">{{ t(TC.DO_YOU_AGREE_WITH_THE_OFFER) }}</p>
        <div class="flex space-x-4 mx-4 mt-2">
          <Button @click="updateFinancingOffer(true)">
            {{ t(TC.ACCEPT) }}
          </Button>
          <Button @click="updateFinancingOffer(false)">
            {{ t(TC.DECLINE) }}
          </Button>
        </div>
      </div>
    </transition>
  </div>
  <div
    v-else-if="buyJourney.step_financing.is_taking_care_of_financing !== true"
  >
    <p class="text-xs mt-4">
      {{ t(TC.WE_ARE_PREPARING_THE_FINANCING_OFFER) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import Button from '@/components/common/form/button.vue'
import FileList from '@/components/common/list/file-list.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { Button, FileList },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateFinancingOffer = (accepted: boolean) => {
      BuyJourneyModule.updateFinancingOfferDocuments({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_financing.id,
        financingOfferId: props.buyJourney.step_financing.financing_offer.id,
        payload: {
          is_accepted_by_user: accepted,
        },
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    return {
      updateFinancingOffer,
      t,
      TC,
    }
  },
})
</script>
