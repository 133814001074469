import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators'
import store from '@/store'
import {
  UserModelInterface,
  UserModelPayloadInterface,
} from '@/models/user.model'
import { UserAPI } from '@/api/user.api'
import {
  SearchProfilePatchPayloadInterface,
  SearchProfileModelInterface,
} from '@/models/search-profile.model'
import { InvestmentEffortTypeModelInterface } from '@/models/investment-effort-type.model'
import { RealEstateTypeModelInterface } from '@/models/real-estate-type.model'
import { EmploymentStatusTypeModelInterface } from '@/models/employment-status-types'
import { LoadingModule } from './loading.module'

export const USER_MODULE_NAME = 'USER'
export interface UserStateInterface {
  user: UserModelInterface | null
  isProfileEditable: boolean
  investmentEffortTypes: Array<InvestmentEffortTypeModelInterface> | null
  realEstateTypes: Array<RealEstateTypeModelInterface> | null
}

@Module({ dynamic: true, store, name: USER_MODULE_NAME })
class User extends VuexModule implements UserStateInterface {
  public isProfileEditable = false
  public user: UserModelInterface | null = null
  public investmentEffortTypes: Array<InvestmentEffortTypeModelInterface> = []
  public realEstateTypes: Array<RealEstateTypeModelInterface> = []
  public employmentStatusTypes: Array<EmploymentStatusTypeModelInterface> = []

  @Mutation
  private SET_INVESTMENT_EFFORT_TYPES(
    investmentEffortTypes: Array<InvestmentEffortTypeModelInterface> | null
  ): void {
    if (!investmentEffortTypes) return
    this.investmentEffortTypes = investmentEffortTypes
  }

  @Mutation
  private SET_REAL_ESTATE_TYPES(
    realEstateTypes: Array<RealEstateTypeModelInterface> | null
  ): void {
    if (!realEstateTypes) return
    this.realEstateTypes = realEstateTypes
  }

  @Mutation
  private SET_EMPLOYMENT_STATUS_TYPES(
    employmentStatusTypes: Array<EmploymentStatusTypeModelInterface> | null
  ): void {
    if (!employmentStatusTypes) return
    this.employmentStatusTypes = employmentStatusTypes
  }

  @Mutation
  public SET_USER(user: UserModelInterface | null): void {
    this.user = user
  }

  @Mutation
  public TOGGLE_IS_PROFILE_EDITABLE(): void {
    this.isProfileEditable = !this.isProfileEditable
  }
  @Mutation
  public SET_IS_PROFILE_EDITABLE(value: boolean): void {
    this.isProfileEditable = value
  }

  @Action
  public async login({
    email,
    password,
  }: Record<'email' | 'password', string>): Promise<void> {
    LoadingModule.SET_IS_LOGIN_LOADING(true)
    const userResponse = await UserAPI.login({
      email,
      password,
    }).finally(() => LoadingModule.SET_IS_LOGIN_LOADING(false))
    this.SET_USER(userResponse)
  }

  @Action
  public async verifyAuthentication(): Promise<void> {
    const userResponse = await UserAPI.verifyAuthentication()
    this.SET_USER(userResponse)
  }

  @Action
  public async logout(): Promise<void> {
    await UserAPI.logout()
  }

  @Action
  public async updateUserProfileAndSearchProfile(
    profileData: Record<
      'userProfilePayload' | 'searchProfilePayload',
      UserModelInterface | SearchProfileModelInterface
    >
  ) {
    if (this.user) {
      LoadingModule.SET_IS_UPDATE_PROFILE_LOADING(true)
      await this.updateUserProfile(
        profileData.userProfilePayload as UserModelInterface
      )
      await this.updateSearchProfile(
        profileData.searchProfilePayload as SearchProfileModelInterface
      )
      LoadingModule.SET_IS_UPDATE_PROFILE_LOADING(false)
    }
  }

  @Action
  public async updateSearchProfile(
    searchProfilePayload: SearchProfilePatchPayloadInterface
  ): Promise<void> {
    if (!this.user) return
    const updatedUser: UserModelInterface = await UserAPI.updateSearchProfile(
      searchProfilePayload,
      this.user.id
    )
    this.SET_USER(updatedUser)
  }

  @Action
  public async resetPassword({
    email,
  }: Record<'email', string>): Promise<void> {
    const userResponse: UserModelInterface = await UserAPI.resetPassword({
      email,
    })
    this.SET_USER(userResponse)
  }

  @Action
  public async updateUserProfile(
    payload: UserModelPayloadInterface
  ): Promise<void> {
    if (!this.user) return
    const updatedUser: UserModelInterface = await UserAPI.updateUser(
      payload,
      this.user.id
    )
    this.SET_USER(updatedUser)
  }

  @Action
  public async getInvestmentEffortTypes(): Promise<void> {
    const investmentEffortTypes = await UserAPI.getInvestmentEffortTypes()
    if (!investmentEffortTypes) return
    this.SET_INVESTMENT_EFFORT_TYPES(investmentEffortTypes)
  }

  @Action
  public async getRealEstateTypes(): Promise<void> {
    const realEstateTypes = await UserAPI.getRealEstateTypes()
    if (!realEstateTypes) return
    this.SET_REAL_ESTATE_TYPES(realEstateTypes)
  }

  @Action
  public async getEmploymentStatusTypes(): Promise<void> {
    const employmentStatusTypes = await UserAPI.getEmploymentStatusTypes()
    if (!employmentStatusTypes) return
    this.SET_EMPLOYMENT_STATUS_TYPES(employmentStatusTypes)
  }

  @Action
  public async updateAvatar(payload: FormData): Promise<void> {
    if (!this.user) return
    const updatedUser = await UserAPI.updateAvatar(payload, this.user.id)
    this.SET_USER(updatedUser)
  }

  @Action
  public async loadUserProfileSelectLists(): Promise<void> {
    LoadingModule.SET_IS_PROFILE_LOADING(true)
    await Promise.all([
      this.getInvestmentEffortTypes(),
      this.getRealEstateTypes(),
      this.getEmploymentStatusTypes(),
    ]).finally(() => LoadingModule.SET_IS_PROFILE_LOADING(false))
  }
}

export const UserModule = getModule(User)
