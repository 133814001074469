<template>
  <Form @keyup.enter="submitForm" @submit-form="submitForm" novalidate>
    <div class="mb-4">
      <Input
        :type="formData.email.type"
        :name="formData.email.name"
        :label="formData.email.label"
        :rules="formData.email.rules"
        @input="resetButtonAndMessage()"
      />
    </div>
    <div class="text-center mb-4">
      <SuccessMessage :text="successMessage" />
    </div>

    <div class="mb-4 text-center">
      <Button :disabled="!hasValue" type="primary">
        {{ t(TC.RESET_PASSWORD) }}
      </Button>
    </div>
    <div class="text-center">
      <Link
        :title="t(TC.GO_TO_LOGIN)"
        :to="{ name: signInRouteName }"
        :isLight="true"
      />
    </div>
  </Form>
</template>

<script lang="ts">
import Form from '@/components/common/form/form.vue'
import Input from '@/components/common/form/input.vue'
import Button from '@/components/common/form/button.vue'
import Link from '@/components/common/link.vue'
import SuccessMessage from '@/components/common/form/success-message.vue'
import useTranslation from '@/composables/use-translation.ts'
import { computed, defineComponent, ref } from 'vue'
import { UserModule } from '@/store/modules/user.module.ts'
import * as TC from '@/constants/translation-constants.ts'
import { string } from 'yup'
import { SIGN_IN } from '@/constants/router-constants'
import { useForm } from 'vee-validate'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    Input,
    Button,
    Form,
    SuccessMessage,
    Link,
  },
  setup() {
    const t = useTranslation()
    const toast = useToast()
    const { validate, values, resetForm } = useForm()
    const successMessage = ref('')
    const formData = {
      email: {
        type: 'email',
        name: 'email',
        label: t(TC.EMAIL),
        rules: string()
          .required(t(TC.EMAIL_IS_REQUIRED))
          .email(t(TC.EMAIL_MUST_BE_VALID)),
      },
    }
    const hasValue = computed(() => (values.email?.length ?? 0) > 0)

    const resetButtonAndMessage = () => {
      successMessage.value = ''
    }

    const submitForm = async () => {
      if (!hasValue.value) {
        return
      }

      const validationResult = await validate()
      if (validationResult.valid) {
        const { email } = values
        try {
          await UserModule.resetPassword({
            email,
          })
          toast.success(t(TC.RESET_PASSWORD_SUCCEEDED), {
            timeout: 8000,
          })
          resetForm()
        } catch (e) {
          toast.error(t(TC.COULD_NOT_RESET_PASSWORD))
        }
      }
    }

    return {
      submitForm,
      hasValue,
      formData,
      successMessage,
      signInRouteName: SIGN_IN.name,
      resetButtonAndMessage,
      t,
      TC,
    }
  },
})
</script>
