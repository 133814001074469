
import { defineComponent, onMounted, PropType, ref } from 'vue'
import ImageCarousel from '@/components/proposals/proposal-modal/image-carousel.vue'
import RealEstateIcon from '@/components/proposals/real-estate-icon/index.vue'
import Rating from '@/components/proposals/proposal-modal/rating.vue'
import Button from '@/components/common/form/button.vue'
import { ProposalModelInterface } from '@/models/proposal.model'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import { RealEstateModelFunctions } from '@/models/real-estate.model'
import ClickableText from '@/components/common/clickable-text.vue'

export default defineComponent({
  props: {
    proposal: {
      type: Object as PropType<ProposalModelInterface>,
    },
  },
  components: {
    ClickableText,
    ImageCarousel,
    RealEstateIcon,
    Rating,
    Button,
  },
  setup(props) {
    const t = useTranslation()
    const priceFormatted = ref('')
    const isDescriptionExpanded = ref(false)

    onMounted(() => {
      if (props.proposal?.real_estate) {
        priceFormatted.value = RealEstateModelFunctions.getPriceFormattedOf(
          props.proposal.real_estate
        )
      }
    })

    return {
      priceFormatted,
      isDescriptionExpanded,
      t,
      TC,
    }
  },
})
