import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from '../App.vue'
import DefaultLayout from '@/layouts/default.vue'
import AuthorizationLayout from '@/layouts/authorization.vue'
import * as Routes from '@/constants/router-constants'
import DashboardView from '@/views/dashboard.vue'
import NotFound from '@/views/404.vue'
import SignInView from '@/views/authorization/sign-in/index.vue'
import ForgotPasswordView from '@/views/authorization/forgot-password/index.vue'
import ProposalsView from '@/views/proposals/index.vue'
import ProfileView from '@/views/profile/index.vue'
import InvestmentView from '@/views/investments/index.vue'
import HeaderWithLogo from '@/components/main-header/header-with-logo.vue'
import ProposalModal from '@/components/proposals/proposal-modal/index.vue'
import InvestmentModal from '@/components/investments/investment-modal/index.vue'
import {
  beforeEnterAuthorization,
  beforeEnterHome,
} from '@/router/route-guards'
import { RoutePlacementEnum } from '@/enums/route-placement-enum'
import * as Icons from '@/constants/icon-constants'
import * as TC from '@/constants/translation-constants'
import * as CommonConstants from '@/constants/common'

const routes: Array<RouteRecordRaw> = [
  {
    path: Routes.ROOT.path,
    name: Routes.ROOT.name,
    component: App,
    redirect: { name: Routes.DASHBOARD.name },
    children: [
      {
        path: Routes.AUTHORIZATION.path,
        name: Routes.AUTHORIZATION.name,
        component: AuthorizationLayout,
        beforeEnter: beforeEnterAuthorization,
        redirect: { name: Routes.SIGN_IN.name },
        children: [
          {
            path: Routes.SIGN_IN.path,
            name: Routes.SIGN_IN.name,
            component: SignInView,
          },
          {
            path: Routes.FORGOT_PASSWORD.path,
            name: Routes.FORGOT_PASSWORD.name,
            component: ForgotPasswordView,
          },
        ],
      },
      {
        path: Routes.DASHBOARD.path,
        name: Routes.DASHBOARD.name,
        component: DefaultLayout,
        beforeEnter: beforeEnterHome,
        children: [
          {
            path: Routes.DASHBOARD.path,
            name: Routes.DASHBOARD.name,
            component: DashboardView,
            meta: {
              placement: RoutePlacementEnum.MAIN_NAVIGATION,
              icon: Icons.HOME_MENU_ICON,
              navigationTitle: TC.DASHBOARD_TITLE_NAVIGATION,
              title: TC.DASHBOARD_TITLE,
              subtitle: TC.DASHBOARD_SUBTITLE,
              customHeader: {
                component: HeaderWithLogo,
                showOn: [CommonConstants.DESKTOP, CommonConstants.MOBILE],
              },
            },
          },
          {
            path: Routes.INVESTMENTS.path,
            name: Routes.INVESTMENTS.name,
            component: InvestmentView,
            meta: {
              placement: RoutePlacementEnum.MAIN_NAVIGATION,
              icon: Icons.INVESTMENTS_MENU_ICON,
              navigationTitle: TC.INVESTMENT_TITLE_NAVIGATION,
              title: TC.INVESTMENT_TITLE,
              subtitle: TC.INVESTMENT_SUBTITLE,
              customHeader: {
                component: HeaderWithLogo,
                showOn: [CommonConstants.DESKTOP, CommonConstants.MOBILE],
              },
            },
            children: [
              {
                path: Routes.INVESTMENT_DETAIL.path,
                name: Routes.INVESTMENT_DETAIL.name,
                component: InvestmentModal,
              },
            ],
          },
          {
            path: Routes.PROPOSALS.path,
            name: Routes.PROPOSALS.name,
            component: ProposalsView,
            meta: {
              placement: RoutePlacementEnum.MAIN_NAVIGATION,
              icon: Icons.PROPOSALS_MENU_ICON,
              navigationTitle: TC.PROPOSAL_TITLE_NAVIGATION,
              title: TC.PROPOSAL_TITLE,
              subtitle: TC.PROPOSAL_SUBTITLE,
              customHeader: {
                component: HeaderWithLogo,
                showOn: [CommonConstants.DESKTOP, CommonConstants.MOBILE],
              },
            },
            children: [
              {
                path: Routes.PROPOSAL_DETAIL.path,
                name: Routes.PROPOSAL_DETAIL.name,
                component: ProposalModal,
              },
            ],
          },
          {
            path: Routes.PROFILE.path,
            name: Routes.PROFILE.name,
            component: ProfileView,
            meta: {
              placement: RoutePlacementEnum.MAIN_NAVIGATION,
              icon: Icons.PROFILE_MENU_ICON,
              navigationTitle: TC.PROFILE_TITLE_NAVIGATION,
              title: TC.PROFILE_TITLE,
              showOnMobile: false,
              customHeader: {
                component: HeaderWithLogo,
                showOn: [CommonConstants.DESKTOP],
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
