import * as Routes from '@/constants/router-constants'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { UserModule } from '@/store/modules/user.module'

const isLoggedIn = async () => {
  try {
    await UserModule.verifyAuthentication()
    return true
  } catch (e) {
    return false
  }
}
export const beforeEnterAuthorization = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (await isLoggedIn()) {
    return next({
      name: Routes.DASHBOARD.name,
    })
  }
  return next()
}

export const beforeEnterHome = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!(await isLoggedIn())) {
    return next({
      name: Routes.AUTHORIZATION.name,
      query: {
        next: to.path,
      },
    })
  }
  return next()
}
