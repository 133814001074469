<template>
  <div v-if="questions.length" class="mt-4">
    <p class="bg-primary text-white text-center p-1">
      {{ t(TC.OPEN_QUESTIONS) }} ({{ questions.length }})
    </p>
    <div
      v-for="question of questions"
      :key="question"
      class="text-sm p-2 bg-blue-100 odd:bg-white border mt-1"
    >
      <p>{{ question.question }}</p>
      <p class="text-2xs mt-2 text-right">
        {{ new Date(question.modified_at).toGermanDate() }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { QuestionAnswerModelInterface } from '@/models/buy-journey-steps/step-question/question-answer.model'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  props: {
    questions: {
      type: Array as PropType<Array<QuestionAnswerModelInterface>>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()

    return {
      angleUpIcon: Icons.ANGLE_UP_ICON,
      t,
      TC,
    }
  },
})
</script>
