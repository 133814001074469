<template>
  <svg
    :class="className"
    :style="{ width: `${size}`, height: `${size}` }"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconUrl" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: '24px',
    },
  },
  setup(props) {
    const iconUrl = ref('')

    function updateIconURL() {
      const svgIconImport = import(`@/assets/icons/${props.name}.svg`)
      svgIconImport.then(value => {
        if (Object.prototype.hasOwnProperty.call(value, 'default')) {
          iconUrl.value = value.default.url
        }
      })
    }

    updateIconURL()

    watch(
      () => props.name,
      () => {
        updateIconURL()
      }
    )

    function className() {
      return `svg-icon svg-icon--${props.name}`
    }

    return {
      className: className(),
      iconUrl,
    }
  },
})
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: currentColor;
}
</style>
