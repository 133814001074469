<template>
  <div>
    <p class="text-sm font-bold text-center">
      {{ t(TC.HOW_DO_YOU_WANT_TO_FINANCE_THE_REAL_ESTATE) }}
    </p>
    <Button @click="emitChoice(true)" class="mt-2">
      1. {{ t(TC.I_TAKE_CARE_OF_THE_FINANCING) }}
    </Button>
    <Button @click="emitChoice(false)" class="mt-2">
      2. {{ t(TC.I_NEED_HELP_FOR_THE_FINANCING) }}
    </Button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  emits: ['choice-selected'],
  components: { Button },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()

    const emitChoice = (is_taking_care_of_financing: boolean) => {
      emit('choice-selected', {
        is_taking_care_of_financing,
      })
    }

    return {
      emitChoice,
      t,
      TC,
    }
  },
})
</script>
