
import { Swiper, SwiperSlide } from 'swiper/vue'
import { defineComponent, onMounted, ref } from 'vue'
import useResize from '@/composables/use-resize'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { onResize } = useResize()
    const cardElement = ref({ $el: Element.prototype })
    const slidesPerView = ref(1)
    const sliderSpaceBetween = 24

    function _calculateBestSliderWidths(width) {
      if (
        props.items.length > 1 &&
        cardElement.value.$el &&
        cardElement.value.$el.hasChildNodes()
      ) {
        const card = cardElement.value.$el.firstElementChild
        if (card) {
          const elementWidth = card.getBoundingClientRect().width
          const diff = width / (elementWidth + sliderSpaceBetween)
          slidesPerView.value = Math.min(Math.max(diff, 1), 1000)
        }
      }
    }

    onResize(_calculateBestSliderWidths)

    onMounted(() => {
      _calculateBestSliderWidths(window.innerWidth)
    })

    const onReachEnd = (swiper: typeof Swiper) => {
      const totalSlides = swiper.slides.length - 1
      if (totalSlides > 0) {
        swiper.slideTo(props.items.length - 1)
      } else {
        swiper.slideTo(0)
      }
    }

    const setControlledSwiper = swiper => {
      swiper.update()
    }

    return {
      onReachEnd,
      cardElement,
      slidesPerView,
      setControlledSwiper,
    }
  },
})
