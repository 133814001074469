export {}

declare global {
  interface Number {
    toGermanNumber(decimals?: number): string
  }

  interface String {
    toUUID(): string
  }
}

Number.prototype.toGermanNumber = function(decimals = 2) {
  return this.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  })
}

String.prototype.toUUID = function() {
  return `xxxxxxxx-${this}-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
