
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object as PropType<string | Record<'path' | 'name', string>>,
      required: true,
    },
  },
})
