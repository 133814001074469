
import { defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import Button from '@/components/common/form/button.vue'
import FileList from '@/components/common/list/file-list.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { Button, FileList },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateFinancingOffer = (accepted: boolean) => {
      BuyJourneyModule.updateFinancingOfferDocuments({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_financing.id,
        financingOfferId: props.buyJourney.step_financing.financing_offer.id,
        payload: {
          is_accepted_by_user: accepted,
        },
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    return {
      updateFinancingOffer,
      t,
      TC,
    }
  },
})
