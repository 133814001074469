
import { computed, defineComponent, onBeforeMount } from 'vue'
import ProposalCard from '@/components/proposals/proposal-card/index.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import { useRouter } from 'vue-router'
import { ProposalModelInterface } from '@/models/proposal.model'
import { PROPOSAL_DETAIL } from '@/constants/router-constants'
import ProposalsNotFoundPlaceholder from '@/components/proposals/proposals-not-found-placeholder/index.vue'
import { LoadingModule } from '@/store/modules/loading.module'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: {
    ProposalCard,
    ProposalsNotFoundPlaceholder,
  },
  setup() {
    const { push } = useRouter()
    const isLoading = computed(() => LoadingModule.isProposalsListLoading)
    const toast = useToast()
    const { getMessage } = useError()

    const proposals = computed(() => BuyJourneyModule.proposals)
    const openModal = (proposal: ProposalModelInterface) => {
      // Persist scroll position, before routing. Reset in `closeModal`
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
      push({
        name: PROPOSAL_DETAIL.name,
        params: { proposal_id: proposal.id },
      })
    }

    onBeforeMount(async () => {
      BuyJourneyModule.getProposals().catch(error => {
        toast.error(getMessage(error))
      })
    })

    return {
      proposals,
      openModal,
      isLoading,
    }
  },
})
