import * as TC from '@/constants/translation-constants.ts'

export default {
  [TC.APPLICATION_TITLE]: 'Herzberg Immobilien',
  [TC.APPLICATION_SUBTITLE]: 'Investment mit Herz',

  [TC.DASHBOARD_TITLE]: 'Dashboard',
  [TC.DASHBOARD_TITLE_NAVIGATION]: 'Dashboard',
  [TC.DASHBOARD_SUBTITLE]: 'Ihre Immobilien Investment Übersicht',
  [TC.PROPOSAL_TITLE]: 'Entdecken',
  [TC.PROPOSAL_TITLE_NAVIGATION]: 'Entdecken',
  [TC.PROPOSAL_SUBTITLE]: 'Passende Vorschläge',

  [TC.YOUR_PERSONAL_ADVISOR]: 'Ihr/e persönliche/r Berater:in',
  [TC.WE_WILL_ASSIGN_AN_ADIVSOR]:
    'Wir werden Ihnen in Kürze eine/n BeraterIn zuweisen.',
  [TC.IMPRINT]: 'Impressum',
  [TC.PRIVACY_POLICY]: 'Datenschutz',
  [TC.TERMS_AND_CONDITIONS]: 'AGBs',

  [TC.NO_PROPOSAL_FOUND]:
    'Momentan gibt es noch keine passenden Immobilien für Sie',

  [TC.NO_BUY_JOURNEY_FOUND]: 'Sie haben noch keine Investments.',

  [TC.MORE_INFORMATION]: 'Mehr Informationen',
  [TC.INVESTMENT_STRATEGIES]: 'Investment Strategie',
  [TC.INVESTMENT_REGION]: 'Region',
  [TC.INVESTMENT_GOALS]: 'Investment Ziele',
  [TC.INVESTMENT_REQUIREMENTS]: 'Investment Anforderungen',
  [TC.INVESTMENT_VOLUME]: 'Investmentvolumen',
  [TC.INVESTMENT_TITLE_NAVIGATION]: 'Übersicht',
  [TC.SUBSCRIPTION_NEWSLETTER]: 'Newsletter',
  [TC.REAL_ESTATES_COUNT]: 'Immobilienbestand',
  [TC.ADDRESS]: 'Adresse',
  [TC.FIRST_NAME]: 'Vorname',
  [TC.LAST_NAME]: 'Nachname',
  [TC.PHONE]: 'Telefon',
  [TC.EMAIL]: 'Email',
  [TC.STREET]: 'Straße',
  [TC.NUMBER]: 'Hausnummer',
  [TC.COUNTRY]: 'Land',
  [TC.CITY]: 'Stadt',
  [TC.ZIP_CODE]: 'PLZ',
  [TC.DESCRIPTION]: 'Beschreibung',
  [TC.AVATAR]: 'Avatar',
  [TC.BIRTHDAY]: 'Geburtstag',
  [TC.JOB]: 'Beruf',
  [TC.SALARY]: 'Nettoeinkommen',
  [TC.EMPLOYMENT_STATUS]: 'Anstellungsverhältniss',
  [TC.EQUITY]: 'Eigenkapital',
  [TC.FINANCIAL_VALUES]: 'Vermögenswerte',
  [TC.TITLE_CONTACT_INFORMATION]: 'Kontakt Informationen',
  [TC.TITLE_PERSONAL_INFORMATION]: 'Persönliche Informationen',
  [TC.TITLE_FINANCIAL_INFORMATION]: 'Finanzielle Informationen',
  [TC.PROFILE_TITLE]: 'Profil',
  [TC.PROFILE_TITLE_NAVIGATION]: 'Profil',
  [TC.PROFILE_GREETING]: 'Hallo ',
  [TC.PROFILE_GREETING_END]: 'Willkommen zurück!',
  [TC.PROFILE_SUBTITLE]: 'Willkommen zurück!',
  [TC.TAB_TITLE_PROFILE]: 'Persönliche Daten',
  [TC.TAB_TITLE_SEARCH_PROFILE]: 'Ihr Suchprofil',
  [TC.OBJECT_TYPES]: 'Objektarten',
  [TC.REQUEST_EXPOSE]: 'Exposé anfordern',
  [TC.COMPLETELY_CAREFREE]: 'Rundum sorglos',
  [TC.NO_INVESTMENTS_FOUND]:
    'Oh, Sie haben aktuell keine Investments in der Prüfung.',
  [TC.SHOW_MATCHING_ESTATES]: 'Passende Immobilien anzeigen',
  [TC.INVESTMENT_TITLE]: 'Investment Übersicht',
  [TC.INVESTMENT_SUBTITLE]: 'Übersicht',
  [TC.NO_MATCHING_ESTATES_FOUND]:
    'Leider haben wir aktuell keine Immobilien mehr, die zu ihrem Suchprofil passen.',
  [TC.ACCEPT]: 'Akzeptieren',
  [TC.ACTIVATE_NOTIFICATION]: 'Investmentbenachrichtigung aktivieren',
  [TC.NOTIFICATION_DESCRIPTION]:
    'Sie erhalten automatisch eine Benachrichtigung per E-Mail, wenn eine neue passende Immobilie bei uns im Angebot ist.',
  [TC.LETTER_OF_INTEREST]: 'Absichtserklärung',
  [TC.CONSULATION_PROTOCOL_AND_DISCLAIMER]:
    'Konsulationsprotokoll und Haftungsausschluss',
  [TC.CANCELLATION_POLICY]: 'Stornierungsbedingungen',
  [TC.CONFIRM_CONDITIONS]:
    'Hiermit bestätige ich die oben aufgeführten Bedingungen',
  [TC.CONTINUE]: 'Weiter',
  [TC.START_BUY_JOURNEY]: 'Prozess starten',

  [TC.BUY_JOURNEY_CREATED]: 'Einkaufsprozess erfolgreich erstellt.',
  [TC.AN_ERROR_OCCURED]: 'Es ist ein Fehler aufgetreten',
  [TC.PASSWORD]: 'Passwort',
  [TC.NO_ACCOUNT_YET]: 'Haben Sie noch keinen Account?',
  [TC.WRONG_EMAIL_OR_PASSWORD]: 'Email oder Passwort falsch',
  [TC.EMAIL_IS_REQUIRED]: 'Email ist erforderlich',
  [TC.PASSWORD_IS_REQUIRED]: 'Passwort ist erforderlich',
  [TC.EMAIL_MUST_BE_VALID]: 'Email muss gültig sein',
  [TC.LOGIN]: 'Login',
  [TC.SIGN_IN]: 'Einloggen',
  [TC.FORGOT_PASSWORD]: 'Passwort vergessen?',
  [TC.REGISTER_HERE]: 'Hier registrieren',
  [TC.ARE_YOU_SURE_TO_LOGOUT]:
    'Sind Sie sicher dass Sie sich ausloggen wollen?',
  [TC.OBJECT_PRESENTATION]: 'Objektpräsentation',
  [TC.OBJECT_PRESENTATION]: 'Objektvorstellung',
  [TC.OBJECT_PRESENTATION]: 'Objektpräsentation',
  [TC.OBJECT_PRESENTATION]: 'Objektvorstellung',
  [TC.OBJECT_CHECK]: 'Objektprüfung',
  [TC.INVESTMENT]: 'Investment',
  [TC.INVESTMENT_CALCULATION]: 'Rentabilitätsrechnung',
  [TC.EXPOSE]: 'Exposé',
  [TC.QUESTIONS]: 'Rückfragen',
  [TC.VISITATION]: 'Besichtigung',
  [TC.FINANCING]: 'Finanzierung',
  [TC.ADDITIONAL_DOCUMENTS]: 'Weitere Dokumente',
  [TC.BUY_CONTRACT_DRAFT_AND_NOTARY_APPOINTMENT]:
    'Kaufvertragsentwurf & Notartermin',
  [TC.LOAN_CONTRACTS]: 'Darlehensverträge',
  [TC.PASSWORD]: 'Passwort',
  [TC.NO_ACCOUNT_YET]: 'Haben Sie noch keinen Account?',
  [TC.WRONG_EMAIL_OR_PASSWORD]: 'Email oder Passwort falsch',
  [TC.EMAIL_IS_REQUIRED]: 'Email ist erforderlich',
  [TC.PASSWORD_IS_REQUIRED]: 'Passwort ist erforderlich',
  [TC.EMAIL_MUST_BE_VALID]: 'Email muss gültig sein',
  [TC.LOGIN]: 'Login',
  [TC.SIGN_IN]: 'Einloggen',
  [TC.FORGOT_PASSWORD]: 'Passwort vergessen?',
  [TC.REGISTER_HERE]: 'Hier registrieren',
  [TC.COULD_NOT_RESET_PASSWORD]:
    'Leider können wir Ihr Passwort nicht Zurücksetzen. Versuchen Sie es später erneut.',
  [TC.RESET_PASSWORD]: 'Passwort zurücksetzen',
  [TC.FORGOT_PASSWORD_DESCRIPTION]:
    'Haben Sie Ihr Passwort vergessen? Kein Problem, tragen Sie hier einfach Ihre Email ein um ein neues Passwort zu vergeben.',
  [TC.GO_TO_LOGIN]: 'Zum Login',
  [TC.GO_BACK]: 'Zurück zur Webseite',
  [TC.RESET_PASSWORD_SUCCEEDED]:
    'Sofern wir diese Email Adresse im System haben, bekommen Sie in Kürze eine Email mit der Anleitung wie Sie Ihr Passwort zurücksetzen können.',
  [TC.SAVE]: 'Speichern',
  [TC.NO_DOCUMENTS_AVAILABLE]: 'Es sind noch keine Dokumente verfügbar.',
  [TC.DOCUMENTS]: 'Dokumente',
  [TC.CONFIRM]: 'Bestätigen',
  [TC.I_HAVE_DOWNLOADED_THE_EXPOSE_DOCUMENTS]:
    'Ich habe die Exposédokumente heruntergeladen',
  [TC.CALCULATION_PRESETS]: 'Berechnungsvorlagen',
  [TC.UPLOAD_YOUR_CALCULATIONS]: 'Laden Sie Ihre Berechnungen hier hoch',
  [TC.WANT_TO_CHECK_YOUR_CALCULATIONS]:
    'Möchten Sie Ihre Berechnungen von uns prüfen lassen?',
  [TC.YOUR_FILES_HAVE_BEEN_UPLOADED]:
    'Ihre Dateien wurden erfolgreich hochgeladen.',
  [TC.YOU_CAN_ASK_QUESTIONS_HERE]:
    'Hier haben Sie die Möglichkeit beliebige Fragen zum Objekt zu stellen.',
  [TC.INPUT_YOUR_QUESTION]: 'Geben Sie Ihre Frage ein ...',
  [TC.SUBMIT_QUESTION]: 'Frage absenden',
  [TC.YOUR_QUESTION_HAS_BEEN_SUBMITTED]:
    'Vielen Dank! Ihre Frage wurde erfolgreich übermittelt.',
  [TC.OPEN_QUESTIONS]: 'Offene Fragen',
  [TC.ANSWERED_QUESTIONS]: 'Beantwortete Fragen',
  [TC.ANSWER_OF_YOUR_ADVISOR]: 'Antwort Ihres Beraters',
  [TC.CLICK_YOU_QUESTION_TO_SHOW_ANSWER]:
    'Klicken Sie auf Ihre Frage um die Antwort anzuzeigen.',
  [TC.PLEASE_CHOOSE_A_DATE]: 'Bitte wählen Sie ein Datum aus.',
  [TC.PLEASE_TYPE_A_NUMBER]: 'Bitte geben Sie eine Zahl ein.',
  [TC.HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_7]:
    'Bitte geben Sie eine Stunde ab 7 Uhr an.',
  [TC.HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_20]:
    'Bitte geben Sie eine Stunde bis 20 Uhr an.',
  [TC.YOUR_APPOINTMENT_HAS_BEEN_SUBMITTED]:
    'Vielen Dank! Ihr Terminvorschlag wurde erfolgreich übermittelt.',
  [TC.PLEASE_PROPOSE_AT_LEAST_ONE_APPOINTMENT]:
    'Bitte schlagen Sie mindestens einen Termin vor, an dem Sie die Immobilie besichtigen wollen. Sie können bis zu 3 Terminvorschläge zusenden.',
  [TC.SUBMIT]: 'Absenden',
  [TC.YOUR_APPOINTMENT_IS_AT]: 'Ihr Besichtigungstermin findet statt am',
  [TC.THE_APPOINTMENT_LOCATION_IS]: 'Die Adresse zur Besichtigung lautet',
  [TC.NO_LOCATION_PROVIDED_YET]:
    'Es wurde noch keine Adresse hinterlegt. Bitte kontaktieren Sie Ihren Berater.',
  [TC.YOUR_VISITATION_APPOINTMENT_PROPOSALS]: 'Ihre Terminvorschläge',
  [TC.WE_INFORM_YOU_ABOUT_A_FINAL_APPOINTMENT]:
    'Wir informieren Sie, sobald wir einen passenden Termin gefunden haben. Dieser wird Ihnen dann hier angezeigt.',
  [TC.O_CLOCK]: 'Uhr',
  [TC.FROM]: 'von',
  [TC.TO]: 'bis',
  [TC.DATE]: 'Datum',
  [TC.CLICK_TO_DISPLAY_CALENDAR]: 'Klicken um Kalender anzuzeigen ...',
  [TC.HOUR]: 'Stunde',
  [TC.REAL_ESTATE_IS_FINANCED_BY_OUR_PARTNER]:
    'Diese Immobilie wird durch einen unserer Partner finanziert.',
  [TC.HOW_DO_YOU_WANT_TO_FINANCE_THE_REAL_ESTATE]:
    'Wie möchten Sie diese Immobilie finanzieren?',
  [TC.I_TAKE_CARE_OF_THE_FINANCING]:
    'Ich kümmere mich selbst um meine Finanzierung',
  [TC.I_NEED_HELP_FOR_THE_FINANCING]:
    'Ich benötige Unterstützung bei der Finanzierung',
  [TC.OUR_FINANCING_OFFER]: 'Unser Finanzierungsangebot für Sie',
  [TC.DOWNLOAD_AND_AGREE_DOCUMENTS]:
    'Bitte laden Sie sich die folgenden Dokumente herunter. Sie können unser Angebot dann bestätigen.',
  [TC.DO_YOU_AGREE_WITH_THE_OFFER]: 'Gefällt Ihnen dieses Angebot?',
  [TC.WE_ARE_PREPARING_THE_FINANCING_OFFER]:
    'Wir bereiten Ihr Finanzierungsangebot vor. Sie erhalten dieses sobald Sie die notwendigen Dokumente hochgeladen und Ihr Berater diese bestätigt hat. Sie werden benachrichtigt sobald dieses zur Verfügung steht.',
  [TC.THANK_YOU_FOR_ACCEPTING_THE_OFFER]:
    'Vielen Dank für Ihre Wahl. Sie haben das Angebot angenommen. Sie können dieses jederzeit hier herunterladen.',
  [TC.THANK_YOU_FOR_DENYING_THE_OFFER]:
    'Vielen Dank für Ihre Wahl. Ihr Berater wird sich telefonisch bei ihnen melden.',
  [TC.UPLOAD_YOUR_FINANCING_DOCUMENTS_HERE]:
    'Laden Sie Ihre Finanzierungsdokumente hier hoch',
  [TC.I_HAVE_TAKEN_CARE_OF_FINANCING]:
    'Ich habe mich um die Finanzierung gekümmert',
  [TC.I_HAVE_RECEIVED_A_MATCHING_OFFER]:
    'Ich hab ein passendes Angebot oder Darlehensvertrag erhalten',
  [TC.TO_FINISH_CONFIRM_THE_CONDITIONS]:
    'Um diesen Schritt abzuschliessen bestätigen Sie bitte die folgenden Bedingungen.',
  [TC.IF_YOU_WANNA_CHECK_YOUR_OFFERS_UPLOAD_THEM_HERE]:
    'Wenn Sie Ihre Angebote von uns prüfen lassen wollen, dann können Sie diese hier hochladen. Wir werden uns mit Ihnen in Verbindung setzen.',
  [TC.THANK_YOU_FOR_TAKING_CARE_OF_INVESTMENT]:
    'Vielen Dank. Sie haben sich um die Finanzierung gekümmert und können nun fortfahren.',
  [TC.SADLY_AN_ERROR_OCCURED]: 'Leider ist ein Fehler aufgetreten.',
  [TC.THE_REQUIRED_RESOURCE_WAS_NOT_FOUND]:
    'Die angeforderte Resource wurde nicht gefunden.',
  [TC.BACK]: 'Zurück',
  [TC.SHOW_MORE]: 'Mehr anzeigen',
  [TC.SHOW_LESS]: 'Weniger anzeigen',
  [TC.YES]: 'Ja',
  [TC.NO]: 'Nein',
  [TC.YOU_CAN_DOWNLOAD_ADDITIONAL_DOCUMENTS_HERE]:
    'Hier finden Sie weitere Dokumente die Sie interessieren könnten.',
  [TC.DOWNLOAD_THE_SELF_DISCLOSURE]:
    'Laden Sie die Selbstauskunftvorlage und die Checkliste herunter.',
  [TC.UPLOAD_ALL_DOCUMENT_AND_SELF_DISCLOSURE]:
    'Laden Sie alle aufgeführten Dokumenttypen und die ausgefüllte Selbstauskunftvorlage hoch.',
  [TC.THANK_YOU_FOR_THE_DOCUMENTS_YOU_WILL_BE_INFORMED]:
    'Vielen Dank für Ihre Dokumente. Ihr Berater prüft diese nun und <strong>Sie werden benachrichtigt</strong> wenn die Dokumente verifiziert wurden.',
  [TC.WE_ARE_LOOKING_FOR_AN_FINANCING_OFFER]:
    'Wir suchen simultan bereits nach einem passenden <strong>Finanzierungsangebot</strong> für Sie.',
  [TC.YOU_CAN_ADD_ADDITIONAL_DOCUMENTS]:
    'Sie können selbstverständlich weitere Dokumente hinzufügen.',
  [TC.YOUR_UPLOADED_DOCUMENTS]: 'Ihre bisher hochgeladenen Dokumente',
  [TC.SEND_ALL_DOCUMENTS_BY_EMAIL]:
    'Senden Sie uns alle Unterlagen per E-Mail an',
  [TC.SEND_ALL_DOCUMENTS_BY_POST]: 'Senden Sie uns die Unterlagen per Post an',
  [TC.OR]: 'oder',
  [TC.STATUS]: 'Status',
  [TC.CHECKING]: 'Wird überprüft',
  [TC.DENIED]: 'Abgelehnt',
  [TC.VALIDATED]: 'Angenommen',
  [TC.DOWNLOAD_FINANCING_OFFER]: 'Laden Sie das Finanzierungsangebot herunter',
  [TC.WE_PREPARE_THE_SELF_DISCLOSURE]:
    'Wir haben momentan noch keine Selbstauskunftvorlage für Sie. Ihr Berater wird Ihnen diese bald zur Verfügung stellen.',
  [TC.WE_PREPARE_THE_FINANCING_OFFER]:
    'Wir bereiten ihr Finanzierungsangebot für Sie vor und informieren Sie sobald dieses zur Verfügung steht.',
  [TC.YOU_AGREE_TO_THE_NOTARY_FEE]:
    'Hiermit beauftrage ich Herzberg Immobilien einen Kaufvertragsentwurf und einen Beurkundungstermin bei einem passenden Notar anzufordern. Im Falle des selbstverschuldeten nicht Kaufs erkläre ich mich mit den <strong>beim Notar angefallenen Kosten einverstanden</strong>.',
  [TC.I_AM_AWARE_AND_AGREE]: 'Ich bin mir dessen bewusst und einverstanden',
  [TC.SOON_YOU_WILL_SEE_THE_BUY_CONTRACT_AND_NOTARY_APPOINTMENT]:
    'Vielen Dank. In Kürze sehen Sie hier Ihren Kaufvertragsentwurf und den Notartermin. Wir informieren Sie zusätzlich per E-Mail darüber.',
  [TC.DOWNLOAD_YOUR_BUY_CONTRACT]:
    'Wir haben Ihren Kaufvertragsentwurf erhalten. Sie können diesen nun hier herunterladen.',
  [TC.NO_BUY_CONTRACT_AVAILABLE_YET]:
    'Wir bereiten Ihren Kaufvertragsentwurf vor. Sie werden informiert sobald dieser verfügbar ist.',
  [TC.THANKS_FOR_CHOOSING_THE_BUY_CONTRACT_DRAFT]:
    'Wir freuen uns dass Ihnen unser Kaufvertragsentwurf gefällt. Sie können diesen jederzeit hier herunterladen.',
  [TC.WE_ARE_LOOKING_FOR_ANOTHER_BUY_CONTRACT_DRAFT]:
    'Vielen Dank für Ihre Wahl. Ihr Berater wird sich telefonisch bei Ihnen melden.',
  [TC.ACCEPT_BUY_CONTRACT_DRAFT]: 'Ich akzeptiere den Entwurf',
  [TC.DENY_BUY_CONTRACT_DRAFT]: 'Ich möchte den Entwurf ändern lassen',
  [TC.YOUR_NOTARY_APPOINTMENT_IS_AT_TIME]: 'Ihr Notartermin findet statt am',
  [TC.YOUR_NOTARY_APPOINTMENT_IS_AT_PLACE]:
    'Die Adresse für diesen Termin lautet',
  [TC.NO_NOTARY_APPOINTMENT_AVAILABLE_YET]:
    'Momentan haben wir noch keinen passenden Notartermin für Sie. Sie werden informiert sobald wir einen passenden Termin für Sie gefunden haben.',
  [TC.WE_ARE_SORRY_THAT_YOU_ARE_NOT_SATISIFIED]:
    'Wir bedauern, dass der Notartermin nicht passt, kümmern uns aber schnellstmöglich darum, einen neuen Notartermin zu finden. Ihr Berater wird sich bei Ihnen melden.',
  [TC.ACCEPT_NOTARY_APPOINTMENT]: 'Notartermin akzeptieren',
  [TC.I_WANT_TO_CHANGE_THE_NOTARY_APPOINTMENT]: 'Der Termin passt nicht',
  [TC.WE_NEED_YOUR_AGREEMENT_FOR_THE_LOAN_CONTRACT]:
    'Da Sie diese Immobilie selbst finanzieren, benötigen wir Ihre Zusage dass sie die Darlehensverträge erhalten und unterschrieben haben.',
  [TC.I_RECEIVED_THE_LOAN_CONTRACT_AND_SIGNED_IT]:
    'Ich habe die Darlehensverträge erhalten und unterschrieben',
  [TC.YOU_CAN_UPLOAD_YOUR_LOAN_CONTRACTS]:
    'Sie können hier optional Ihre Darlehensverträge hochladen, damit wir diese überprüfen können.',
  [TC.UPLOAD_YOUR_LOAN_CONTRACT]: 'Laden Sie hier Ihre Darlehensverträge hoch',
  [TC.DOWNLOAD_YOUR_LOAN_CONTRACT]:
    'Laden Sie Ihre Darlehensverträge herunter.',
  [TC.WE_PREPARE_YOUR_LOAN_CONTRACT]:
    'Ihre Darlehensverträge werden vorbereitet. Sie erhalten diese postalisch direkt von der Bank',
  [TC.THANK_YOU_FOR_CONFIRMING_YOUR_LOAN_CONTRACT]:
    'Vielen Dank, dass Sie ihre Darlehensverträge bestätigt haben.',
  [TC.YOU_DID_NOT_YET_PARTICIPATE_THE_NOTARY_APPOINTMENT]:
    'Dieser Kaufprozess ist abgeschlossen sobald Sie am Notartermin teilgenommen haben.',
  [TC.THANK_YOU_FOR_PARTICIPATING_THE_NOTARY_APPOINTMENT]:
    'Vielen Dank, dass Sie am Notartermin teilgenommen haben.',
  [TC.OWNERSHIP_TRANSFER_IS_IN_RPOGRESS]:
    'Die Eigentumsübertragung ist im Gange und wird bald abgeschlossen.',
  [TC.THE_BUY_JOURNEY_IS_COMPLETED]:
    'Dieser Einkaufsprozess ist abgeschlossen. Wir gratulieren Ihnen zu Ihrer neuen Immobilie und wünschen Ihnen viel Spaß mit dieser.',
  [TC.UPDATE_USER_SUCCEEDED]: 'Nutzerprofil erfolgreich aktualisiert',
  [TC.UPDATE_USER_ERROR]: 'Fehler beim aktualisieren des Nutzerprofils',
  [TC.FIRST_NAME_FORM_VALIDATION_ERROR]: 'Vorname ist erforderlich',
  [TC.LAST_NAME_FORM_VALIDATION_ERROR]: 'Nachname ist erforderlich',
  [TC.PHONE_REQUIRED_FORM_VALIDATION_ERROR]: 'Telefonnummer ist erforderlich',
  [TC.PHONE_FORMAT_FORM_VALIDATION_ERROR]: 'Die Telefonnummer ist nicht gültig',
  [TC.EMAIL_FORM_VALIDATION_ERROR]: 'E-Mail ist erforderlich',
  [TC.COUNTRY_FORM_VALIDATION_ERROR]: 'Land ist erforderlich',
  [TC.STREET_FORM_VALIDATION_ERROR]: 'Straße ist erforderlich',
  [TC.NUMBER_FORM_VALIDATION_ERROR]: 'Hausnummer ist erforderlich',
  [TC.ZIP_CODE_FORM_VALIDATION_ERROR]: 'PLZ ist erforderlich',
  [TC.JOB_FORM_VALIDATION_ERROR]: 'Beruf ist erforderlich',
  [TC.SALARY_FORM_VALIDATION_ERROR]: 'Nettoeinkommen ist erforderlich',
  [TC.REAL_ESTATES_COUNT_FORM_VALIDATION_ERROR]:
    'Immobilienbestand ist erforderlich',
  [TC.EQUITY_FORM_VALIDATION_ERROR]: 'Eigenkapital ist erforderlich',
  [TC.INVESTMENT_GOALS_FORM_VALIDATION_ERROR]:
    'Investmentziele sind erforderlich',
  [TC.INVESTMENT_OBJECT_TYPES_FORM_VALIDATION_ERROR]:
    'Objektarten sind erforderlich',
  [TC.INVESTMENT_STRATEGY_FORM_VALIDATION_ERROR]:
    'Investmentstrategie ist erforderlich',
  [TC.INVESTMENT_VOLUME_FORM_VALIDATION_ERROR]:
    'Investmentvolumen ist erforderlich',
  [TC.INVESTMENT_REGION_FORM_VALIDATION_ERROR]: 'Region ist erforderlich',
  [TC.INVESTMENT_REQUIREMENTS_FORM_VALIDATION_ERROR]:
    'Investmentanforderungen sind erforderlich',
  [TC.CITY_FORM_VALIDATION_ERROR]: 'Stadt ist erforderlich',
  [TC.FINANCIAL_VALUES_FORM_VALIDATION_ERROR]:
    'Vermögenswerte sind erforderlich',
  [TC.REAL_ESTATES_TYPES_FORM_VALIDATION_ERROR]:
    'Objektarten sind erforderlich',
  [TC.REAL_ESTATES_TYPES]: 'Objektarten',
  [TC.INVESTMENT_EFFORT_TYPES_FORM_VALIDATION_ERROR]:
    'Investmentarten sind erforderlich',
  [TC.INVESTMENT_EFFORT_TYPES]: 'Investmentarten',
  [TC.PROFILE_SAVE_SUCCESS]: 'Profil erfolgreich gespeichert',
  [TC.PROFILE_SAVE_ERROR]: 'Fehler beim Speichern des Profils',
  [TC.BIRTHDAY]: 'Geburtstag',
  [TC.BIRTHDAY_FORM_VALIDATION_ERROR]: 'Geburtstag ist erforderlich',
  [TC.BIRTHDAY_FORMAT_INCORRECT]: 'Format muss "Jahr-Monat-Tag" entsprechen',
  [TC.NEWSLETTER_FORM_VALIDATION_ERROR]: 'Newsletter ist erforderlich',
  [TC.EMPLOYMENT_STATUS_IS_REQUIRED]:
    'Bitte wählen Sie Ihr Anstellungsverhältniss',
  [TC.YOU_ARE_ALREADY_SUBSCRIBED]:
    'Sie werden automatisch über neue Immobilen informiert.',
  [TC.YOU_HAVE_BEEN_SUBSCRIBED]:
    'Sie werden ab sofort über neue Immobilien informiert.',
  [TC.DECLINE]: 'Ablehnen',
  [TC.TRANSFER_OF_OWNERSHIP]: 'Eigentumsübertragung',
  [TC.YOU_ARE_ALREADY_SUBSCRIBED]:
    'Sie werden automatisch über neue Immobilen informiert.',
  [TC.YOU_HAVE_BEEN_SUBSCRIBED]:
    'Sie werden ab sofort über neue Immobilien informiert.',
  [TC.DECLINE]: 'Ablehnen',
  [TC.TRANSFER_OF_OWNERSHIP]: 'Eigentumsübertragung',
  [TC.AVATAR_SUCCESSFULLY_UPDATED]: 'Das Profilbild wurde aktualisiert.',
  [TC.FINISH_STEP]: 'Schritt abschließen',
  [TC.REOPEN_STEP]: 'Schritt erneut öffnen',
}
