import { Store } from 'vuex'
import { BuyJourneyStateInterface } from '@/store/modules/buy-journey.module'
import { LoadingStateInterface } from '@/store/modules/loading.module'
import { RealEstateStateInterface } from '@/store/modules/real-estate.module'
import { UserStateInterface } from '@/store/modules/user.module'
import { config } from 'vuex-module-decorators'

// Set rawError to true by default on all @Action decorators
config.rawError = true

export interface RootStateInterface {
  buyJourney: BuyJourneyStateInterface
  loading: LoadingStateInterface
  realEstate: RealEstateStateInterface
  user: UserStateInterface
}

// Modules are created dynamically with dependency inversion
export default new Store<RootStateInterface>({})
