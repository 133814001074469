<template>
  <div class="my-2 mx-4">
    <template v-if="buyJourney.real_estate.additional_document">
      <p class="text-xs font-bold">
        {{ t(TC.YOU_CAN_DOWNLOAD_ADDITIONAL_DOCUMENTS_HERE) }}
      </p>
      <FileList
        :documents="buyJourney.real_estate.additional_document.documents"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import FileList from '@/components/common/list/file-list.vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  components: { FileList },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
</script>
