<template>
  <div class="relative mt-4">
    <Field
      class="hidden"
      name="date"
      as="input"
      v-model="selectedDateISOString"
    />
    <Input
      @click="showCalendar"
      :label="t(TC.DATE)"
      name="ignoreme"
      :placeholder="t(TC.CLICK_TO_DISPLAY_CALENDAR)"
      :isReadonly="true"
      :value="selectedDateString"
      :rules="inputRules.date"
    />
    <div class="flex flex-row justify-between">
      <Input
        class="mt-2"
        :label="t(TC.FROM)"
        name="from"
        :placeholder="t(TC.HOUR)"
        :rules="inputRules.timeRange"
      />
      <Input
        class="mt-2"
        :label="t(TC.TO)"
        name="to"
        :placeholder="t(TC.HOUR)"
        :rules="inputRules.timeRange"
      />
    </div>
    <MonthCalendarModal
      @selected="setDate"
      @closing="hideCalendar"
      :visibleData="calendarVisibleData"
      :selectedDate="selectedDate"
      :ignoreElementsForClose="['dateInput']"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import Input from '@/components/common/form/input.vue'
import MonthCalendarModal from '@/components/common/calendar/month-calendar-modal.vue'
import { number, string } from 'yup'
import { Field } from 'vee-validate'

export default defineComponent({
  components: { MonthCalendarModal, Input, Field },
  setup() {
    const t = useTranslation()
    const selectedDate = ref<Date>()
    const selectedDateISOString = ref('')
    const selectedDateString = ref('')
    const inputRules = {
      date: string().required(t(TC.PLEASE_CHOOSE_A_DATE)),
      timeRange: number()
        .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
        .required(t(TC.PLEASE_TYPE_A_NUMBER))
        .integer(t(TC.PLEASE_TYPE_A_NUMBER))
        .min(7, t(TC.HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_7))
        .max(20, t(TC.HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_20)),
    }
    const calendarVisibleData = ref(
      null as { visible: boolean; element: HTMLElement | null } | null
    )

    const setDate = (event: Date) => {
      selectedDate.value = event
      selectedDateISOString.value = event.toISOString().substring(0, 10)
      selectedDateString.value = event.toGermanDate(false)
    }

    const showCalendar = event => {
      calendarVisibleData.value = {
        visible: true,
        element: event.target,
      }
    }

    const hideCalendar = () => {
      calendarVisibleData.value = null
    }

    return {
      selectedDate,
      selectedDateISOString,
      selectedDateString,
      calendarVisibleData,
      setDate,
      showCalendar,
      hideCalendar,
      inputRules,
      t,
      TC,
    }
  },
})
</script>
