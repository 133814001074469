<template>
  <div class="flex flex-row justify-between my-5">
    <p class="text-xs font-bold truncate w-2/6">{{ rating.title }}</p>
    <div class="w-4/6">
      <RatingSlider :value="rating.value" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import RatingSlider from '@/components/common/rating-slider/index.vue'
import { RealEstateRatingModelInterface } from '@/models/real-estate-rating.model'

export default defineComponent({
  props: {
    rating: {
      type: Object as PropType<RealEstateRatingModelInterface>,
      required: true,
    },
  },
  components: {
    RatingSlider,
  },
})
</script>
