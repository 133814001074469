<template>
  <div
    class="fixed flex md:items-start justify-center md:overflow-y-auto w-full h-full top-0 left-0 z-50 bg-white md:bg-opacity-50 p-0 md:p-8"
    :class="{ 'modal-scroll': !isMobile }"
  >
    <div
      class="relative w-full min-h-1/2 flex-1 overflow-y-auto md:overflow-y-hidden overflow-x-hidden max-w-3xl md:shadow-5xl remove-transparency"
      :class="{ 'modal-scroll': isMobile }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useResize from '@/composables/use-resize'

export default defineComponent({
  setup() {
    const { isMobile } = useResize()
    return {
      isMobile,
    }
  },
})
</script>

<style scoped>
/* taiwind has no possibility of making a child of a transparent parent opaque */
.remove-transparency {
  background-color: rgba(255, 255, 255, 1);
}
</style>
