<template>
  <div class="shadow-md second-and-nth-my-6" ref="cardElement">
    <!-- Card Header -->
    <div
      class="h-40 bg-center bg-cover relative overflow-hidden"
      :style="{
        backgroundImage: realEstate.main_image
          ? `url(${realEstate.main_image.image_file_path})`
          : '',
      }"
    >
      <CardCorner />
    </div>
    <!-- Card Content -->
    <div>
      <div
        class="top-0 w-full flex flex-row justify-around items-center py-2 h-12 bg-light-blue"
      >
        <RealEstateIcon
          v-for="icon in realEstate.icons"
          :key="icon.id"
          :icon="icon"
          :dark="true"
        />
      </div>
      <div
        class="
               flex flex-col justify-between
               h-42
               px-6 py-4
               bg-white
               "
      >
        <div>
          <div class="text-xl font-poppins">{{ realEstate.name }}</div>
          <div
            v-if="realEstate.address"
            class="flex flex-row items-center space-x-1"
          >
            <span class="text-2xs uppercase tracking-widest">{{
              realEstate.address.city
            }}</span>
          </div>
        </div>
        <div class="font-bold text-green text-sm">{{ priceFormatted }}</div>
        <div class="h-16">
          <p class="font-roboto text-xs line-clamp-3">
            {{ realEstate.plain_text_description }}
          </p>
        </div>
        <!-- Card Footer -->
      </div>
      <Button type="primary" @click="$emit('open', proposal)">
        {{ t(TC.MORE_INFORMATION) }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, reactive, onMounted } from 'vue'
import useTranslation from '@/composables/use-translation'
import RealEstateIcon from '@/components/proposals/real-estate-icon/index.vue'
import CardCorner from '@/components/proposals/proposal-card/card-corner.vue'
import Button from '@/components/common/form/button.vue'
import useResize from '@/composables/use-resize'
import { ProposalModelInterface } from '@/models/proposal.model'
import { RealEstateModelFunctions } from '@/models/real-estate.model'
import * as TC from '@/constants/translation-constants.ts'

export default defineComponent({
  props: {
    proposal: {
      type: Object as PropType<ProposalModelInterface>,
      required: true,
    },
  },
  components: {
    RealEstateIcon,
    CardCorner,
    Button,
  },
  setup(props) {
    const t = useTranslation()
    const { onResize } = useResize()

    const cardElement = ref<HTMLElement | null>(null)
    const amountOfDescriptionLetters = ref(0)
    const realEstate = reactive(props.proposal.real_estate)
    const priceFormatted = RealEstateModelFunctions.getPriceFormattedOf(
      realEstate
    )

    const adjustTextSize = () => {
      if (cardElement.value) {
        const baseWidth = {
          screenWidth: 375,
          scaleFactor: 3.5,
        }
        amountOfDescriptionLetters.value =
          cardElement.value.clientWidth / baseWidth.scaleFactor
      }
    }

    onResize(() => adjustTextSize())

    onMounted(() => {
      adjustTextSize()
    })

    return {
      priceFormatted,
      realEstate,
      cardElement,
      t,
      TC,
    }
  },
})
</script>
