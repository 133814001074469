
import { defineComponent, PropType, ref } from 'vue'
import { RealEstateModelInterface } from '@/models/real-estate.model'
import ImageCarousel from '@/components/proposals/proposal-modal/image-carousel.vue'
import * as TC from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'
import useNumbers from '@/composables/use-numbers'
import ClickableText from '@/components/common/clickable-text.vue'

export default defineComponent({
  components: {
    ImageCarousel,
    ClickableText,
  },
  props: {
    realEstate: {
      type: Object as PropType<RealEstateModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const { priceFormatted } = useNumbers(props.realEstate.price)
    const isDescriptionExpanded = ref(false)
    return {
      priceFormatted,
      isDescriptionExpanded,
      t,
      TC,
    }
  },
})
