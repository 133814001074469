<template>
  <div v-if="text" class="text-red-500 text-2xs animate-error-shake">
    {{ text }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
  },
})
</script>
