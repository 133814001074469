import axios from 'axios'
import { reduce } from 'lodash'

const request = axios.create({
  baseURL: '//' + process.env.VUE_APP_API_HOST,
  withCredentials: true,
})

request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // TODO: Find a good way to redirect an expired token user to authentication screen
    return Promise.reject(error)
  }
)

function constructQueryString(object: object) {
  return encodeURI(
    reduce(
      object,
      (result, value, key) => (result = `${result}${key}=${value}&`),
      '?'
    ).slice(0, -1)
  )
}

const buildURL = (version = 'v1', url = '') => {
  return {
    join: (path: string) => {
      return buildURL(version, `${url}${url ? '/' : ''}${path}`)
    },
    addQueryParams: (object: object) => {
      return buildURL(version, `${url}/${constructQueryString(object)}`)
    },
    get: (): string => `${version}/${url}/`,
  }
}

export { request, buildURL }
