<template>
  <div
    class="w-full h-8 bottom-0 mb-14 flex bg-white flex-row justify-center items-center mt-12 py-3
    md:bg-opacity-0 md:h-10 md:p-2 md:my-0"
  >
    <a class="text-xs">{{ t(TC.IMPRINT) }}</a>
    <VerticalDivider :height="3" :width="1" />
    <a class="text-xs">{{ t(TC.PRIVACY_POLICY) }}</a>
    <VerticalDivider :height="3" :width="1" />
    <a class="text-xs ">{{ t(TC.TERMS_AND_CONDITIONS) }}</a>
  </div>
</template>

<script lang="ts">
import VerticalDivider from '@/components/common/vertical-divider.vue'
import { defineComponent } from 'vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'

export default defineComponent({
  components: {
    VerticalDivider,
  },
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
})
</script>
