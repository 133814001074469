<template>
  <div>
    <div
      class="flex flex-col border border-primary justify-center"
      :class="{ 'border-red-500 animate-error-shake': errorMessage }"
    >
      <label
        class="text-2xs border-b border-primary pl-5 py-2"
        :class="isDark ? 'text-white bg-primary' : 'text-primary bg-white'"
        :for="name"
      >
        {{ label }}
      </label>
      <textarea
        class="px-5 py-2 text-sm outline-none w-full"
        :class="{ 'border border-red-500 animate-error-shake': errorMessage }"
        :readonly="isReadonly"
        rows="5"
        :value="v"
        :rules="rules"
        :name="name"
        @input="updateValue"
        @keydown.enter.stop
      />
    </div>
    <ErrorMessage :text="errorMessage" />
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { useField } from 'vee-validate'
import ErrorMessage from '@/components/common/form/error-message.vue'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String || Number,
    },
    rules: [Object, String],
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { value: v, handleInput, errorMessage } = useField(
      props.name,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.value,
      }
    )

    const updateValue = event => {
      emit('update:modelValue', event.target.value)
      emit('input', event.target.value)
      handleInput(event.target.value)
    }

    watch(props, () => {
      handleInput(props.value)
    })

    return {
      v,
      errorMessage,
      updateValue,
    }
  },
})
</script>
