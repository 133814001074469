<template>
  <div class="flex justify-content items-center text-2xs">
    <div class="relative shadow rounded-full h-3 w-48">
      <div
        class="absolute bg-green rounded-full h-full transition-width duration-300"
        :style="{ width: `${percentFilled}%` }"
      ></div>
    </div>
    <div class="ml-2">{{ percentFilled + '%' }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    percentFilled: {
      type: Number,
      default: 0,
    },
  },
})
</script>
