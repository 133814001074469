<template>
  <div class="flex flex-row justify-center bg-primary w-full px-2">
    <div class="flex flex-col mt-12 mb-8 items-center text-white relative">
      <div class="flex items-center justify-center relative">
        <div
          class="relative flex justify-center items-center w-24 h-24 rounded-full overflow-hidden border-4 border-white"
        >
          <img
            v-if="user.avatar"
            :src="user.avatar"
            :alt="fullName"
            class="w-full h-full object-cover object-center"
          />
          <svg-icon v-else name="profile" size="32px" class="text-white" />
          <input
            @input="uploadUserAvatar"
            id="avatar"
            class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            type="file"
            accept=".png, .jpg, .jpeg"
          />
        </div>
      </div>

      <div class="flex justify-center items-center mt-8 space-x-2">
        <h3 class="flex items-center text-center text-lg font-bold">
          {{ fullName }}
        </h3>
        <div @click="logout" class="p-2 cursor-pointer">
          <fa class="h-4" icon="sign-out-alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { UserModule } from '@/store/modules/user.module'
import { UserModelFunctions, UserModelInterface } from '@/models/user.model'
import { useRouter } from 'vue-router'
import { SIGN_IN } from '@/constants/router-constants'
import useTranslation from '@/composables/use-translation'
import {
  ARE_YOU_SURE_TO_LOGOUT,
  AVATAR_SUCCESSFULLY_UPDATED,
} from '@/constants/translation-constants'
import * as Icons from '@/constants/icon-constants'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  emits: ['toggleIsProfileEditable'],
  props: {
    user: {
      type: Object as PropType<UserModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { replace } = useRouter()
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)
    const fullName = computed(() => UserModelFunctions.getFullName(props.user))

    const logout = () => {
      const result = confirm(t(ARE_YOU_SURE_TO_LOGOUT))
      if (result === true) {
        UserModule.logout().finally(() => {
          replace({ name: SIGN_IN.name }).then(async () => {
            UserModule.SET_USER(null)
          })
        })
      }
    }
    const toggleIsProfileEditable = () => {
      emit('toggleIsProfileEditable')
    }
    const uploadUserAvatar = event => {
      if (event.target.files.length) {
        const file: File = event.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        UserModule.updateAvatar(formData)
          .then(() => {
            toast.success(t(AVATAR_SUCCESSFULLY_UPDATED))
          })
          .catch(error => {
            toast.error(getMessage(error))
          })
      }
    }

    return {
      uploadUserAvatar,
      isProfileEditable,
      fullName,
      logout,
      toggleIsProfileEditable,
      profileEditIcon: Icons.PROFILE_EDIT_ICON,
      profileEditCloseIcon: Icons.PROFILE_EDIT_CLOSE_ICON,
    }
  },
})
</script>
