
import { defineComponent, computed } from 'vue'
import useTranslation from '@/composables/use-translation.ts'
import { UserModule } from '@/store/modules/user.module'
import Input from '@/components/common/form/input.vue'
import Select from '@/components/common/form/select.vue'
import * as TC from '@/constants/translation-constants.ts'
import { number, string } from 'yup'
import * as propertyConstants from '@/constants/property-constants.ts'

export default defineComponent({
  components: {
    Input,
    Select,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)
    const employmentStatusTypes = computed(
      () => UserModule.employmentStatusTypes
    )

    const formData = {
      job: {
        name: propertyConstants.JOB,
        label: t(TC.JOB),
        rules: string().required(t(TC.JOB_FORM_VALIDATION_ERROR)),
      },
      salary: {
        name: propertyConstants.SALARY,
        label: t(TC.SALARY),
        rules: string()
          .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
          .required(t(TC.SALARY_FORM_VALIDATION_ERROR)),
      },
      equity: {
        name: propertyConstants.EQUITY,
        label: t(TC.EQUITY),
        rules: string()
          .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
          .required(t(TC.EQUITY_FORM_VALIDATION_ERROR)),
      },
      realEstateCount: {
        type: 'number',
        name: propertyConstants.REAL_ESTATE_COUNT,
        label: t(TC.REAL_ESTATES_COUNT),
        rules: number()
          .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
          .required(t(TC.REAL_ESTATES_COUNT_FORM_VALIDATION_ERROR)),
      },
      employmentStatus: {
        name: propertyConstants.EMPLOYMENT_STATUS,
        label: t(TC.EMPLOYMENT_STATUS),
        rules: string().required(t(TC.EMPLOYMENT_STATUS_IS_REQUIRED)),
      },
    }

    return {
      t,
      TC,
      isProfileEditable,
      employmentStatusTypes,
      formData,
    }
  },
})
