export const HOME_MENU_ICON = 'home'
export const INVESTMENTS_MENU_ICON = 'investments'
export const PROPOSALS_MENU_ICON = 'proposals'
export const PROFILE_MENU_ICON = 'profile'
export const CHAT_MENU_ICON = 'chat'
export const MAIL_ICON = 'mail'
export const PHONE_ICON = 'phone'
export const DOCUMENTS_MENU_ICON = 'folder'
export const PROFILE_EDIT_ICON = 'pen'
export const PROFILE_SAVE_ICON = 'check-circle'
export const PROFILE_EDIT_CLOSE_ICON = 'times'
export const ANGLE_DOWN_ICON = 'angle-down'
export const ANGLE_RIGHT_ICON = 'angle-right'
export const ANGLE_UP_ICON = 'angle-up'
export const ANGLE_LEFT_ICON = 'angle-left'
export const PLUS_ICON = 'plus'
export const MINUS_ICON = 'minus'
export const CHECK_ICON = 'check'
export const DOWNLOAD = 'download'
export const UPLOAD = 'upload'
export const TRASH = 'trash'
