
import { defineComponent, PropType, ref } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const showMore = ref(false)

    return {
      showMore,
      t,
      TC,
    }
  },
})
