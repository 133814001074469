<template>
  <div>
    <div class="bg-white">
      <ImageCarousel :images="realEstate.images" />
      <div class="flex flex-col px-8 py-4">
        <div class="flex flex-row relative items-center justify-between">
          <div class="font-semibold text-green text-sm pb-2">
            {{ priceFormatted }}
          </div>
          <div class="absolute right-0 top-0" style="width: min-content">
            <div
              class="uppercase text-sm text-light-gray"
              v-html="t(TC.COMPLETELY_CAREFREE)"
            ></div>
          </div>
        </div>
        <div
          v-if="realEstate.address"
          class="flex items-center justify-start space-x-3"
        >
          <fa class="h-4 text-light-gray" icon="map-marker-alt" />
          <span class="uppercase tracking-widest text-sm">
            {{ realEstate.address.city }}
          </span>
        </div>
        <div class="flex flex-col mt-3 font-roboto text-xs">
          <div :class="{ 'line-clamp-3': !isDescriptionExpanded }">
            <div v-html="realEstate.description"></div>
          </div>

          <ClickableText
            v-if="realEstate.plain_text_description.length > 180"
            @click="isDescriptionExpanded = !isDescriptionExpanded"
            class="mt-4"
          >
            {{ isDescriptionExpanded ? t(TC.SHOW_LESS) : t(TC.SHOW_MORE) }}
          </ClickableText>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { RealEstateModelInterface } from '@/models/real-estate.model'
import ImageCarousel from '@/components/proposals/proposal-modal/image-carousel.vue'
import * as TC from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'
import useNumbers from '@/composables/use-numbers'
import ClickableText from '@/components/common/clickable-text.vue'

export default defineComponent({
  components: {
    ImageCarousel,
    ClickableText,
  },
  props: {
    realEstate: {
      type: Object as PropType<RealEstateModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const { priceFormatted } = useNumbers(props.realEstate.price)
    const isDescriptionExpanded = ref(false)
    return {
      priceFormatted,
      isDescriptionExpanded,
      t,
      TC,
    }
  },
})
</script>
