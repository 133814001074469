<template>
  <div
    class="
    md:px-8
    md:w-full
    md:flex md:flex-col"
  >
    <horizontal-list-skeleton-loader
      :isLoading="isLoading"
      :itemWidth="64"
      :itemHeight="40"
    >
      <section v-if="buyJourneys.length > 0">
        <div class="hidden md:flex md:flex-wrap md:w-full">
          <div
            v-for="item in buyJourneys"
            :key="item.id"
            class="md:my-4 md:mr-8"
          >
            <InvestmentCard
              :key="item.id"
              :buyJourney="item"
              @open="redirectToBuyJourney"
              v-slot="{ item }"
            >
            </InvestmentCard>
          </div>
        </div>
        <HorizontalScroll :items="buyJourneys" class="md:hidden">
          <template v-slot="{ item }">
            <InvestmentCard
              :key="item.id"
              :buyJourney="item"
              @open="redirectToBuyJourney"
            ></InvestmentCard>
          </template>
        </HorizontalScroll>
      </section>
      <div class="md:flex md:flex-col md:w-full md:items-center" v-else>
        <InvestmentNotFoundPlaceholder />
      </div>
    </horizontal-list-skeleton-loader>
    <section class="flex-1">
      <Advisor />
    </section>
  </div>
</template>

<script lang="ts">
import HorizontalScroll from '@/components/common/horizontal-scroll.vue'
import Advisor from '@/components/dashboard/advisor/index.vue'
import InvestmentNotFoundPlaceholder from '@/components/dashboard/investment-not-found-placeholder/index.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import InvestmentCard from '@/components/investments/investment-card/index.vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as Routes from '@/constants/router-constants'
import { LoadingModule } from '@/store/modules/loading.module'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: {
    HorizontalScroll,
    InvestmentCard,
    Advisor,
    InvestmentNotFoundPlaceholder,
  },
  setup() {
    const { push } = useRouter()
    const toast = useToast()
    const { getMessage } = useError()

    const isLoading = computed(() => LoadingModule.isBuyJourneyLoading)
    const buyJourneys = computed(() => BuyJourneyModule.buyJourneys)
    function redirectToBuyJourney(buyJourney: BuyJourneyModelInterface) {
      push({
        name: Routes.INVESTMENT_DETAIL.name,
        params: { buy_journey_id: buyJourney.id },
      })
    }

    onBeforeMount(async () => {
      BuyJourneyModule.getBuyJourneys().catch(error => {
        toast.error(getMessage(error))
      })
    })

    return {
      buyJourneys,
      redirectToBuyJourney,
      isLoading,
    }
  },
})
</script>
