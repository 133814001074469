<template>
  <div ref="row" class="relative flex w-full h-full items-center">
    <div
      class="text-2xs text-white flex justify-center items-center w-4 h-4 rounded-full bg-primary"
    >
      {{ min }}
    </div>
    <div class="w-full h-1 bg-primary"></div>
    <div
      class="text-2xs text-white flex justify-center items-center w-4 h-4 rounded-full bg-primary"
    >
      {{ max }}
    </div>
    <div
      ref="sliderKnob"
      class="absolute z-10 text-2xs text-black flex justify-center items-center w-4 h-4 rounded-full bg-light-blue transition-left duration-300"
      :style="{ left: `${leftDistance}px` }"
    >
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const row = ref<HTMLElement>()
    const sliderKnob = ref<HTMLElement>()
    const leftDistance = ref(0)

    const setKnobPosition = () => {
      if (row.value && sliderKnob.value) {
        const scaleFactor = props.value / 10
        leftDistance.value =
          row.value.getBoundingClientRect().width * scaleFactor -
          sliderKnob.value.getBoundingClientRect().width * scaleFactor
      }
    }

    onMounted(() => {
      window.addEventListener('resize', setKnobPosition)
      setTimeout(setKnobPosition, 500)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', setKnobPosition)
    })

    return {
      row,
      sliderKnob,
      leftDistance,
    }
  },
})
</script>
