<template>
  <section class="text-dark-gray">
    <router-view></router-view>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss">
$very-light-grey: #f3f3f3;
$animation-duration: 0.3s;
body {
  background-color: $very-light-grey;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
  .second-and-nth-my-6:nth-child(n + 2) {
    @apply my-6;
  }
}

// Animations
.fade-enter-active,
.fade-leave-active {
  transform: scale3d(1, 1, 1);
  transition: all $animation-duration ease-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: scale3d(0.7, 0.7, 0.7);
  opacity: 0;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity $animation-duration ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.slide-left-enter-from {
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter-to {
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-active {
  transition: all $animation-duration ease;
}

.slide-left-leave-from {
  transform: translate3d(0, 0, 0);
}

.slide-left-leave-to {
  transform: translate3d(-100%, 0, 0);
}

.slide-left-leave-active {
  transition: all $animation-duration ease;
}

// Special classes
.wysiwyg-content {
  p {
    @apply mt-2;
  }

  li {
    @apply mt-2;
  }

  ul {
    @apply list-inside;
    @apply list-disc;
    @apply ml-2;
  }

  ol {
    @apply list-inside;
    @apply list-decimal;
    @apply ml-2;
  }

  a {
    @apply text-primary;
    @apply font-bold;
  }
}
</style>
