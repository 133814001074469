<template>
  <transition name="fade-in">
    <div v-if="visibleData?.visible" class="calendar-modal">
      <vue-calendar
        :hide-view-selector="true"
        :disable-views="['week', 'day']"
        :time="false"
        active-view="month"
        :xsmall="true"
        :click-to-navigate="true"
        :min-date="tomorrow"
        :selected-date="selectedDate"
        @cell-click="emitDate"
        @touchend.stop
        @click.stop
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType, watch } from 'vue'

export default defineComponent({
  emits: ['selected', 'closing'],
  props: {
    visibleData: {
      type: Object as PropType<{
        visible: boolean
        element: HTMLElement | false
      } | null>,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: null,
    },
  },
  setup(props, { emit }) {
    const tomorrow = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
    const emitDate = (event: Date) => {
      event.setUTCHours(0, 0, 0, 0)
      emit('selected', event)
      emit('closing', event)
    }

    const closeModal = event => {
      const element = props.visibleData?.element
      if (event.target !== element) {
        emit('closing', event)
      }
    }

    watch(
      () => props.visibleData,
      value => {
        if (value?.visible) {
          setTimeout(() => {
            document.addEventListener('touchend', closeModal)
            document.addEventListener('click', closeModal)
          }, 300)
        } else {
          document.removeEventListener('touchend', closeModal)
          document.removeEventListener('click', closeModal)
        }
      }
    )
    return {
      emitDate,
      tomorrow,
    }
  },
})
</script>

<style lang="scss" scoped>
.calendar-modal {
  @apply absolute;
  @apply h-72;
  @apply bg-white;
  @apply text-black;
  @apply shadow-lg;
  @apply z-10;
  top: 0;
  left: 50%;
  width: 90%;
  transform: translate3d(-50%, -105%, 0);
  .vuecal__cell--out-of-scope {
    @apply text-white;
  }
  :deep(.vuecal__cell--today) {
    @apply bg-gray;
    @apply text-white;
  }
  :deep(.vuecal__cell--selected) {
    @apply bg-primary;
    @apply text-white;
  }
}
</style>
