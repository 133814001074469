
import ContactItem from '@/components/dashboard/advisor/contact-item.vue'
import VerticalDivider from '@/components/common/vertical-divider.vue'
import SmallSubtitle from '@/components/common/typography/small-subtitle.vue'
import { computed, defineComponent } from 'vue'
import useTranslation from '@/composables/use-translation'
import { MAIL_ICON, PHONE_ICON } from '@/constants/icon-constants'
import * as TC from '@/constants/translation-constants.ts'
import { UserModule } from '@/store/modules/user.module.ts'
import { AdvisorModelFunctions } from '@/models/advisor.model'

export default defineComponent({
  components: {
    ContactItem,
    VerticalDivider,
    SmallSubtitle,
  },
  setup() {
    const t = useTranslation()
    const advisor = computed(() => UserModule.user?.assigned_advisor)
    const fullName = computed(() =>
      AdvisorModelFunctions.getFullName(UserModule.user?.assigned_advisor)
    )

    return {
      t,
      TC,
      MAIL_ICON,
      PHONE_ICON,
      advisor,
      fullName,
    }
  },
})
