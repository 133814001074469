<template>
  <form ref="form" @submit.prevent="emitSubmitEvent" novalidate>
    <slot></slot>
  </form>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'

export default defineComponent({
  emits: ['submitForm', 'changed'],
  setup(_, { emit }) {
    const form = ref<HTMLFormElement>()
    let oldLength = 0
    let elementsToCheck: Element[] = []
    let interval: number | undefined

    const emitSubmitEvent = () => {
      emit('submitForm')
    }

    const emitChangeEvent = () => {
      emit('changed')
    }

    const removeListeners = () => {
      elementsToCheck.forEach(element => {
        element.removeEventListener('input', emitChangeEvent)
      })
    }

    const setListeners = () => {
      elementsToCheck.forEach(element => {
        element.addEventListener('input', emitChangeEvent)
      })
    }

    const collectFormElements = () => {
      if (form.value) {
        const nodeTypesToCheck = ['INPUT', 'TEXTAREA', 'SELECT']
        elementsToCheck = Array.from(form.value.elements).filter(element => {
          return (
            nodeTypesToCheck.includes(element.nodeName) &&
            element.id !== 'avatar'
          )
        })
      }
    }

    onMounted(() => {
      interval = setInterval(() => {
        collectFormElements()
        if (oldLength !== 0 && elementsToCheck.length === oldLength) {
          setListeners()
          clearInterval(interval)
        }
        oldLength = elementsToCheck.length
      }, 250)
    })

    onBeforeUnmount(() => {
      removeListeners()
    })

    return {
      emitSubmitEvent,
      form,
    }
  },
})
</script>
