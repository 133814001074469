import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

export const LOADING_MODULE_NAME = 'LOADING'
export interface LoadingStateInterface {
  isProposalsListLoading: boolean
  isCreateBuyJourneyLoading: boolean
  isBuyJourneyLoading: boolean
  isLoginLoading: boolean
  isLogoutLoading: boolean
  isForgotPasswordLoading: boolean
  isChatLoading: boolean
  isVerifyAuthenticationLoading: boolean
  isUpdateUserProfileLoading: boolean
  isUserProfileLoading: boolean
  isInvestmentsListLoading: boolean
  isProposalLoading: boolean
  isUserSearchProfileLoading: boolean
  isUploadCalculationDocumentsLoading: boolean
  isUploadFinancingDocumentsLoading: boolean
  isUploadLoanContractLoading: boolean
}

@Module({ dynamic: true, store, name: LOADING_MODULE_NAME })
class Loading extends VuexModule implements LoadingStateInterface {
  public isProposalsListLoading = false
  public isCreateBuyJourneyLoading = false
  public isBuyJourneyLoading = false
  public isLoginLoading = false
  public isLogoutLoading = false
  public isForgotPasswordLoading = false
  public isChatLoading = false
  public isVerifyAuthenticationLoading = false
  public isUpdateUserProfileLoading = false
  public isUserProfileLoading = false
  public isUserSearchProfileLoading = false
  public isInvestmentsListLoading = false
  public isProposalLoading = false
  public isUploadCalculationDocumentsLoading = false
  public isUploadFinancingDocumentsLoading = false
  public isUploadLoanContractLoading = false

  @Mutation
  public SET_IS_PROPOSALS_LIST_LOADING(loading: boolean): void {
    this.isProposalsListLoading = loading
  }

  @Mutation
  public SET_IS_CREATE_BUY_JOURNEY_LOADING(loading: boolean): void {
    this.isCreateBuyJourneyLoading = loading
  }

  @Mutation
  public SET_IS_BUY_JOURNEY_LOADING(loading: boolean): void {
    this.isBuyJourneyLoading = loading
  }

  @Mutation
  public SET_IS_LOGIN_LOADING(loading: boolean): void {
    this.isLoginLoading = loading
  }

  @Mutation
  public SET_IS_LOGOUT_LOADING(loading: boolean): void {
    this.isLogoutLoading = loading
  }

  @Mutation
  public SET_IS_FORGOT_PASSWORD_LOADING(loading: boolean): void {
    this.isForgotPasswordLoading = loading
  }

  @Mutation
  public SET_IS_CHAT_LOADING(loading: boolean): void {
    this.isChatLoading = loading
  }

  @Mutation
  public SET_IS_VERIFY_AUTHENTICATION_LOADING(loading: boolean): void {
    this.isVerifyAuthenticationLoading = loading
  }

  @Mutation
  public SET_IS_UPDATE_PROFILE_LOADING(loading: boolean): void {
    this.isUpdateUserProfileLoading = loading
  }

  @Mutation
  public SET_IS_PROFILE_LOADING(loading: boolean): void {
    this.isUserProfileLoading = loading
  }

  @Mutation
  public SET_IS_SEARCH_PROFILE_LOADING(loading: boolean): void {
    this.isUserSearchProfileLoading = loading
  }

  @Mutation
  public SET_IS_INVESTMENTS_LIST_LOADING(loading: boolean): void {
    this.isInvestmentsListLoading = loading
  }

  @Mutation
  public SET_IS_PROPOSAL_LOADING(loading: boolean): void {
    this.isProposalLoading = loading
  }

  @Mutation
  public SET_IS_UPLOAD_CALCULATION_DOCUMENTS_LOADING(loading: boolean): void {
    this.isUploadCalculationDocumentsLoading = loading
  }

  @Mutation
  public SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(loading: boolean): void {
    this.isUploadFinancingDocumentsLoading = loading
  }

  @Mutation
  public SET_IS_UPLOAD_LOAN_CONTRACT_LOADING(loading: boolean): void {
    this.isUploadLoanContractLoading = loading
  }
}

export const LoadingModule = getModule(Loading)
