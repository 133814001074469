<template>
  <div class="my-2 mx-4">
    <transition name="fade-in" mode="out-in">
      <div
        v-if="
          !buyJourney.step_visitation.final_appointment &&
            getAppointmentsSorted.length < maxAllowedAppointmentProposals
        "
      >
        <p class="text-xs">
          {{ t(TC.PLEASE_PROPOSE_AT_LEAST_ONE_APPOINTMENT) }}
        </p>

        <Form @submit-form="submitAppointmentForm" novalidate>
          <AppointmentInput />
          <div class="mt-2">
            <Button type="primary">{{ t(TC.SUBMIT) }}</Button>
          </div>
        </Form>
      </div>
    </transition>

    <transition name="fade-in" mode="out-in">
      <FinalAppointment
        v-if="buyJourney.step_visitation.final_appointment"
        :step="buyJourney.step_visitation"
      />
      <AppointmentProposals
        v-else-if="getAppointmentsSorted.length"
        :sortedAppointments="getAppointmentsSorted"
        :step="buyJourney.step_visitation"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from 'vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import AppointmentInput from '@/components/buy-journey/steps/step-visitation/appointment-input.vue'
import Form from '@/components/common/form/form.vue'
import Button from '@/components/common/form/button.vue'
import { useToast } from 'vue-toastification'
import { YOUR_APPOINTMENT_HAS_BEEN_SUBMITTED } from '@/constants/translation-constants.ts'
import useError from '@/composables/use-error'
import FinalAppointment from './step-visitation/final-appointment.vue'
import AppointmentProposals from './step-visitation/appointment-proposals.vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import { useForm } from 'vee-validate'

export default defineComponent({
  components: {
    Form,
    Button,
    AppointmentInput,
    FinalAppointment,
    AppointmentProposals,
  },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const maxAllowedAppointmentProposals = 3
    const { getMessage } = useError()
    const { resetForm, handleSubmit, validate, values } = useForm()

    const getAppointmentsSorted = computed(() => {
      return props.buyJourney.step_visitation.user_appointments
        .map(appointment => appointment)
        .sort((a, b) => a.date.localeCompare(b.date))
    })

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_visitation.has_visited_step) {
        BuyJourneyModule.updateVisitationStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_visitation.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    const submitAppointmentForm = handleSubmit(async () => {
      const validationResult = await validate()
      if (validationResult.valid) {
        const { date, from, to } = values
        let fromDate = from
        let toDate = to
        if (Number.parseInt(fromDate) > Number.parseInt(toDate)) {
          fromDate = to
          toDate = from
        }

        BuyJourneyModule.createUserVisitationAppointment({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_visitation.id,
          payload: {
            date: date,
            time_range: `${fromDate} - ${toDate}`,
          },
        })
          .then(() => {
            resetForm()
            toast.success(t(YOUR_APPOINTMENT_HAS_BEEN_SUBMITTED))
          })
          .catch(error => {
            toast.error(getMessage(error))
          })
      }
    })

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      getAppointmentsSorted,
      submitAppointmentForm,
      maxAllowedAppointmentProposals,
      t,
      TC,
    }
  },
})
</script>
