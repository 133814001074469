<template>
  <div
    v-if="buyJourney.real_estate.has_financing_partner"
    class="mt-4 bg-light-gray text-black p-2"
  >
    <p class="text-xs font-bold">
      {{ t(TC.REAL_ESTATE_IS_FINANCED_BY_OUR_PARTNER) }}
    </p>
    <transition name="fade-in">
      <p
        v-if="showMore"
        class="text-xs mt-2 wysiwyg-content"
        v-html="buyJourney.real_estate.financing_partner_hint"
      ></p>
    </transition>
    <p
      @click="showMore = !showMore"
      class="text-xs text-center mt-2 cursor-pointer"
    >
      > {{ showMore ? t(TC.SHOW_LESS) : t(TC.SHOW_MORE) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const showMore = ref(false)

    return {
      showMore,
      t,
      TC,
    }
  },
})
</script>
