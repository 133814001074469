<template>
  <div class="relative border border-black w-full h-full">
    <textarea
      class="textarea px-4 py-5 w-full h-full outline-none text-sm"
      :name="name"
      :id="name"
      :rows="rows"
      :value="v"
      @input="updateValue"
      :rules="rules"
      :disabled="disabled"
    >
    </textarea>
    <p
      v-if="label"
      class="absolute px-4 py-4 top-0 left-0 text-gray pointer-events-none text-sm transition-text-size duration-300"
      :class="{ 'label-moved-up': v.trim().length }"
    >
      {{ label }}
    </p>
    <ErrorMessage :text="errorMessage" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import ErrorMessage from '@/components/common/form/error-message.vue'
import { useField } from 'vee-validate'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    modelValue: {
      type: String,
    },
    rules: [Object, String],
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputName = computed(() => props.name || ''.toUUID())
    const { value: v, handleInput, errorMessage, errors } = useField(
      inputName,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.value,
      }
    )

    const updateValue = event => {
      emit('update:modelValue', event.target.value)
      emit('input', event)
      handleInput(event.target.value)
    }

    watch(props, () => {
      handleInput(props.value)
    })

    return {
      updateValue,
      v,
      errorMessage,
      errors,
    }
  },
})
</script>

<style lang="scss" scoped>
.label-moved-up {
  @apply text-2xs;
  @apply py-1;
}
.textarea:focus ~ p {
  @extend .label-moved-up;
}
</style>
