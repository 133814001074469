<template>
  <div v-if="isLoading" class="fixed bottom-0 w-full">
    <!-- Waiting... -->
    <div class="w-full overflow-hidden">
      <div
        class="w-1/2 h-2 inline-block relative animate-progress-race bg-gradient-to-r from-transparent via-light-red to-transparent"
      ></div>
    </div>

    <!-- 85% -->
    <div class="w-full mt-12">
      <div class="h-1 bg-primary w-85%"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
})
</script>
