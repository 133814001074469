<template>
  <div class="flex flex-col justify-center p-8 bg-primary md:bg-opacity-0">
    <h4
      class="font-sans font-family-arial font-bold text-center text-sm text-white pb-4 md:text-primary md:text-left"
    >
      {{ t(TC.TITLE_FINANCIAL_INFORMATION) }}
    </h4>
    <div class="space-y-4 text-primary text-2xs">
      <Input
        :name="formData.job.name"
        :label="formData.job.label"
        :rules="formData.job.rules"
        :value="user.job"
      />
      <Input
        :type="formData.salary.type"
        :name="formData.salary.name"
        :label="formData.salary.label"
        :rules="formData.salary.rules"
        :value="user.salary"
      />
      <Input
        :type="formData.equity.type"
        :name="formData.equity.name"
        :label="formData.equity.label"
        :rules="formData.equity.rules"
        :value="user.equity"
      />
      <Input
        :type="formData.realEstateCount.type"
        :name="formData.realEstateCount.name"
        :label="formData.realEstateCount.label"
        :rules="formData.realEstateCount.rules"
        :value="user.real_estate_count"
      />
      <Select
        :name="formData.employmentStatus.name"
        :rules="formData.employmentStatus.rules"
        :dataList="employmentStatusTypes"
        :label="formData.employmentStatus.label"
        :value="user.employment_status"
        keyName="id"
        valueName="name"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import useTranslation from '@/composables/use-translation.ts'
import { UserModule } from '@/store/modules/user.module'
import Input from '@/components/common/form/input.vue'
import Select from '@/components/common/form/select.vue'
import * as TC from '@/constants/translation-constants.ts'
import { number, string } from 'yup'
import * as propertyConstants from '@/constants/property-constants.ts'

export default defineComponent({
  components: {
    Input,
    Select,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)
    const employmentStatusTypes = computed(
      () => UserModule.employmentStatusTypes
    )

    const formData = {
      job: {
        name: propertyConstants.JOB,
        label: t(TC.JOB),
        rules: string().required(t(TC.JOB_FORM_VALIDATION_ERROR)),
      },
      salary: {
        name: propertyConstants.SALARY,
        label: t(TC.SALARY),
        rules: string()
          .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
          .required(t(TC.SALARY_FORM_VALIDATION_ERROR)),
      },
      equity: {
        name: propertyConstants.EQUITY,
        label: t(TC.EQUITY),
        rules: string()
          .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
          .required(t(TC.EQUITY_FORM_VALIDATION_ERROR)),
      },
      realEstateCount: {
        type: 'number',
        name: propertyConstants.REAL_ESTATE_COUNT,
        label: t(TC.REAL_ESTATES_COUNT),
        rules: number()
          .typeError(t(TC.PLEASE_TYPE_A_NUMBER))
          .required(t(TC.REAL_ESTATES_COUNT_FORM_VALIDATION_ERROR)),
      },
      employmentStatus: {
        name: propertyConstants.EMPLOYMENT_STATUS,
        label: t(TC.EMPLOYMENT_STATUS),
        rules: string().required(t(TC.EMPLOYMENT_STATUS_IS_REQUIRED)),
      },
    }

    return {
      t,
      TC,
      isProfileEditable,
      employmentStatusTypes,
      formData,
    }
  },
})
</script>
