<template>
  <div class="w-full">
    <p class="w-full text-2xs text-left text-white" :for="inputName">
      {{ label }}
    </p>
    <div
      class="flex flex-initial justify-between items-center bg-white border border-black h-12 px-4 mt-1"
      :class="{ 'border-red-500 animate-error-shake': errorMessage }"
    >
      <select
        class="w-full bg-white text-xs h-full"
        :id="inputName"
        :disabled="disabled"
        @change="updateValue"
        :value="v"
      >
        <option value="" v-if="placeholder" disabled>{{ placeholder }}</option>
        <option :value="data.id" v-for="data of dataList" :key="data.id">{{
          data.name
        }}</option>
      </select>
    </div>
    <ErrorMessage :text="errorMessage" />
  </div>
</template>

<script lang="ts">
import ErrorMessage from '@/components/common/form/error-message.vue'
import { computed, defineComponent, watch } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  emits: ['update:modelValue', 'change'],
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean],
    },
    rules: [Object, String],
    value: {
      type: [String, Number],
    },
    dataList: {
      type: Array,
      required: true,
    },
    keyName: {
      type: String,
      required: true,
    },
    valueName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const inputName = computed(() => props.name || ''.toUUID())
    const { value: v, handleInput, errorMessage } = useField(
      inputName,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.value,
      }
    )

    const updateValue = event => {
      emit('update:modelValue', event.target.value)
      emit('change', event)
      handleInput(event.target.value)
    }

    watch(props, () => {
      handleInput(props.value)
    })

    return {
      updateValue,
      v,
      errorMessage,
      inputName,
    }
  },
})
</script>
