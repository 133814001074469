<template>
  <a
    class="flex flex-col align-center items-center justify-center
    md:flex-row md:space-x-2"
    :href="`${hrefType}:${text.replaceAll(' ', '')}`"
  >
    <svg-icon :name="icon" class="w-4 h-4" />
    <p class="font-roboto text-2xs font-bold md:text-xs md:font-medium">
      {{ text }}
    </p>
  </a>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { MAIL_ICON, PHONE_ICON } from '@/constants/icon-constants'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const hrefType = computed(() => {
      switch (props.icon) {
        case MAIL_ICON:
          return 'mailto'
        case PHONE_ICON:
          return 'tel'
        default:
          return ''
      }
    })
    return {
      hrefType,
    }
  },
})
</script>
