
import { defineComponent, PropType } from 'vue'
import Button from '@/components/common/form/button.vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'

export default defineComponent({
  emits: ['clicked'],
  components: { Button },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()

    const emitClicked = (is_accepted_by_user: boolean) => {
      emit('clicked', { is_accepted_by_user })
    }

    return {
      emitClicked,
      t,
      TC,
    }
  },
})
