
import { defineComponent, onMounted, PropType } from 'vue'
import Button from '@/components/common/form/button.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'
import FileList from '@/components/common/list/file-list.vue'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'

export default defineComponent({
  components: { Button, FileList },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_expose.has_visited_step) {
        BuyJourneyModule.updateExposeStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_expose.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    const updateHasExposeDownloaded = isChecked => {
      if (isChecked === true || isChecked === false) {
        BuyJourneyModule.updateExposeStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_expose.id,
          payload: {
            is_expose_downloaded: isChecked,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      updateHasExposeDownloaded,
      t,
      TC,
    }
  },
})
