import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators'
import store from '@/store'
import { BuyJourneyAPI } from '@/api/buy-journey.api'
import { ProposalModelInterface } from '@/models/proposal.model'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import { BuyJourneyStepExposePatchPayloadInterface } from '@/models/buy-journey-steps/step-expose/buy-journey-step-expose.model'
import { BuyJourneyStepCalculationPatchPayloadInterface } from '@/models/buy-journey-steps/step-calculation/buy-journey-step-calculation.model'
import { QuestionAnswerPayloadInterface } from '@/models/buy-journey-steps/step-question/question-answer.model'
import { UserAppointmentPayloadInterface } from '@/models/buy-journey-steps/step-visitation/user-appointment.model'
import { BuyJourneyStepFinancingPayloadInterface } from '@/models/buy-journey-steps/step-financing/buy-journey-step-financing.model'
import { BuyJourneyStepVisitationPayloadInterface } from '@/models/buy-journey-steps/step-visitation/buy-journey-step-visitation.model'
import { BuyJourneyStepQuestionPayloadInterface } from '@/models/buy-journey-steps/step-question/buy-journey-step-question.model'
import { FinancingOfferDocumentGroupPayloadInterface } from '@/models/buy-journey-steps/step-financing/financing-offer-document-group.model'
import { BuyJourneyStepBuyContractAndNotaryAppointmentPayloadInterface } from '@/models/buy-journey-steps/step-buy-contract-and-notary-appointment/buy-journey-step-buy-contract-and-notary-appointment.model'
import { NotaryAppointmentPatchPayloadInterface } from '@/models/buy-journey-steps/step-buy-contract-and-notary-appointment/notary-appointment.model'
import { BuyJourneyStepLoanContractPayloadInterface } from '@/models/buy-journey-steps/step-loan-contract/buy-journey-step-loan-contract.model'
import { BuyJourneyStepTransferOfOwnershipPayloadInterface } from '@/models/buy-journey-steps/step-transfer-of-ownership/buy-journey-step-transfer-of-ownership.model'
import { BuyContractDraftDocumentGroupPayloadInterface } from '@/models/buy-journey-steps/step-buy-contract-and-notary-appointment/buy-contract-draft-document-group.model'
import { LoadingModule } from './loading.module'

export const BUY_JOURNEY_MODULE_NAME = 'BUY_JOURNEY'
export interface BuyJourneyStateInterface {
  proposals: ProposalModelInterface[]
  openProposal: ProposalModelInterface | null
}

@Module({ dynamic: true, store, name: BUY_JOURNEY_MODULE_NAME })
class BuyJourney extends VuexModule implements BuyJourneyStateInterface {
  proposals: ProposalModelInterface[] = []
  openProposal: ProposalModelInterface | null = null
  buyJourneys: BuyJourneyModelInterface[] = []
  activeBuyJourney: BuyJourneyModelInterface | null = null

  @Mutation
  private SET_PROPOSALS(proposals: ProposalModelInterface[]): void {
    this.proposals = proposals
  }

  @Mutation
  private SET_PROPOSAL(proposal: ProposalModelInterface): void {
    this.openProposal = proposal
  }

  @Mutation
  private ADD_BUY_JOURNEY(buyJourney: BuyJourneyModelInterface): void {
    this.buyJourneys.push(buyJourney)
  }

  @Mutation
  private UPDATE_BUY_JOURNEY(buyJourney: BuyJourneyModelInterface): void {
    this.buyJourneys = this.buyJourneys.map(existingBuyJourney =>
      existingBuyJourney.id === buyJourney.id ? buyJourney : existingBuyJourney
    )
  }

  @Mutation
  private SET_BUY_JOURNEYS(buyJourneys: BuyJourneyModelInterface[]): void {
    this.buyJourneys = buyJourneys
  }

  @Mutation
  private SET_ACTIVE_BUY_JOURNEY(buyJourney: BuyJourneyModelInterface): void {
    this.activeBuyJourney = buyJourney
  }

  @Action
  public async getProposals(): Promise<void> {
    LoadingModule.SET_IS_PROPOSALS_LIST_LOADING(this.proposals.length === 0)
    const proposals = await BuyJourneyAPI.getProposals()
    this.SET_PROPOSALS(proposals)
    LoadingModule.SET_IS_PROPOSALS_LIST_LOADING(false)
  }

  @Action
  public async getProposal(id: string): Promise<void> {
    LoadingModule.SET_IS_PROPOSAL_LOADING(true)
    const proposal = await BuyJourneyAPI.getProposal(id)
    this.SET_PROPOSAL(proposal)
    LoadingModule.SET_IS_PROPOSAL_LOADING(false)
  }

  @Action
  public async createBuyJourney(id: string): Promise<BuyJourneyModelInterface> {
    const buyJourney = await BuyJourneyAPI.createBuyJourney(id)
    this.ADD_BUY_JOURNEY(buyJourney)
    return buyJourney
  }

  @Action
  public async getBuyJourneys(): Promise<void> {
    LoadingModule.SET_IS_INVESTMENTS_LIST_LOADING(this.buyJourneys.length === 0)
    const buyJourneys = await BuyJourneyAPI.getBuyJourneys()
    const activeBuyJourneys = buyJourneys.filter(
      buyJourney => !buyJourney.deactivated
    )
    this.SET_BUY_JOURNEYS(activeBuyJourneys)
    LoadingModule.SET_IS_INVESTMENTS_LIST_LOADING(false)
  }

  @Action
  public async getBuyJourney(id: string): Promise<void> {
    LoadingModule.SET_IS_BUY_JOURNEY_LOADING(true)
    let result = this.buyJourneys.find(buy_journey => buy_journey.id === id)
    if (!result) result = await BuyJourneyAPI.getBuyJourney(id)
    this.SET_ACTIVE_BUY_JOURNEY(result)
    LoadingModule.SET_IS_BUY_JOURNEY_LOADING(false)
  }

  @Action
  public async updateExposeStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepExposePatchPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateExposeStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateCalculationStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepCalculationPatchPayloadInterface
  }): Promise<void> {
    LoadingModule.SET_IS_UPLOAD_CALCULATION_DOCUMENTS_LOADING(true)
    const buyJourney = await BuyJourneyAPI.updateCalculationStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
    LoadingModule.SET_IS_UPLOAD_CALCULATION_DOCUMENTS_LOADING(false)
  }

  /** Upload binary files for the buy journey calculation step. */
  @Action
  public async createUserCalculationDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<void> {
    LoadingModule.SET_IS_UPLOAD_CALCULATION_DOCUMENTS_LOADING(true)
    const buyJourney = await BuyJourneyAPI.createUserCalculationDocuments(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
    LoadingModule.SET_IS_UPLOAD_CALCULATION_DOCUMENTS_LOADING(false)
  }

  @Action
  public async updateQuestionStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepQuestionPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateQuestionStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateQuestionAnswer(input: {
    buyJourneyId: string
    stepId: string
    questionId: string
    payload: QuestionAnswerPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateQuestionAnswer(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async createQuestionAnswer(input: {
    buyJourneyId: string
    stepId: string
    payload: QuestionAnswerPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.createQuestionAnswer(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async createUserVisitationAppointment(input: {
    buyJourneyId: string
    stepId: string
    payload: UserAppointmentPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.createUserVisitationAppointment(
      input
    )
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateVisitationStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepVisitationPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateVisitationStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateFinancingStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepFinancingPayloadInterface
  }): Promise<void> {
    LoadingModule.SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(true)
    const buyJourney = await BuyJourneyAPI.updateFinancingStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
    LoadingModule.SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(false)
  }

  /** Upload binary files for the buy journey financing step. */
  @Action
  public async createUserSelfDisclosureDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<void> {
    LoadingModule.SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(true)
    const buyJourney = await BuyJourneyAPI.createUserSelfDisclosureDocuments(
      input
    )
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
    LoadingModule.SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(false)
  }

  /** Upload binary files for the buy journey financing step. */
  @Action
  public async createOwnFinancingDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<void> {
    LoadingModule.SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(true)
    const buyJourney = await BuyJourneyAPI.createOwnFinancingDocuments(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
    LoadingModule.SET_IS_UPLOAD_FINANCING_DOCUMENTS_LOADING(false)
  }

  @Action
  public async updateFinancingOfferDocuments(input: {
    buyJourneyId: string
    stepId: string
    financingOfferId: string
    payload: FinancingOfferDocumentGroupPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateFinancingOfferDocuments(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateBuyContractAndNotaryAppointmentStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepBuyContractAndNotaryAppointmentPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateBuyContractAndNotaryAppointmentStep(
      input
    )
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateNotaryAppointment(input: {
    buyJourneyId: string
    stepId: string
    notaryAppointmentId: string
    payload: NotaryAppointmentPatchPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateNotaryAppointment(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateBuyContractDraftDocuments(input: {
    buyJourneyId: string
    stepId: string
    buyContractDraftId: string
    payload: BuyContractDraftDocumentGroupPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateBuyContractDraftDocuments(
      input
    )
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateLoanContractStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepLoanContractPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateLoanContractStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  /** Upload binary files for the buy journey calculation step. */
  @Action
  public async createSelfFinancedDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.createSelfFinancedDocuments(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }

  @Action
  public async updateNotaryAppointmentStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepTransferOfOwnershipPayloadInterface
  }): Promise<void> {
    const buyJourney = await BuyJourneyAPI.updateNotaryAppointmentStep(input)
    this.SET_ACTIVE_BUY_JOURNEY(buyJourney)
    this.UPDATE_BUY_JOURNEY(buyJourney)
  }
}

export const BuyJourneyModule = getModule(BuyJourney)
