
import { useRoute } from 'vue-router'
import SmallSubtitle from '@/components/common/typography/small-subtitle.vue'
import { defineComponent } from 'vue'
import useTranslation from '@/composables/use-translation'
import HeaderTitle from '@/components/common/typography/header-title.vue'

export default defineComponent({
  components: {
    SmallSubtitle,
    HeaderTitle,
  },
  setup() {
    const route = useRoute()
    const t = useTranslation()

    return {
      t,
      route,
    }
  },
})
