
import { computed, defineComponent, onBeforeMount, PropType } from 'vue'
import { UserModule } from '@/store/modules/user.module'
import CheckBox from '@/components/common/form/checkbox.vue'
import { InvestmentEffortTypeModelInterface } from '@/models/investment-effort-type.model'
import { RealEstateTypeModelInterface } from '@/models/real-estate-type.model'

export default defineComponent({
  components: {
    CheckBox,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Object as PropType<
        | Array<InvestmentEffortTypeModelInterface>
        | Array<RealEstateTypeModelInterface>
      >,
      required: true,
    },
    checkedItems: {
      type: Object as PropType<
        | Array<InvestmentEffortTypeModelInterface>
        | Array<RealEstateTypeModelInterface>
      >,
      required: true,
    },
    groupName: {
      type: String,
      default: '',
    },
    rules: [Object, String],
  },
  setup(props) {
    const allItems = computed(() => UserModule.investmentEffortTypes)
    const user = computed(() => UserModule.user)

    onBeforeMount(() => {
      UserModule.verifyAuthentication()
    })

    const isCheckedByUser = (
      item: InvestmentEffortTypeModelInterface
    ): boolean => {
      return !!props.checkedItems.find(e => e.id == item.id)
    }

    return {
      allItems,
      user,
      isCheckedByUser,
    }
  },
})
