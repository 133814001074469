<template>
  <div
    class="flex items-center justify-center border border-black rounded w-4 h-4"
  >
    <div
      v-if="!isCompletable"
      class="border border-black rounded bg-black h-3 w-3 cursor-pointer"
    />
    <fa
      class="w-4/6"
      v-else-if="isCompleted"
      :icon="checkIcon"
      color="#20D17E"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  props: {
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isCompletable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      checkIcon: Icons.CHECK_ICON,
    }
  },
})
</script>
