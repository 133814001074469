<template>
  <div>
    <div
      class="flex flex-initial justify-between items-center bg-white border border-black h-12 px-5 space-x-2"
      :class="{ 'border-red-500 animate-error-shake': errorMessage }"
    >
      <input
        ref="inputElement"
        class="outline-none w-full text-sm min-w-0 disabled:opacity-50"
        @keydown.enter.prevent
        :readonly="isReadonly"
        :type="type"
        :placeholder="placeholder"
        :id="inputName"
        :value="v"
        :disabled="disabled"
        @input="updateValue"
        :autocomplete="type === 'password' ? 'current-password' : 'off'"
      />
      <label class="text-2xs text-right whitespace-no-wrap" :for="inputName">
        {{ label }}
      </label>
    </div>
    <ErrorMessage :text="errorMessage" />
  </div>
</template>

<script lang="ts">
import ErrorMessage from '@/components/common/form/error-message.vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  emits: ['update:modelValue', 'input'],
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<
        'text' | 'password' | 'email' | 'tel' | 'number' | 'boolean'
      >,
      default: 'text',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean],
    },
    rules: [Object, String],
    value: {
      type: [String, Number],
    },
  },
  setup(props, { emit }) {
    const inputName = computed(() => props.name || ''.toUUID())
    const { value: v, handleInput, errorMessage } = useField(
      inputName,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.value,
      }
    )

    const inputElement = ref<HTMLInputElement | null>(null)

    const updateValue = event => {
      const value = event.target.value.trim()
      emit('update:modelValue', value)
      emit('input', event)
      handleInput(value)
    }

    watch(props, () => {
      handleInput(props.value)
    })

    return {
      inputElement,
      updateValue,
      v,
      errorMessage,
      inputName,
    }
  },
})
</script>
