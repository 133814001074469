import { BackendErrorMessage } from '@/utils/backend/interfaces/error_message_interface'
import useTranslation from '@/composables/use-translation.ts'
import * as TC from '@/constants/translation-constants.ts'

export interface UseError {
  getMessage: (error, fallbackErrorMessage?) => string
  getErrorCode: (error) => number
}

export default function useError(): UseError {
  const t = useTranslation()

  function getErrorCode(error): number {
    const genericError = error as Error
    const backendError = error as BackendErrorMessage
    const genericErrorStatus = parseInt(genericError.message.slice(-3))
    if (backendError.response.data.status) {
      return backendError.response.data.status
    } else if (genericErrorStatus >= 400) {
      return genericErrorStatus
    }
    return 400
  }

  function getMessage(
    error,
    fallbackErrorMessage = t(TC.AN_ERROR_OCCURED)
  ): string {
    const backendError = error as BackendErrorMessage
    if (backendError.response.data.detail) {
      return backendError.response.data.detail
    } else if (getErrorCode(error) === 404) {
      return t(TC.THE_REQUIRED_RESOURCE_WAS_NOT_FOUND)
    }
    return fallbackErrorMessage
  }
  return { getMessage, getErrorCode }
}
