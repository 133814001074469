export const USER = 'user'
export const FIRST_NAME = 'first_name'
export const LAST_NAME = 'last_name'
export const PHONE = 'phone'
export const JOB = 'job'
export const EMAIL = 'email'
export const ADDRESS = 'address'
export const COUNTRY = 'country'
export const STREET = 'sreet'
export const POSTCODE = 'zipcode'
export const HOUSENUMBER = 'housenumber'
export const CITY = 'city'
export const SALARY = 'salary'
export const EQUITY = 'equity'

export const USER_SEARCH_PROFILE = 'user_search_profile'
export const MODIFIED_AT = 'modified_at'
export const INVESTMENT_VOLUME = 'investment_volume'
export const INVESTMENT_STRATEGY = 'investment_strategy'
export const INVESTMENT_GOAL_REASON = 'investment_goal_reason'
export const INVESTMENT_REQIUREMENTS = 'investment_requirements'
export const REAL_ESTATE_COUNT = 'real_estate_count'
export const EMPLOYMENT_STATUS = 'employment_status'
export const REGION = 'region'
export const NEWSLETTER = 'wants_newsletter'
export const BIRTHDAY = 'birthday'

export const INVESTMENT_EFFORT_TYPES = 'investment_effort_types'
export const REAL_ESTATE_TYPES = 'real_estate_types'
export const FINANCIAL_VALUES = 'financial_values'
