<template>
  <slot v-if="!isLoading"></slot>

  <div v-else class="skeleton-loader w-full p-4 mx-auto">
    <div class="flex h-24">
      <Skeletor class="w-full" height="100%" />
    </div>
    <div class="flex mt-2">
      <Skeletor class="w-12 h-12" circle />
      <div class="flex flex-col flex-1 ml-2 justify-around">
        <Skeletor pill />
        <Skeletor pill />
      </div>
    </div>
    <div class="mt-2">
      <Skeletor />
      <Skeletor />
      <Skeletor />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Skeletor } from 'vue-skeletor'

export default defineComponent({
  components: { Skeletor },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
})
</script>
