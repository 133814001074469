import {
  UserModelInterface,
  UserModelPayloadInterface,
} from '@/models/user.model'
import { SearchProfilePatchPayloadInterface } from '@/models/search-profile.model'
import { request, buildURL } from '@/utils/http/request'

export class UserAPI {
  static async login({
    email,
    password,
  }: Record<'email' | 'password', string>): Promise<UserModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('users')
        .join('login')
        .get(),
      {
        email,
        password,
      }
    )
    return data
  }

  static async logout(): Promise<void> {
    await request.get(
      buildURL()
        .join('users')
        .join('logout')
        .get()
    )
  }

  static async resetPassword({
    email,
  }: Record<'email', string>): Promise<UserModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('users')
        .join('reset-password')
        .get(),
      {
        email,
      }
    )
    return data
  }

  static async verifyAuthentication(): Promise<UserModelInterface> {
    const { data } = await request.get(
      buildURL()
        .join('users')
        .join('verify-authentication')
        .get()
    )
    return data
  }

  static async updateUser(
    payload: UserModelPayloadInterface,
    userId: string
  ): Promise<UserModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('users')
        .join(userId)
        .get(),
      payload
    )
    return data
  }

  static async updateSearchProfile(
    payload: SearchProfilePatchPayloadInterface,
    userId: string
  ): Promise<UserModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('users')
        .join(userId)
        .join('search-profiles')
        .join(userId)
        .get(),
      payload
    )
    return data
  }

  static async getInvestmentEffortTypes() {
    const { data } = await request.get(
      buildURL()
        .join('users')
        .join('investment-effort-types')
        .get()
    )
    return data
  }

  static async getRealEstateTypes() {
    const { data } = await request.get(
      buildURL()
        .join('users')
        .join('real-estate-types')
        .get()
    )
    return data
  }

  static async getEmploymentStatusTypes() {
    const { data } = await request.get(
      buildURL()
        .join('users')
        .join('employment-status')
        .get()
    )
    return data
  }

  static async updateAvatar(payload: FormData, userId: string) {
    const { data } = await request.post(
      buildURL()
        .join('users')
        .join(userId)
        .join('set-avatar')
        .get(),
      payload
    )
    return data
  }
}
