
import { defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  emits: ['choice-selected'],
  components: { Button },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()

    const emitChoice = (is_taking_care_of_financing: boolean) => {
      emit('choice-selected', {
        is_taking_care_of_financing,
      })
    }

    return {
      emitChoice,
      t,
      TC,
    }
  },
})
