<template>
  <div class="my-2 mx-4 space-y-2">
    <div v-if="buyJourney.step_financing.is_taking_care_of_financing">
      <transition name="fade-in" mode="out-in">
        <div
          v-if="!buyJourney.step_loan_contract.has_confirmed_own_loan_contracts"
        >
          <p class="text-xs">
            {{ t(TC.WE_NEED_YOUR_AGREEMENT_FOR_THE_LOAN_CONTRACT) }}
          </p>
          <div class="mt-2">
            <CheckBox
              @changed="
                updateLoanContractStep({
                  has_confirmed_own_loan_contracts: $event,
                })
              "
              :label="t(TC.I_RECEIVED_THE_LOAN_CONTRACT_AND_SIGNED_IT)"
              name="confirm-own-loan-contract"
            />
          </div>
        </div>
        <div v-else class="flex items-center space-x-2 mt-2">
          <fa class="w-8 text-green" icon="check" />
          <p class="text-xs">
            {{ t(TC.THANK_YOU_FOR_CONFIRMING_YOUR_LOAN_CONTRACT) }}
          </p>
        </div>
      </transition>
      <p class="text-xs mt-4">
        {{ t(TC.YOU_CAN_UPLOAD_YOUR_LOAN_CONTRACTS) }}
      </p>
      <FileUploadList
        @on-upload="createSelfFinancedDocuments"
        :placeholderText="t(TC.UPLOAD_YOUR_LOAN_CONTRACT)"
      />
    </div>

    <div v-else-if="buyJourney.step_loan_contract.loan_contract">
      <p class="text-xs font-bold">
        {{ t(TC.DOWNLOAD_YOUR_LOAN_CONTRACT) }}
      </p>
      <FileList
        :documents="buyJourney.step_loan_contract.loan_contract.documents"
      />
    </div>
    <p v-else class="text-xs font-bold">
      {{ t(TC.WE_PREPARE_YOUR_LOAN_CONTRACT) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import FileList from '@/components/common/list/file-list.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import CheckBox from '@/components/common/form/checkbox.vue'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { FileList, FileUploadList, CheckBox },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateLoanContractStep = payload => {
      BuyJourneyModule.updateLoanContractStep({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_loan_contract.id,
        payload,
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    const createSelfFinancedDocuments = async (
      files: File[],
      resetCallback: Function
    ) => {
      const bodyFormData = new FormData()
      files.forEach(file => bodyFormData.append(file.name, file))
      try {
        await BuyJourneyModule.createSelfFinancedDocuments({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_loan_contract.id,
          payload: bodyFormData,
        })
        toast.success(t(TC.YOUR_FILES_HAVE_BEEN_UPLOADED))
        resetCallback()
      } catch (error) {
        toast.error(getMessage(error))
      }
    }

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_loan_contract.has_visited_step) {
        updateLoanContractStep({
          has_visited_step: true,
          is_expanded: true,
        })
      }
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      updateLoanContractStep,
      createSelfFinancedDocuments,
      t,
      TC,
    }
  },
})
</script>
