<template>
  <div v-if="questions.length" class="mt-4">
    <p class="bg-primary text-white text-center p-1">
      {{ t(TC.ANSWERED_QUESTIONS) }} ({{ questions.length }})
    </p>
    <div
      v-for="question of questions"
      :key="question"
      class="text-sm p-2 bg-blue-100 odd:bg-white border mt-1"
    >
      <div
        @click="$emit('toggle-collapse', question)"
        class="cursor-pointer flex justify-between items-center w-full"
      >
        <p>{{ question.question }}</p>
        <div
          class="transform rotate-0 transition-transform duration-300"
          :class="{
            '-rotate-180': !question.is_collapsed,
          }"
        >
          <fa class="w-3 h-3 text-primary" :icon="angleUpIcon" />
        </div>
      </div>

      <div v-if="!question.is_collapsed" class="m-3 p-2 bg-white text-xs">
        <p class="font-bold underline">{{ t(TC.ANSWER_OF_YOUR_ADVISOR) }}:</p>
        <p class="mt-4 wysiwyg-content" v-html="question.answer"></p>
      </div>
      <p v-else class="my-3 p-2 bg-white text-2xs text-center">
        {{ t(TC.CLICK_YOU_QUESTION_TO_SHOW_ANSWER) }}
      </p>
      <p class="text-2xs mt-2 text-right">
        {{ new Date(question.modified_at).toGermanDate() }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { QuestionAnswerModelInterface } from '@/models/buy-journey-steps/step-question/question-answer.model'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  emits: ['toggle-collapse'],
  props: {
    questions: {
      type: Array as PropType<Array<QuestionAnswerModelInterface>>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()

    return {
      angleUpIcon: Icons.ANGLE_UP_ICON,
      t,
      TC,
    }
  },
})
</script>
