<template>
  <p
    @click.stop="$emit('click')"
    class="text-xs text-primary text-center cursor-pointer hover:underline"
  >
    <slot></slot>
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['click'],
})
</script>
