
import useRouter from '@/composables/use-router'
import useResize from '@/composables/use-resize'
import { computed, defineComponent } from 'vue'
import { CHAT_MENU_ICON } from '@/constants/icon-constants'
import useCrispButton from '@/composables/use-crisp'
import NavbarLoader from '@/components/common/loader/navbar-loader.vue'
import { LoadingModule } from '@/store/modules/loading.module'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  components: {
    NavbarLoader,
  },
  setup() {
    const { mainNavigationRoutes } = useRouter()
    const { isMobile } = useResize()
    const { openChat } = useCrispButton()
    const isChatLoading = computed(() => LoadingModule.isChatLoading)
    const t = useTranslation()

    function openChatbox() {
      LoadingModule.SET_IS_CHAT_LOADING(true)
      openChat({
        onChatOpened: () => LoadingModule.SET_IS_CHAT_LOADING(false),
      })
    }

    return {
      mainNavigationRoutes,
      isMobile,
      CHAT_MENU_ICON,
      openChatbox,
      isChatLoading,
      t,
    }
  },
})
