<template>
  <div class="my-2 mx-4">
    <div v-if="buyJourney.real_estate.has_financing_partner">
      <FinancingOffer :buyJourney="buyJourney" />
      <hr class="mt-4" />
      <FinancingPartner :buyJourney="buyJourney" />
    </div>
    <div v-else>
      <FinancingChoice
        v-if="buyJourney.step_financing.is_taking_care_of_financing === null"
        @choice-selected="updateFinancingStep($event)"
        :buyJourney="buyJourney"
      />
      <SelfFinancing
        v-else-if="
          buyJourney.step_financing.is_taking_care_of_financing === true
        "
        @checked-taken-care="updateFinancingStep($event)"
        @checked-received-offers="updateFinancingStep($event)"
        @on-upload="createOwnFinancingDocuments"
        :buyJourney="buyJourney"
      />
      <HelpFinancing
        v-else-if="
          buyJourney.step_financing.is_taking_care_of_financing === false
        "
        @on-upload="createUserSelfDisclosureDocuments"
        :buyJourney="buyJourney"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import FinancingPartner from '@/components/buy-journey/steps/step-financing/financing-partner.vue'
import FinancingOffer from '@/components/buy-journey/steps/step-financing/financing-offer.vue'
import FinancingChoice from '@/components/buy-journey/steps/step-financing/financing-choice.vue'
import SelfFinancing from '@/components/buy-journey/steps/step-financing/self-financing.vue'
import HelpFinancing from '@/components/buy-journey/steps/step-financing/help-financing.vue'

import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: {
    FinancingPartner,
    FinancingOffer,
    FinancingChoice,
    SelfFinancing,
    HelpFinancing,
  },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_financing.has_visited_step) {
        BuyJourneyModule.updateFinancingStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_financing.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    const createUserSelfDisclosureDocuments = async (
      files: File[],
      resetCallback: Function
    ) => {
      const bodyFormData = new FormData()
      files.forEach(file => bodyFormData.append(file.name, file))
      try {
        await BuyJourneyModule.createUserSelfDisclosureDocuments({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_financing.id,
          payload: bodyFormData,
        })
        toast.success(t(TC.YOUR_FILES_HAVE_BEEN_UPLOADED))
        resetCallback()
      } catch (error) {
        toast.error(getMessage(error))
      }
    }

    const createOwnFinancingDocuments = async (
      files: File[],
      resetCallback: Function
    ) => {
      const bodyFormData = new FormData()
      files.forEach(file => bodyFormData.append(file.name, file))
      try {
        await BuyJourneyModule.createOwnFinancingDocuments({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_financing.id,
          payload: bodyFormData,
        })
        toast.success(t(TC.YOUR_FILES_HAVE_BEEN_UPLOADED))
        resetCallback()
      } catch (error) {
        toast.error(getMessage(error))
      }
    }

    const updateFinancingStep = (payload: object) => {
      BuyJourneyModule.updateFinancingStep({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_financing.id,
        payload: payload,
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      createOwnFinancingDocuments,
      createUserSelfDisclosureDocuments,
      updateFinancingStep,
    }
  },
})
</script>
