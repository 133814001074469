<template>
  <div
    class="
    flex flex-col md:flex-row
    max-w-screen"
  >
    <div
      class="
        relative
        md:flex md:flex-col
        md:h-screen
        md:shadow-lg"
    >
      <div class="md:fixed md:w-72 bg-white  md:h-screen md:flex md:flex-col">
        <div
          :class="{
            'hidden md:block': hideHeaderOnMobile,
            'md:hidden': hideHeaderOnDesktop,
          }"
        >
          <component :is="$route.meta.customHeader.component" />
        </div>

        <MainNavigation />
        <div class="hidden md:block md:flex-1"></div>
        <Avatar :user="user" />
      </div>
    </div>
    <div
      class="
        flex flex-col
        w-full h-full
        md:pt-8 md:pl-72 md:pr-8
        min-h-screen
      "
    >
      <MainHeader
        id="mainHeader"
        :route="$route.meta"
        :class="{
          'hidden md:block': hideTitleOnMobile,
        }"
      />
      <router-view class="md:mb-16"></router-view>
      <div class="flex-1"></div>
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import MainNavigation from '@/components/main-navigation/index.vue'
import MainHeader from '@/components/main-header/index.vue'
import Avatar from '@/components/profile/common/avatar.vue'
import { defineComponent, onMounted, computed } from 'vue'
import useResize from '@/composables/use-resize'
import useCrisp from '@/composables/use-crisp'
import { UserModule } from '@/store/modules/user.module'
import Footer from '@/components/dashboard/footer/index.vue'
import { useRoute } from 'vue-router'
import * as CommonConstants from '@/constants/common'
import * as Routes from '@/constants/router-constants'

export default defineComponent({
  components: {
    MainNavigation,
    MainHeader,
    Avatar,
    Footer,
  },
  setup() {
    const { isMobile, onResize } = useResize()
    const route = useRoute()
    const user = computed(() => UserModule.user)
    const hideHeaderOnMobile = computed(() => {
      return !route.meta.customHeader.showOn.includes(CommonConstants.MOBILE)
    })
    const hideHeaderOnDesktop = computed(() => {
      return !route.meta.customHeader.showOn.includes(CommonConstants.DESKTOP)
    })

    const hideTitleOnMobile = computed(() => {
      return !route.meta.showOnMobile && route.name === Routes.PROFILE.name
    })

    const {
      initCrisp,
      hideCrispButton,
      showCrispButton,
      hideCrispButtonOnChatClose,
      showCrispButtonOnChatClose,
    } = useCrisp()

    function hideOrShowCrispButton() {
      if (isMobile.value) {
        hideCrispButton()
        hideCrispButtonOnChatClose()
      } else {
        showCrispButton()
        showCrispButtonOnChatClose()
      }
    }

    onResize(() => {
      hideOrShowCrispButton()
    })

    onMounted(() => {
      initCrisp().then(() => {
        hideOrShowCrispButton()
      })
    })

    return {
      user,
      hideHeaderOnMobile,
      hideHeaderOnDesktop,
      hideTitleOnMobile,
      Routes,
    }
  },
})
</script>
