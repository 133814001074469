export const ROOT = {
  path: '/',
  name: 'Root',
}

export const AUTHORIZATION = {
  path: 'auth',
  name: 'Authorization',
}

export const DASHBOARD = {
  path: '/',
  name: 'Dashboard',
}

export const SIGN_IN = {
  path: 'sign-in',
  name: 'SignIn',
}

export const FORGOT_PASSWORD = {
  path: 'forgot-password',
  name: 'ForgotPassword',
}

export const INVESTMENTS = {
  path: 'investments',
  name: 'Investments',
}

export const INVESTMENT_DETAIL = {
  path: ':buy_journey_id',
  name: 'Investment Detail',
}

export const PROPOSALS = {
  path: 'proposals',
  name: 'Proposals',
}

export const PROPOSAL_DETAIL = {
  path: ':proposal_id',
  name: 'ProposalDetail',
}

export const PROFILE = {
  path: 'profile',
  name: 'Profile',
}
