
import { defineComponent, ref, PropType, reactive, onMounted } from 'vue'
import useTranslation from '@/composables/use-translation'
import RealEstateIcon from '@/components/proposals/real-estate-icon/index.vue'
import CardCorner from '@/components/proposals/proposal-card/card-corner.vue'
import Button from '@/components/common/form/button.vue'
import useResize from '@/composables/use-resize'
import { ProposalModelInterface } from '@/models/proposal.model'
import { RealEstateModelFunctions } from '@/models/real-estate.model'
import * as TC from '@/constants/translation-constants.ts'

export default defineComponent({
  props: {
    proposal: {
      type: Object as PropType<ProposalModelInterface>,
      required: true,
    },
  },
  components: {
    RealEstateIcon,
    CardCorner,
    Button,
  },
  setup(props) {
    const t = useTranslation()
    const { onResize } = useResize()

    const cardElement = ref<HTMLElement | null>(null)
    const amountOfDescriptionLetters = ref(0)
    const realEstate = reactive(props.proposal.real_estate)
    const priceFormatted = RealEstateModelFunctions.getPriceFormattedOf(
      realEstate
    )

    const adjustTextSize = () => {
      if (cardElement.value) {
        const baseWidth = {
          screenWidth: 375,
          scaleFactor: 3.5,
        }
        amountOfDescriptionLetters.value =
          cardElement.value.clientWidth / baseWidth.scaleFactor
      }
    }

    onResize(() => adjustTextSize())

    onMounted(() => {
      adjustTextSize()
    })

    return {
      priceFormatted,
      realEstate,
      cardElement,
      t,
      TC,
    }
  },
})
