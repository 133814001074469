
import { defineComponent, computed } from 'vue'
import { UserModule } from '@/store/modules/user.module'
import useTranslation from '@/composables/use-translation.ts'
import Input from '@/components/common/form/input.vue'
import * as TC from '@/constants/translation-constants.ts'
import { string } from 'yup'
import * as propertyConstants from '@/constants/property-constants.ts'

export default defineComponent({
  components: {
    Input,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    groupName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const t = useTranslation()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)

    const formData = {
      country: {
        name: propertyConstants.COUNTRY,
        label: t(TC.COUNTRY),
        rules: string().required(t(TC.COUNTRY_FORM_VALIDATION_ERROR)),
      },
      city: {
        name: propertyConstants.CITY,
        label: t(TC.CITY),
        rules: string().required(t(TC.CITY_FORM_VALIDATION_ERROR)),
      },
      street: {
        name: propertyConstants.STREET,
        label: t(TC.STREET),
        rules: string().required(t(TC.STREET_FORM_VALIDATION_ERROR)),
      },
      number: {
        name: propertyConstants.HOUSENUMBER,
        label: t(TC.NUMBER),
        rules: string().required(t(TC.NUMBER_FORM_VALIDATION_ERROR)),
      },
      postcode: {
        name: propertyConstants.POSTCODE,
        label: t(TC.ZIP_CODE),
        rules: string().required(t(TC.ZIP_CODE_FORM_VALIDATION_ERROR)),
      },
    }

    return {
      t,
      TC,
      isProfileEditable,
      formData,
    }
  },
})
