
import { defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyStepVisitationModelInterface } from '@/models/buy-journey-steps/step-visitation/buy-journey-step-visitation.model'

export default defineComponent({
  props: {
    step: {
      type: Object as PropType<BuyJourneyStepVisitationModelInterface>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
})
