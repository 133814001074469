import { onMounted, onUnmounted, ref } from 'vue'

type CallBackFunction = (width: number, height?: number) => void
let hasGlobalResizeEventRegistered = false
const innerWidth = ref(window.innerWidth)
const isMobile = ref(innerWidth.value <= 768)
const isTablet = ref(innerWidth.value > 768 && innerWidth.value <= 1024)
const isDesktop = ref(innerWidth.value > 1024)

export default function useResize() {
  if (!hasGlobalResizeEventRegistered) {
    // Prevent registering endless events
    hasGlobalResizeEventRegistered = true
    window.addEventListener('resize', () => {
      innerWidth.value = window.innerWidth
      isMobile.value = innerWidth.value <= 768
      isTablet.value = innerWidth.value > 768 && innerWidth.value <= 1024
      isDesktop.value = innerWidth.value > 1024
    })
  }

  function onResize(callback: CallBackFunction) {
    const callbackHandler = () => callback(innerWidth.value, window.innerHeight)
    onMounted(() => {
      window.addEventListener('resize', callbackHandler)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', callbackHandler)
    })
  }

  return {
    onResize,
    isMobile,
    isTablet,
    isDesktop,
  }
}
