
import { defineComponent, PropType } from 'vue'
import useNumbers from '@/composables/use-numbers'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import CircularProgress from '@/components/common/progress/circular-progress.vue'

export default defineComponent({
  components: { CircularProgress },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { priceFormatted } = useNumbers(props.buyJourney.real_estate.price)

    return {
      priceFormatted,
    }
  },
})
