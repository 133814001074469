<template>
  <div class="px-8 md:px-0">
    <div
      class="flex flex-col items-center border-b border-light-gray pb-8 space-y-6"
    >
      <Image
        class="w-95% max-w-2xs md:max-w-full pt-4"
        src="investment-not-found.svg"
      />
      <p
        class="w-95% max-w-2xs md:max-w-full pb-4 text-primary font-bold text-lg font-poppins leading-tight text-justify"
      >
        {{ t(TC.NO_INVESTMENTS_FOUND) }}
      </p>
      <div class="flex col justify-center w-95% pb-4">
        <Button @click="redirectToProposals">
          {{ t(TC.SHOW_MATCHING_ESTATES) }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Button from '@/components/common/form/button.vue'
import { useRouter } from 'vue-router'
import Image from '@/components/common/image.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import * as Routes from '@/constants/router-constants'

export default defineComponent({
  components: {
    Button,
    Image,
  },
  setup() {
    const t = useTranslation()
    const { push } = useRouter()
    function redirectToProposals() {
      push({ name: Routes.PROPOSALS.name })
    }

    return {
      redirectToProposals,
      t,
      TC,
    }
  },
})
</script>
