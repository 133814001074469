
import { defineComponent } from 'vue'
import ForgotPasswordForm from '@/components/authorization/forgot-password-form.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants.ts'
import HeaderWithLogoAuthScreen from '@/components/main-header/header-with-logo-auth-screen.vue'

export default defineComponent({
  components: {
    HeaderWithLogoAuthScreen,
    ForgotPasswordForm,
  },
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
