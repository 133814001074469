
import useResize from '@/composables/use-resize'
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  PropType,
  Component,
} from 'vue'
import { includes } from 'lodash'
import { UserModelInterface } from '@/models/user.model'

export interface TabInterface {
  text: string
  contentComponent: Component
  props: Record<string, object | UserModelInterface | null>
}

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Array<TabInterface>>,
      required: true,
    },
    defaultTabIndex: {
      type: Number,
      default: 0,
    },
    invalidTabs: {
      type: Array as PropType<Array<string>>,
      default: [],
    },
  },
  emits: ['tab-change'],
  setup(props) {
    const tabRefs = reactive<HTMLButtonElement[]>([])
    const line = reactive({ offset: 0, width: 0 })
    const selectedTabIndex = ref(props.defaultTabIndex)
    const { onResize } = useResize()

    const setTab = tabIndex => {
      line.width = tabRefs[tabIndex].clientWidth
      line.offset = tabRefs[tabIndex].offsetLeft + 4
      selectedTabIndex.value = tabIndex
    }

    const onSelectTab = tabIndex => {
      setTab(tabIndex)
    }

    const isTabInvalid = (tab: TabInterface) => {
      return includes(props.invalidTabs, tab.text)
    }

    onMounted(() => {
      setTab(props.defaultTabIndex)
    })

    onResize(() => {
      setTab(selectedTabIndex.value)
    })

    return {
      line,
      onSelectTab,
      tabRefs,
      selectedTabIndex,
      isTabInvalid,
    }
  },
})
