
import { computed, defineComponent } from 'vue'
import { MAIL_ICON, PHONE_ICON } from '@/constants/icon-constants'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const hrefType = computed(() => {
      switch (props.icon) {
        case MAIL_ICON:
          return 'mailto'
        case PHONE_ICON:
          return 'tel'
        default:
          return ''
      }
    })
    return {
      hrefType,
    }
  },
})
