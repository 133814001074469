
import { defineComponent } from 'vue'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  props: {
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isCompletable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      checkIcon: Icons.CHECK_ICON,
    }
  },
})
