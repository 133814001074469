<template>
  <div class="flex flex-col justify-center p-8 bg-dark-white md:bg-opacity-0">
    <h4
      class="font-sans font-family-arial font-bold text-center text-primary text-sm pb-4 md:text-left"
    >
      {{ t(TC.TITLE_PERSONAL_INFORMATION) }}
    </h4>
    <div class="space-y-4 text-primary text-2xs">
      <Input
        :name="`${groupName}[0]country`"
        :rules="formData.country.rules"
        :label="formData.country.label"
        :value="user.address?.country"
      />
      <Input
        :name="`${groupName}[1]postcode`"
        :rules="formData.postcode.rules"
        :label="formData.postcode.label"
        :value="user.address?.postcode"
      />
      <Input
        :name="`${groupName}[2]city`"
        :rules="formData.city.rules"
        :label="formData.city.label"
        :value="user.address?.city"
      />
      <Input
        :name="`${groupName}[3]street`"
        :rules="formData.street.rules"
        :label="formData.street.label"
        :value="user.address?.street"
      />
      <Input
        :name="`${groupName}[4]housenumber`"
        :rules="formData.number.rules"
        :label="formData.number.label"
        :value="user.address?.housenumber"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { UserModule } from '@/store/modules/user.module'
import useTranslation from '@/composables/use-translation.ts'
import Input from '@/components/common/form/input.vue'
import * as TC from '@/constants/translation-constants.ts'
import { string } from 'yup'
import * as propertyConstants from '@/constants/property-constants.ts'

export default defineComponent({
  components: {
    Input,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    groupName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const t = useTranslation()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)

    const formData = {
      country: {
        name: propertyConstants.COUNTRY,
        label: t(TC.COUNTRY),
        rules: string().required(t(TC.COUNTRY_FORM_VALIDATION_ERROR)),
      },
      city: {
        name: propertyConstants.CITY,
        label: t(TC.CITY),
        rules: string().required(t(TC.CITY_FORM_VALIDATION_ERROR)),
      },
      street: {
        name: propertyConstants.STREET,
        label: t(TC.STREET),
        rules: string().required(t(TC.STREET_FORM_VALIDATION_ERROR)),
      },
      number: {
        name: propertyConstants.HOUSENUMBER,
        label: t(TC.NUMBER),
        rules: string().required(t(TC.NUMBER_FORM_VALIDATION_ERROR)),
      },
      postcode: {
        name: propertyConstants.POSTCODE,
        label: t(TC.ZIP_CODE),
        rules: string().required(t(TC.ZIP_CODE_FORM_VALIDATION_ERROR)),
      },
    }

    return {
      t,
      TC,
      isProfileEditable,
      formData,
    }
  },
})
</script>
