<template>
  <img :src="require(`@/assets/images/${src}`)" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
  },
})
</script>
