
import { computed, defineComponent } from 'vue'
import Image from '@/components/common/image.vue'
import CheckAndSubmitCondition from '@/components/common/form/check-and-submit-condition.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import { UserModule } from '@/store/modules/user.module'

export default defineComponent({
  components: {
    Image,
    CheckAndSubmitCondition,
  },
  setup() {
    const t = useTranslation()
    const wantsNewsletter = computed(
      () => UserModule.user?.user_search_profile?.wants_newsletter ?? {}
    )

    return {
      wantsNewsletter,
      t,
      TC,
    }
  },
})
