
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    height: {
      type: Number,
      default: 6,
    },
    width: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const widthClass =
      props.width.toString() === '1' ? 'border-l' : 'border-l-' + props.width
    const heightClass = 'h-' + props.height
    return {
      widthClass,
      heightClass,
    }
  },
})
