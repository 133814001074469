
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: '24px',
    },
  },
  setup(props) {
    const iconUrl = ref('')

    function updateIconURL() {
      const svgIconImport = import(`@/assets/icons/${props.name}.svg`)
      svgIconImport.then(value => {
        if (Object.prototype.hasOwnProperty.call(value, 'default')) {
          iconUrl.value = value.default.url
        }
      })
    }

    updateIconURL()

    watch(
      () => props.name,
      () => {
        updateIconURL()
      }
    )

    function className() {
      return `svg-icon svg-icon--${props.name}`
    }

    return {
      className: className(),
      iconUrl,
    }
  },
})
