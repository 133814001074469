<template>
  <div class="flex flex-col border border-primary bg-white border-collapse">
    <h3 class="text-center text-2xs w-full py-2">
      {{ title }}
    </h3>
    <div class="flex flex-wrap border-collapse">
      <div
        v-for="(item, i) in items"
        :key="item.id"
        class="flex items-center w-1/2"
      >
        <CheckBox
          :label="item.name"
          :checked="isCheckedByUser(item)"
          :name="groupName ? `${groupName}[${i}]${item.id}` : item.id"
          :rules="rules"
          :withBorder="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, PropType } from 'vue'
import { UserModule } from '@/store/modules/user.module'
import CheckBox from '@/components/common/form/checkbox.vue'
import { InvestmentEffortTypeModelInterface } from '@/models/investment-effort-type.model'
import { RealEstateTypeModelInterface } from '@/models/real-estate-type.model'

export default defineComponent({
  components: {
    CheckBox,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Object as PropType<
        | Array<InvestmentEffortTypeModelInterface>
        | Array<RealEstateTypeModelInterface>
      >,
      required: true,
    },
    checkedItems: {
      type: Object as PropType<
        | Array<InvestmentEffortTypeModelInterface>
        | Array<RealEstateTypeModelInterface>
      >,
      required: true,
    },
    groupName: {
      type: String,
      default: '',
    },
    rules: [Object, String],
  },
  setup(props) {
    const allItems = computed(() => UserModule.investmentEffortTypes)
    const user = computed(() => UserModule.user)

    onBeforeMount(() => {
      UserModule.verifyAuthentication()
    })

    const isCheckedByUser = (
      item: InvestmentEffortTypeModelInterface
    ): boolean => {
      return !!props.checkedItems.find(e => e.id == item.id)
    }

    return {
      allItems,
      user,
      isCheckedByUser,
    }
  },
})
</script>
