<template>
  <div class="card-corner">
    <p class="text-2xs">Rundum Sorglos</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss" scoped>
.card-corner {
  position: absolute;
  background: white;
  opacity: 0.8;
  top: -74px;
  right: -46px;
  width: 122px;
  height: 119px;
  transform: rotate3d(0, 0, 1, 35deg);
  p {
    position: absolute;
    left: 38px;
    width: 71px;
    bottom: 6px;
    text-align: center;
    font-weight: bold;
  }
}
</style>
