<template>
  <label class="flex flex-col max-w-xs">
    <div class="flex items-center">
      <div class="w-1/6">
        <CheckBox v-model="isChecked" name="subscribe-newsletter" />
      </div>
      <p class="w-5/6 text-xs text-left">
        {{ conditionTitle }}
      </p>
    </div>
    <div class="flex items-center">
      <div class="w-1/6"></div>
      <p
        v-if="conditionDescription"
        class="w-5/6 text-justify text-2xs text-left font-roboto"
      >
        {{ conditionDescription }}
      </p>
    </div>
    <div class="flex flex-col items-center">
      <Button
        class="w-4/6 mt-3"
        :disabled="!isChecked"
        @click="subscribeToNewsletter"
      >
        {{ buttonText }}
      </Button>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Button from '@/components/common/form/button.vue'
import { UserModule } from '@/store/modules/user.module'
import CheckBox from './checkbox.vue'
import { useToast } from 'vue-toastification'
import { YOU_HAVE_BEEN_SUBSCRIBED } from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  components: {
    Button,
    CheckBox,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    conditionTitle: {
      type: String,
      required: true,
    },
    conditionDescription: {
      type: String,
      required: false,
    },
  },
  setup() {
    const isChecked = ref(false)
    const t = useTranslation()
    const toast = useToast()

    function subscribeToNewsletter() {
      isChecked.value &&
        UserModule.updateSearchProfile({
          wants_newsletter: true,
        }).then(() => {
          toast.success(t(YOU_HAVE_BEEN_SUBSCRIBED))
        })
    }

    return {
      isChecked,
      subscribeToNewsletter,
    }
  },
})
</script>
