<template>
  <div class="my-2 mx-4">
    <div
      v-if="
        buyJourney.step_transfer_of_ownership
          .customer_has_participated_appointment
      "
    >
      <div class="flex items-center space-x-2 mt-2">
        <fa class="w-8 text-green" icon="check" />
        <p class="text-xs">
          {{ t(TC.THANK_YOU_FOR_PARTICIPATING_THE_NOTARY_APPOINTMENT) }}
        </p>
      </div>
      <p
        v-if="buyJourney.step_transfer_of_ownership.ownership_is_transfered"
        class="text-xs mt-4"
      ></p>
      <p v-else class="text-xs mt-4">
        {{ t(TC.OWNERSHIP_TRANSFER_IS_IN_RPOGRESS) }}
      </p>
    </div>
    <div v-else>
      <NotaryAppointment :buyJourney="buyJourney" />
      <p class="text-sm font-bold mt-4">
        {{ t(TC.YOU_DID_NOT_YET_PARTICIPATE_THE_NOTARY_APPOINTMENT) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import NotaryAppointment from './step-buy-contract-and-notary-appointment/notary-appointment.vue'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { NotaryAppointment },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_question.has_visited_step) {
        BuyJourneyModule.updateNotaryAppointmentStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_question.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      t,
      TC,
    }
  },
})
</script>
