<template>
  <div class="mt-4">
    <p class="font-bold">
      {{ t(TC.YOUR_VISITATION_APPOINTMENT_PROPOSALS) }}
    </p>
    <ul class="list-disc list-inside mt-2 text-sm">
      <li v-for="appointment of sortedAppointments" :key="appointment.id">
        {{ new Date(appointment.date).toGermanDate(false) }}
        {{ t(TC.FROM) }} {{ appointment.time_range }}
        {{ t(TC.O_CLOCK) }}
      </li>
    </ul>
    <p class="text-sm mt-2">
      {{ t(TC.WE_INFORM_YOU_ABOUT_A_FINAL_APPOINTMENT) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyStepVisitationModelInterface } from '@/models/buy-journey-steps/step-visitation/buy-journey-step-visitation.model'
import { UserAppointmentModelInterface } from '@/models/buy-journey-steps/step-visitation/user-appointment.model'

export default defineComponent({
  props: {
    step: {
      type: Object as PropType<BuyJourneyStepVisitationModelInterface>,
      required: true,
    },
    sortedAppointments: {
      type: Array as PropType<Array<UserAppointmentModelInterface>>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
})
</script>
