<template>
  <transition name="fade-in">
    <div
      v-if="isLoading"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-75 z-50"
    >
      <div
        class="animate-spin w-4.5 h-4.5 border-2 border-white-600 rounded-full loader"
      ></div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.loader {
  border-right-color: transparent;
}
</style>
