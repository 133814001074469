<template>
  <div class="bg-white max-w-3xl mx-auto">
    <ImageCarousel :images="proposal.real_estate.images" />
    <div
      class="flex flex-row justify-around items-center py-2 h-12 bg-light-blue"
    >
      <RealEstateIcon
        v-for="icon in proposal.real_estate.icons"
        :key="icon.id"
        :icon="icon"
        :dark="true"
      />
    </div>
    <div class="break-words px-8 py-4">
      <div class="relative flex flex-row justify-between items-start">
        <div class="font-bold text-green pb-2">
          {{ priceFormatted }}
        </div>
        <div class="absolute right-0 top-0" style="width: min-content;">
          <div class="uppercase text-sm text-light-gray">
            {{ t(TC.COMPLETELY_CAREFREE) }}
          </div>
        </div>
      </div>
      <div
        v-if="proposal.real_estate.address"
        class="flex items-center space-x-3"
      >
        <fa class="h-4 text-light-gray" icon="map-marker-alt" />
        <span class="uppercase tracking-widest text-sm">
          {{ proposal.real_estate.address.city }}
        </span>
      </div>
      <div v-else></div>
      <div class="flex flex-col mt-3 font-roboto text-xs">
        <div :class="{ 'line-clamp-3': !isDescriptionExpanded }">
          <div v-html="proposal.real_estate.description"></div>
        </div>

        <ClickableText
          v-if="proposal.real_estate.plain_text_description.length > 180"
          @click="isDescriptionExpanded = !isDescriptionExpanded"
          class="mt-4"
        >
          {{ isDescriptionExpanded ? t(TC.SHOW_LESS) : t(TC.SHOW_MORE) }}
        </ClickableText>
      </div>
      <div class="py-2">
        <Rating
          v-for="rating in proposal.real_estate.ratings"
          :key="rating.id"
          :rating="rating"
        />
      </div>
      <Button @click="$emit('clicked')">
        {{ t(TC.REQUEST_EXPOSE) }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue'
import ImageCarousel from '@/components/proposals/proposal-modal/image-carousel.vue'
import RealEstateIcon from '@/components/proposals/real-estate-icon/index.vue'
import Rating from '@/components/proposals/proposal-modal/rating.vue'
import Button from '@/components/common/form/button.vue'
import { ProposalModelInterface } from '@/models/proposal.model'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import { RealEstateModelFunctions } from '@/models/real-estate.model'
import ClickableText from '@/components/common/clickable-text.vue'

export default defineComponent({
  props: {
    proposal: {
      type: Object as PropType<ProposalModelInterface>,
    },
  },
  components: {
    ClickableText,
    ImageCarousel,
    RealEstateIcon,
    Rating,
    Button,
  },
  setup(props) {
    const t = useTranslation()
    const priceFormatted = ref('')
    const isDescriptionExpanded = ref(false)

    onMounted(() => {
      if (props.proposal?.real_estate) {
        priceFormatted.value = RealEstateModelFunctions.getPriceFormattedOf(
          props.proposal.real_estate
        )
      }
    })

    return {
      priceFormatted,
      isDescriptionExpanded,
      t,
      TC,
    }
  },
})
</script>
