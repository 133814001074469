
import { computed, defineComponent, onMounted, PropType } from 'vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'
import QuestionInput from '@/components/buy-journey/steps/step-question/question-input.vue'
import { YOUR_QUESTION_HAS_BEEN_SUBMITTED } from '@/constants/translation-constants'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'
import { QuestionAnswerModelInterface } from '@/models/buy-journey-steps/step-question/question-answer.model'
import AnsweredQuestionList from './step-question/answered-question-list.vue'
import OpenQuestionList from './step-question/open-question-list.vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import ClickableText from '@/components/common/clickable-text.vue'

export default defineComponent({
  components: {
    QuestionInput,
    AnsweredQuestionList,
    OpenQuestionList,
    ClickableText,
  },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateInitialStepValues = () => {
      if (!props.buyJourney.step_question.has_visited_step) {
        BuyJourneyModule.updateQuestionStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_question.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    const openQuestions = computed(() => {
      const filtered_items = props.buyJourney.step_question.questions_and_answers.filter(
        question => question.answer.trim().length === 0
      )
      filtered_items.sort((a, b) => b.created_at.localeCompare(a.created_at))
      return filtered_items
    })

    const answeredQuestions = computed(() => {
      const filtered_items = props.buyJourney.step_question.questions_and_answers.filter(
        question => question.answer.trim().length > 0
      )
      filtered_items.sort((a, b) => b.created_at.localeCompare(a.created_at))
      return filtered_items
    })

    const isCompleted = computed(
      () => props.buyJourney.step_question.is_completed
    )

    const isDisabled = computed(
      () => isCompleted.value || !props.buyJourney.step_question.is_unblocked
    )

    const createQuestionAnswer = (value, resetCallback) => {
      BuyJourneyModule.createQuestionAnswer({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_question.id,
        payload: {
          question: value,
        },
      })
        .then(() => {
          resetCallback()
          toast.success(t(YOUR_QUESTION_HAS_BEEN_SUBMITTED))
        })
        .catch(error => {
          toast.error(getMessage(error))
        })
    }

    const toggleQuestionCollapse = (question: QuestionAnswerModelInterface) => {
      BuyJourneyModule.updateQuestionAnswer({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_question.id,
        questionId: question.id,
        payload: {
          is_collapsed: !question.is_collapsed,
        },
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    const toggleStepCompleted = async () => {
      BuyJourneyModule.updateQuestionStep({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_question.id,
        payload: {
          is_completed: !props.buyJourney.step_question.is_completed,
          is_expanded: props.buyJourney.step_question.is_completed,
        },
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      createQuestionAnswer,
      openQuestions,
      answeredQuestions,
      toggleQuestionCollapse,
      toggleStepCompleted,
      isCompleted,
      isDisabled,
      t,
      TC,
    }
  },
})
