<template>
  <slot v-if="!isLoading" />
  <div v-else class="flex justify-center">
    <div
      class="animate-spin w-4.5 h-4.5 border-2 border-white-600 rounded-full loader"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.loader {
  border-right-color: transparent;
}
</style>
