<template>
  <div>
    <div class="px-8 pt-4">
      <div
        class="
          pb-4 flex flex-col"
      >
        <div
          class="flex flex-row w-full justify-center items-center md:justify-start space-x-3 pb-3 md:pb-1"
        >
          <svg-icon
            :name="route.meta.icon"
            class="w-5 text-light-red"
          ></svg-icon>
          <HeaderTitle :text="t(route.meta.title || route.name)" />
        </div>
        <div class="flex flex-row border-b border-light-gray md:w-1/3 pt-1" />
      </div>
      <SmallSubtitle
        v-if="route.meta.subtitle"
        :text="t(route.meta.subtitle)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import SmallSubtitle from '@/components/common/typography/small-subtitle.vue'
import { defineComponent } from 'vue'
import useTranslation from '@/composables/use-translation'
import HeaderTitle from '@/components/common/typography/header-title.vue'

export default defineComponent({
  components: {
    SmallSubtitle,
    HeaderTitle,
  },
  setup() {
    const route = useRoute()
    const t = useTranslation()

    return {
      t,
      route,
    }
  },
})
</script>
