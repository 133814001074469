
import { defineComponent, ref } from 'vue'
import * as Icons from '@/constants/icon-constants'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  emits: ['on-upload'],
  components: { Button },
  props: {
    placeholderText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const filesToUpload = ref<File[]>([])
    const isDraggingOver = ref(false)
    const onFilesAdded = event => {
      filesToUpload.value = filesToUpload.value.concat(
        Array.from(event.target.files)
      )
    }
    const removeUploadItem = (index: number) => {
      filesToUpload.value.splice(index, 1)
    }
    const onDragEnter = () => {
      isDraggingOver.value = true
    }
    const onDragLeave = () => {
      isDraggingOver.value = false
    }
    const onDrop = () => {
      isDraggingOver.value = false
    }
    const resetFileList = () => {
      filesToUpload.value = []
    }

    return {
      filesToUpload,
      onFilesAdded,
      removeUploadItem,
      onDragEnter,
      onDragLeave,
      onDrop,
      isDraggingOver,
      resetFileList,
      uploadIcon: Icons.UPLOAD,
      deleteIcon: Icons.TRASH,
    }
  },
})
