
import { defineComponent, PropType, watch } from 'vue'

export default defineComponent({
  emits: ['selected', 'closing'],
  props: {
    visibleData: {
      type: Object as PropType<{
        visible: boolean
        element: HTMLElement | false
      } | null>,
      default: null,
    },
    selectedDate: {
      type: Date,
      default: null,
    },
  },
  setup(props, { emit }) {
    const tomorrow = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
    const emitDate = (event: Date) => {
      event.setUTCHours(0, 0, 0, 0)
      emit('selected', event)
      emit('closing', event)
    }

    const closeModal = event => {
      const element = props.visibleData?.element
      if (event.target !== element) {
        emit('closing', event)
      }
    }

    watch(
      () => props.visibleData,
      value => {
        if (value?.visible) {
          setTimeout(() => {
            document.addEventListener('touchend', closeModal)
            document.addEventListener('click', closeModal)
          }, 300)
        } else {
          document.removeEventListener('touchend', closeModal)
          document.removeEventListener('click', closeModal)
        }
      }
    )
    return {
      emitDate,
      tomorrow,
    }
  },
})
