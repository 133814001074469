
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const row = ref<HTMLElement>()
    const sliderKnob = ref<HTMLElement>()
    const leftDistance = ref(0)

    const setKnobPosition = () => {
      if (row.value && sliderKnob.value) {
        const scaleFactor = props.value / 10
        leftDistance.value =
          row.value.getBoundingClientRect().width * scaleFactor -
          sliderKnob.value.getBoundingClientRect().width * scaleFactor
      }
    }

    onMounted(() => {
      window.addEventListener('resize', setKnobPosition)
      setTimeout(setKnobPosition, 500)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', setKnobPosition)
    })

    return {
      row,
      sliderKnob,
      leftDistance,
    }
  },
})
