
import { defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { QuestionAnswerModelInterface } from '@/models/buy-journey-steps/step-question/question-answer.model'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  emits: ['toggle-collapse'],
  props: {
    questions: {
      type: Array as PropType<Array<QuestionAnswerModelInterface>>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()

    return {
      angleUpIcon: Icons.ANGLE_UP_ICON,
      t,
      TC,
    }
  },
})
