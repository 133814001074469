
import useTranslation from '@/composables/use-translation'
import { defineComponent } from 'vue'
import * as TC from '@/constants/translation-constants.ts'

export default defineComponent({
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
