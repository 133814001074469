<template>
  <div class="flex flex-col items-center w-full">
    <img
      class="h-20 md:h-24"
      src="@/assets/logo_mobile.svg"
      alt="Herzberg Immobilien"
    />
  </div>
</template>

<script lang="ts">
import useTranslation from '@/composables/use-translation'
import { defineComponent } from 'vue'
import * as TC from '@/constants/translation-constants.ts'

export default defineComponent({
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
</script>
