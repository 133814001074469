
import { defineComponent, ref, watch, onMounted } from 'vue'
import ProgressBar from '@/components/common/progress-bar.vue'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  components: {
    ProgressBar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    percentFilled: {
      type: Number,
      default: 0,
    },
    isUnblocked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showDetails = ref(props.isUnblocked)
    const toggleShowDetails = () => {
      showDetails.value = !showDetails.value
    }

    watch(
      () => props.isUnblocked,
      () => {
        showDetails.value = true
      }
    )

    watch(
      () => props.percentFilled,
      value => {
        showDetails.value = value < 100
      }
    )

    onMounted(() => {
      showDetails.value = props.percentFilled < 100 && props.isUnblocked
    })

    return {
      showDetails,
      toggleShowDetails,
      angleDownIcon: Icons.ANGLE_DOWN_ICON,
      angleUpIcon: Icons.ANGLE_UP_ICON,
    }
  },
})
