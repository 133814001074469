<template>
  <slot v-if="!isLoading"></slot>

  <div v-else class="skeleton-loader flex-col w-full">
    <div class="flex" :class="`h-${itemHeight}`">
      <Skeletor class="w-full" height="100%" />
    </div>
    <div class="flex" :class="`h-${itemHeight}  mt-${itemDistance}`">
      <Skeletor class="w-full" height="100%" />
    </div>
    <div class="flex" :class="`h-${itemHeight} mt-${itemDistance}`">
      <Skeletor class="w-full" height="100%" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Skeletor } from 'vue-skeletor'

export default defineComponent({
  components: { Skeletor },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    itemHeight: {
      type: Number,
      default: 32,
    },
    itemDistance: {
      type: Number,
      default: 4,
    },
  },
})
</script>
