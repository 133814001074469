
import { defineComponent } from 'vue'
import Button from '@/components/common/form/button.vue'
import { useRouter } from 'vue-router'
import Image from '@/components/common/image.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import * as Routes from '@/constants/router-constants'

export default defineComponent({
  components: {
    Button,
    Image,
  },
  setup() {
    const t = useTranslation()
    const { push } = useRouter()
    function redirectToProposals() {
      push({ name: Routes.PROPOSALS.name })
    }

    return {
      redirectToProposals,
      t,
      TC,
    }
  },
})
