
import { computed, defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import FileList from '@/components/common/list/file-list.vue'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  emits: ['clicked'],
  components: { FileList, Button },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()
    const hasAcceptedBuyContractDraft = computed(
      () =>
        props.buyJourney.step_buy_contract_and_notary_appointment
          .buy_contract_draft.is_accepted_by_user === true
    )
    const hasDeclinedBuyContractDraft = computed(
      () =>
        props.buyJourney.step_buy_contract_and_notary_appointment
          .buy_contract_draft.is_accepted_by_user === false
    )
    const hasNotDecidedAboutBuyContractDraft = computed(
      () =>
        props.buyJourney.step_buy_contract_and_notary_appointment
          .buy_contract_draft.is_accepted_by_user === null
    )

    const emitClicked = (is_accepted_by_user: boolean) => {
      emit('clicked', { is_accepted_by_user })
    }

    return {
      hasAcceptedBuyContractDraft,
      hasDeclinedBuyContractDraft,
      hasNotDecidedAboutBuyContractDraft,
      emitClicked,
      t,
      TC,
    }
  },
})
