<template>
  <NavbarLoader :isLoading="isChatLoading" />
  <div
    class="
        fixed z-40 bottom-0 left-0 w-full h-14 flex items-center justify-center space-x-8 bg-primary
        md:flex md:relative md:flex-col md:h-auto md:space-x-0 md:text-primary md:w-full md:bg-opacity-0
      "
  >
    <router-link
      v-for="route in mainNavigationRoutes"
      :key="route.name"
      :to="{ path: route.path }"
      class="md:px-12 md:p-4 md:w-full md:flex md:items-center md:justify-start md:space-x-4"
    >
      <svg-icon
        :name="route.meta.icon"
        class="w-5 transition duration-300 ease text-gray md:text-primary"
      ></svg-icon>
      <span class="hidden md:flex md:w-full break-all">
        {{ t(route.meta.navigationTitle) }}
      </span>
    </router-link>
    <div class="cursor-pointer p-1" v-if="isMobile">
      <svg-icon
        @click="openChatbox"
        :name="CHAT_MENU_ICON"
        class="w-5 transition duration-300 ease text-gray md:text-white"
        :class="{
          'pointer-events-none': isChatLoading,
        }"
      ></svg-icon>
    </div>
  </div>
</template>

<script lang="ts">
import useRouter from '@/composables/use-router'
import useResize from '@/composables/use-resize'
import { computed, defineComponent } from 'vue'
import { CHAT_MENU_ICON } from '@/constants/icon-constants'
import useCrispButton from '@/composables/use-crisp'
import NavbarLoader from '@/components/common/loader/navbar-loader.vue'
import { LoadingModule } from '@/store/modules/loading.module'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  components: {
    NavbarLoader,
  },
  setup() {
    const { mainNavigationRoutes } = useRouter()
    const { isMobile } = useResize()
    const { openChat } = useCrispButton()
    const isChatLoading = computed(() => LoadingModule.isChatLoading)
    const t = useTranslation()

    function openChatbox() {
      LoadingModule.SET_IS_CHAT_LOADING(true)
      openChat({
        onChatOpened: () => LoadingModule.SET_IS_CHAT_LOADING(false),
      })
    }

    return {
      mainNavigationRoutes,
      isMobile,
      CHAT_MENU_ICON,
      openChatbox,
      isChatLoading,
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
// class router-link-exact-active is set by vue-router when route is active.

.router-link-exact-active {
  background-color: #ff7168;
}

.router-link-exact-active svg {
  color: white;
  opacity: 1;
}

.router-link-exact-active span {
  color: white;
}

@media only screen and (max-width: 768px) {
  .router-link-exact-active svg {
    opacity: 1;
    color: white;
  }
  .router-link-exact-active {
    background-color: #2f3a50;
    color: white;
  }
}
</style>
