import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { createI18n } from 'vue-i18n'
import messages from '@/i18n'
import { FontAwesomeIcon } from '@/plugins/font-awesome'
import { SvgIcon } from '@/plugins/svg-icon'
import {
  VerticalListSkeletonLoader,
  HorizontalListSkeletonLoader,
  ContentSkeletonLoader,
} from '@/plugins/skeleton-loader'
import VueTippy from 'vue-tippy'
import Toast, { PluginOptions } from 'vue-toastification'
import './assets/styles/tailwind.css'
import 'tippy.js/dist/tippy.css'
import { redirectHost } from './utils/dev/redirect-host'
import '@/utils/prototypes/number_prototype.ts'
import '@/utils/prototypes/date_prototypes.ts'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/swiper.scss'
import 'vue-skeletor/dist/vue-skeletor.css'
import 'vue-toastification/dist/index.css'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import smoothscroll from 'smoothscroll-polyfill'

if (process.env.NODE_ENV === 'development') {
  // Redirects to 192... from localhost for a better development experience
  redirectHost()
}

const i18n = createI18n({
  locale: 'de',
  messages,
})

smoothscroll.polyfill()

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueTippy, { directive: 'tippy' })
  .use(Toast, { timeout: 3500 } as PluginOptions)
  .component('fa', FontAwesomeIcon)
  .component('svg-icon', SvgIcon)
  .component('content-skeleton-loader', ContentSkeletonLoader)
  .component('vertical-list-skeleton-loader', VerticalListSkeletonLoader)
  .component('horizontal-list-skeleton-loader', HorizontalListSkeletonLoader)
  .component('vue-calendar', VueCal)
  .mount('#app')
