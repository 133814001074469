
import { defineComponent, ref } from 'vue'
import Button from '@/components/common/form/button.vue'
import { UserModule } from '@/store/modules/user.module'
import CheckBox from './checkbox.vue'
import { useToast } from 'vue-toastification'
import { YOU_HAVE_BEEN_SUBSCRIBED } from '@/constants/translation-constants'
import useTranslation from '@/composables/use-translation'

export default defineComponent({
  components: {
    Button,
    CheckBox,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    conditionTitle: {
      type: String,
      required: true,
    },
    conditionDescription: {
      type: String,
      required: false,
    },
  },
  setup() {
    const isChecked = ref(false)
    const t = useTranslation()
    const toast = useToast()

    function subscribeToNewsletter() {
      isChecked.value &&
        UserModule.updateSearchProfile({
          wants_newsletter: true,
        }).then(() => {
          toast.success(t(YOU_HAVE_BEEN_SUBSCRIBED))
        })
    }

    return {
      isChecked,
      subscribeToNewsletter,
    }
  },
})
