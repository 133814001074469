
import SignInForm from '@/components/authorization/sign-in-form.vue'
import HeaderWithLogoAuthScreen from '@/components/main-header/header-with-logo-auth-screen.vue'
import useTranslation from '@/composables/use-translation'
import { defineComponent } from 'vue'
import * as TC from '@/constants/translation-constants'

export default defineComponent({
  components: {
    SignInForm,
    HeaderWithLogoAuthScreen,
  },
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
