
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import * as Icons from '@/constants/icon-constants'
import BuyJourneyCheckbox from '@/components/common/buy-journey-checkbox.vue'
import {
  BaseStepModelInterface,
  BaseStepModelPayloadInterface,
} from '@/models/buy-journey-steps/base/base-step.model'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'

interface UpdateMethodInterface {
  buyJourneyId: string
  stepId: string
  payload: BaseStepModelPayloadInterface
}

type UpdateMethodType = ({
  buyJourneyId,
  stepId,
  payload,
}: UpdateMethodInterface) => void

export default defineComponent({
  components: {
    BuyJourneyCheckbox,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
    step: {
      type: Object as PropType<BaseStepModelInterface>,
      required: true,
    },
    isActiveStep: {
      type: Boolean,
      required: false,
    },
    updateMethod: {
      type: Function as PropType<UpdateMethodType>,
    },
  },
  setup(props) {
    const isExpanded = ref(false)

    const accordionElement = ref<HTMLElement | null>(null)

    const toggleShowDetails = () => {
      if (props.step.is_unblocked) {
        isExpanded.value = !isExpanded.value
      }
      if (props.updateMethod) {
        props.updateMethod({
          buyJourneyId: props.buyJourney.id,
          stepId: props.step.id,
          payload: {
            is_expanded: isExpanded.value,
          },
        })
      }
    }

    const smoothScrollToActive = value => {
      if (value === true) {
        setTimeout(() => {
          const modal = document.querySelector('.modal-scroll')
          const modalHeader = document.querySelector('.modal-header')
          if (modal && accordionElement.value) {
            const offset = modalHeader?.getBoundingClientRect().height ?? 0
            const elementPosition = accordionElement.value.offsetTop - offset
            modal.scrollTo({
              top: elementPosition,
              behavior: 'smooth',
            })
          }
        }, 300)
      }
    }

    onMounted(() => {
      smoothScrollToActive(props.isActiveStep)
      isExpanded.value =
        props.step.is_unblocked &&
        (props.step.is_expanded || props.isActiveStep)
    })

    watch(
      () => props.step.is_expanded,
      value => {
        isExpanded.value = value
      },
      {
        immediate: true,
      }
    )

    watch(
      () => props.step.is_completed,
      value => {
        if (!value) {
          return
        }
        isExpanded.value = false
        if (props.updateMethod) {
          props.updateMethod({
            buyJourneyId: props.buyJourney.id,
            stepId: props.step.id,
            payload: {
              is_expanded: isExpanded.value,
            },
          })
        }
      }
    )

    return {
      accordionElement,
      isExpanded,
      toggleShowDetails,
      plusIcon: Icons.PLUS_ICON,
      minusIcon: Icons.MINUS_ICON,
    }
  },
})
