<template>
  <div
    class="flex flex-col justify-center p-8 max-w-3xl mx-auto md:max-w-full md:bg-transparent"
  >
    <div class="space-y-4 text-primary text-2xs">
      <Multicheckbox
        :title="formData.investmentEffortTypes.label"
        :items="allInvestmentEffortTypes"
        :checkedItems="data.user_search_profile.investment_effort_types"
        :rules="formData.investmentEffortTypes.rules"
        groupName="investment_effort_types"
      />
      <Multicheckbox
        :title="formData.realEstateTypes.label"
        :items="allRealEstateTypes"
        :checkedItems="data.user_search_profile.real_estate_types"
        :rules="formData.realEstateTypes.rules"
        groupName="real_estate_types"
      />
      <Input
        :name="formData.investmentVolume.name"
        :label="formData.investmentVolume.label"
        :rules="formData.investmentVolume.rules"
        :value="data.user_search_profile.investment_volume"
      />
      <Input
        :name="formData.investmentRegion.name"
        :label="formData.investmentRegion.label"
        :rules="formData.investmentRegion.rules"
        :value="data.user_search_profile.region"
      />
      <ProfileTextareaInput
        :name="formData.investmentStrategy.name"
        :label="formData.investmentStrategy.label"
        :rules="formData.investmentStrategy.rules"
        :isDark="true"
        :value="data.user_search_profile.investment_strategy"
      />
      <ProfileTextareaInput
        :name="formData.investmentGoals.name"
        :label="formData.investmentGoals.label"
        :rules="formData.investmentGoals.rules"
        :isDark="true"
        :value="data.user_search_profile.investment_goal_reason"
      />
      <ProfileTextareaInput
        :name="formData.investmentRequirements.name"
        :label="formData.investmentRequirements.label"
        :rules="formData.investmentRequirements.rules"
        :isDark="true"
        :value="data.user_search_profile.investment_requirements"
      />
      <div
        class="flex flex-col justify-center items-center border border-primary border-collapse bg-white"
      >
        <h3 class="text-center text-2xs w-full py-2">
          {{ formData.newsletter.label }}
        </h3>
        <div class="w-full">
          <CheckBox
            :label="formData.newsletter.label"
            :checked="data.user_search_profile.wants_newsletter"
            :name="formData.newsletter.name"
            :rules="formData.newsletter.rules"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import useTranslation from '@/composables/use-translation.ts'
import { UserModule } from '@/store/modules/user.module'
import * as translationConstants from '@/constants/translation-constants.ts'
import Input from '@/components/common/form/input.vue'
import ProfileTextareaInput from '@/components/profile/common/profile-textarea-input.vue'
import Multicheckbox from '@/components/profile/common/investment-types-multicheckbox.vue'
import CheckBox from '@/components/common/form/checkbox.vue'
import { boolean, string } from 'yup'
import * as propertyConstants from '@/constants/property-constants.ts'
import { LoadingModule } from '@/store/modules/loading.module'

export default defineComponent({
  components: {
    Input,
    ProfileTextareaInput,
    Multicheckbox,
    CheckBox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)

    const allInvestmentEffortTypes = computed(
      () => UserModule.investmentEffortTypes
    )
    const allRealEstateTypes = computed(() => UserModule.realEstateTypes)
    const isLoading = computed(() => LoadingModule.isUserSearchProfileLoading)

    const formData = {
      investmentVolume: {
        name: propertyConstants.INVESTMENT_VOLUME,
        label: t(translationConstants.INVESTMENT_VOLUME),
        rules: string()
          .typeError(t(translationConstants.PLEASE_TYPE_A_NUMBER))
          .required(
            t(translationConstants.INVESTMENT_VOLUME_FORM_VALIDATION_ERROR)
          ),
      },
      investmentRegion: {
        name: propertyConstants.REGION,
        label: t(translationConstants.INVESTMENT_REGION),
        rules: string().required(
          t(translationConstants.INVESTMENT_REGION_FORM_VALIDATION_ERROR)
        ),
      },
      investmentStrategy: {
        name: propertyConstants.INVESTMENT_STRATEGY,
        label: t(translationConstants.INVESTMENT_STRATEGIES),
        rules: string().required(
          t(translationConstants.INVESTMENT_STRATEGY_FORM_VALIDATION_ERROR)
        ),
      },
      investmentGoals: {
        name: propertyConstants.INVESTMENT_GOAL_REASON,
        label: t(translationConstants.INVESTMENT_GOALS),
        rules: string().required(
          t(translationConstants.INVESTMENT_GOALS_FORM_VALIDATION_ERROR)
        ),
      },
      investmentEffortTypes: {
        name: propertyConstants.INVESTMENT_EFFORT_TYPES,
        label: t(translationConstants.INVESTMENT_EFFORT_TYPES),
        rules: boolean().required(
          t(translationConstants.INVESTMENT_EFFORT_TYPES_FORM_VALIDATION_ERROR)
        ),
      },
      realEstateTypes: {
        name: propertyConstants.REAL_ESTATE_TYPES,
        label: t(translationConstants.REAL_ESTATES_TYPES),
        rules: boolean().required(
          t(translationConstants.REAL_ESTATES_TYPES_FORM_VALIDATION_ERROR)
        ),
      },
      investmentRequirements: {
        name: propertyConstants.INVESTMENT_REQIUREMENTS,
        label: t(translationConstants.INVESTMENT_REQUIREMENTS),
        rules: string().required(
          t(translationConstants.INVESTMENT_REQUIREMENTS_FORM_VALIDATION_ERROR)
        ),
      },
      newsletter: {
        name: propertyConstants.NEWSLETTER,
        label: t(translationConstants.SUBSCRIPTION_NEWSLETTER),
        rules: boolean().required(
          t(translationConstants.NEWSLETTER_FORM_VALIDATION_ERROR)
        ),
      },
    }
    return {
      isProfileEditable,
      formData,
      allInvestmentEffortTypes,
      allRealEstateTypes,
      isLoading,
    }
  },
})
</script>
