<template>
  <div class="flex flex-col justify-center bg-dark-white my-4">
    <div class="flex flex-auto ml-4 cursor-pointer" @click="toggleShowDetails">
      <h3
        class="font-poppins text-sm flex flex-auto text-xs text-primary mt-4 outline-none"
        :class="{
          'opacity-50': !isUnblocked,
          '-rotate-180': showDetails,
        }"
      >
        {{ title }}
      </h3>
      <div
        class="text-sm bg-transparent mt-4 mr-4 transform rotate-0 transition-transform duration-300"
        :class="{
          'opacity-50': !isUnblocked,
          '-rotate-180': showDetails,
        }"
      >
        <fa class="w-4 h-4 text-primary" :icon="angleUpIcon" />
      </div>
    </div>
    <div
      @click="toggleShowDetails"
      class="mb-4 ml-4 mt-1 cursor-pointer"
      :class="{ 'opacity-50': !isUnblocked }"
    >
      <ProgressBar :percentFilled="percentFilled" />
    </div>
    <slot v-if="showDetails"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue'
import ProgressBar from '@/components/common/progress-bar.vue'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  components: {
    ProgressBar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    percentFilled: {
      type: Number,
      default: 0,
    },
    isUnblocked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showDetails = ref(props.isUnblocked)
    const toggleShowDetails = () => {
      showDetails.value = !showDetails.value
    }

    watch(
      () => props.isUnblocked,
      () => {
        showDetails.value = true
      }
    )

    watch(
      () => props.percentFilled,
      value => {
        showDetails.value = value < 100
      }
    )

    onMounted(() => {
      showDetails.value = props.percentFilled < 100 && props.isUnblocked
    })

    return {
      showDetails,
      toggleShowDetails,
      angleDownIcon: Icons.ANGLE_DOWN_ICON,
      angleUpIcon: Icons.ANGLE_UP_ICON,
    }
  },
})
</script>
