<template>
  <swiper
    :pagination="{ clickable: true, dynamicBullets: true }"
    :autoplay="{
      delay: 3000,
      disableOnInteraction: true,
    }"
    :loop="true"
  >
    <swiper-slide v-for="image in sortedImages" :key="image.id">
      <div
        class="h-48 bg-center bg-cover relative overflow-hidden h-"
        :style="{
          backgroundImage: `url(${image.image_file_path})`,
        }"
      ></div>
    </swiper-slide>
  </swiper>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { sortBy } from 'lodash'
import { RealEstateImageModelInterface } from '@/models/real-estate-image.model'

SwiperCore.use([Pagination, Autoplay])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array as PropType<Array<RealEstateImageModelInterface>>,
      required: true,
    },
  },
  setup(props) {
    const sortedImages = computed(() =>
      sortBy(props.images, [image => (image.is_main_preview_image ? 0 : 1)])
    )

    return {
      sortedImages,
    }
  },
})
</script>
