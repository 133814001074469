<template>
  <button
    @click="$emit('click')"
    :disabled="disabled"
    class="text-white px-4 py-2 text-xs outline-none focus:outline-none w-full disabled:cursor-not-allowed disabled:opacity-50"
    :class="backgroundClass"
  >
    <slot :buttonDisabled="disabled" />
  </button>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  emits: ['click'],
  props: {
    type: {
      type: String as PropType<'primary' | 'secondary' | 'alert'>,
      default: 'primary',
    },
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const backgroundClass = computed(() => {
      switch (props.type) {
        case 'primary':
          return 'bg-primary'
        case 'alert':
          return 'bg-alert'
        case 'secondary':
          return 'bg-secondary'
        default:
          return 'bg-primary'
      }
    })

    return {
      backgroundClass,
    }
  },
})
</script>
