export const APPLICATION_TITLE = 'APPLICATION_TITLE'
export const APPLICATION_SUBTITLE = 'APPLICATION_SUBTITLE'
export const DASHBOARD_TITLE = 'DASHBOARD_TITLE'
export const DASHBOARD_TITLE_NAVIGATION = 'DASHBOARD_TITLE_NAVIGATION'
export const DASHBOARD_SUBTITLE = 'DASHBOARD_SUBTITLE'
export const PROPOSAL_TITLE = 'PROPOSAL_TITLE'
export const PROPOSAL_TITLE_NAVIGATION = 'PROPOSAL_TITLE_NAVIGATION'
export const PROFILE_TITLE = 'PROFILE_TITLE'
export const PROFILE_TITLE_NAVIGATION = 'PROFILE_TITLE_NAVIGATION'
export const PROFILE_SUBTITLE = 'PROFILE_SUBTITLE'
export const PROPOSAL_SUBTITLE = 'PROPOSAL_SUBTITLE'
export const YOUR_PERSONAL_ADVISOR = 'YOUR_PERSONAL_ADVISOR'
export const WE_WILL_ASSIGN_AN_ADIVSOR = 'WE_WILL_ASSIGN_AN_ADIVSOR'
export const IMPRINT = 'IMPRINT'
export const PRIVACY_POLICY = 'PRIVACY_POLICY'
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS'
export const NO_PROPOSAL_FOUND = 'NO_PROPOSAL_FOUND'
export const NO_BUY_JOURNEY_FOUND = 'NO_BUY_JOURNEY_FOUND'
export const MORE_INFORMATION = 'MORE_INFORMATION'
export const INVESTMENT_STRATEGIES = 'INVESTMENT_STRATEGIES'
export const INVESTMENT_REGION = 'INVESTMENT_REGION'
export const INVESTMENT_GOALS = 'INVESTMENT_GOALS'
export const INVESTMENT_TITLE_NAVIGATION = 'INVESTMENT_TITLE_NAVIGATION'
export const INVESTMENT_REQUIREMENTS = 'INVESTMENT_REQUIREMENTS'
export const INVESTMENT_VOLUME = 'INVESTMENT_VOLUME'
export const SUBSCRIPTION_NEWSLETTER = 'SUBSCRIPTION_NEWSLETTER'
export const REAL_ESTATES_COUNT = 'REAL_ESTATES_COUNT'
export const ADDRESS = 'ADDRESS'
export const FIRST_NAME = 'FIRST_NAME'
export const LAST_NAME = 'LAST_NAME'
export const PHONE = 'PHONE'
export const EMAIL = 'EMAIL'
export const PASSWORD = 'PASSWORD'
export const STREET = 'STREET'
export const NUMBER = 'NUMBER'
export const COUNTRY = 'COUNTRY'
export const CITY = 'CITY'
export const ZIP_CODE = 'ZIP_CODE'
export const DESCRIPTION = 'DESCRIPTION'
export const AVATAR = 'AVATAR'
export const BIRTHDAY = 'BIRTHDAY'
export const JOB = 'JOB'
export const SALARY = 'SALARY'
export const EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS'
export const EQUITY = 'EQUITY'
export const FINANCIAL_VALUES = 'FINANCIAL_VALUES'
export const OBJECT_TYPES = 'OBJECT_TYPES'
export const TITLE_CONTACT_INFORMATION = 'TITLE_CONTACT_INFORMATION'
export const TITLE_PERSONAL_INFORMATION = 'TITLE_PERSONAL_INFORMATION'
export const TITLE_FINANCIAL_INFORMATION = 'TITLE_FINANCIAL_INFORMATION'
export const TAB_TITLE_PROFILE = 'TAB_TITLE_PROFILE_PERSONAL'
export const TAB_TITLE_SEARCH_PROFILE = 'TAB_TITLE_SEARCH_PROFILE'
export const REQUEST_EXPOSE = 'REQUEST_EXPOSE'
export const COMPLETELY_CAREFREE = 'COMPLETELY_CAREFREE'
export const NO_INVESTMENTS_FOUND = 'NO_INVESTMENTS_FOUND'
export const SHOW_MATCHING_ESTATES = 'SHOW_MATCHING_ESTATES'
export const INVESTMENT_TITLE = 'INVESTMENT_TITLE'
export const INVESTMENT_SUBTITLE = 'INVESTMENT_SUBTITLE'
export const MATCHING_PROPOSALS = 'MATCHING_PROPOSALS'
export const NO_MATCHING_ESTATES_FOUND = 'NO_MATCHING_ESTATES_FOUND'
export const ACCEPT = 'ACCEPT'
export const ACTIVATE_NOTIFICATION = 'ACTIVATE_NOTIFICATION'
export const NOTIFICATION_DESCRIPTION = 'NOTIFICATION_DESCRIPTION'
export const LETTER_OF_INTEREST = 'LETTER_OF_INTEREST'
export const CONSULATION_PROTOCOL_AND_DISCLAIMER =
  'CONSULATION_PROTOCOL_AND_DISCLAIMER'
export const CANCELLATION_POLICY = 'CANCELLATION_POLICY'
export const CONFIRM_CONDITIONS = 'CONFIRM_CONDITIONS'
export const CONTINUE = 'CONTINUE'
export const START_BUY_JOURNEY = 'START_BUY_JOURNEY'
export const BUY_JOURNEY_CREATED = 'BUY_JOURNEY_CREATED'
export const AN_ERROR_OCCURED = 'AN_ERROR_OCCURED'
export const NO_ACCOUNT_YET = 'NO_ACCOUNT_YET'
export const WRONG_EMAIL_OR_PASSWORD = 'WRONG_USERNAME_OR_PASSWORD'
export const EMAIL_IS_REQUIRED = 'EMAIL_IS_REQUIRED'
export const PASSWORD_IS_REQUIRED = 'PASSWORD_IS_REQUIRED'
export const EMAIL_MUST_BE_VALID = 'EMAIL_MUST_BE_VALID'
export const LOGIN = 'LOGIN'
export const SIGN_IN = 'SIGN_IN'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const REGISTER_HERE = 'REGISTER_HERE'
export const ARE_YOU_SURE_TO_LOGOUT = 'ARE_YOU_SURE_TO_LOGOUT'
export const OBJECT_PRESENTATION = 'OBJECT_PRESENTATION'
export const OBJECT_CHECK = 'OBJECT_CHECK'
export const INVESTMENT = 'INVESTMENT'
export const EXPOSE = 'EXPOSE'
export const INVESTMENT_CALCULATION = 'INVESTMENT_CALCULATION'
export const QUESTIONS = 'QUESTIONS'
export const VISITATION = 'VISITATION'
export const FINANCING = 'FINANCING'
export const ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS'
export const BUY_CONTRACT_DRAFT_AND_NOTARY_APPOINTMENT =
  'BUY_CONTRACT_DRAFT_AND_NOTARY_APPOINTMENT'
export const LOAN_CONTRACTS = 'LOAN_CONTRACTS'
export const COULD_NOT_RESET_PASSWORD = 'COULD_NOT_RESET_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FORGOT_PASSWORD_DESCRIPTION = 'FORGOT_PASSWORD_DESCRIPTION'
export const GO_TO_LOGIN = 'GO_TO_LOGIN'
export const GO_BACK = 'GO_BACK'
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED'
export const SAVE = 'SAVE'
export const PROFILE_GREETING = 'PROFILE_GREETING'
export const PROFILE_GREETING_END = 'PROFILE_GREETING_END'
export const NO_DOCUMENTS_AVAILABLE = 'NO_DOCUMENTS_AVAILABLE'
export const DOCUMENTS = 'DOCUMENTS'
export const CONFIRM = 'CONFIRM'
export const I_HAVE_DOWNLOADED_THE_EXPOSE_DOCUMENTS =
  'I_HAVE_DOWNLOADED_THE_EXPOSE_DOCUMENTS'
export const CALCULATION_PRESETS = 'CALCULATION_PRESETS'
export const UPLOAD_YOUR_CALCULATIONS = 'UPLOAD_YOUR_CALCULATIONS'
export const WANT_TO_CHECK_YOUR_CALCULATIONS = 'WANT_TO_CHECK_YOUR_CALCULATIONS'
export const YOUR_FILES_HAVE_BEEN_UPLOADED = 'YOUR_FILES_HAVE_BEEN_UPLOADED'
export const YOU_CAN_ASK_QUESTIONS_HERE = 'YOU_CAN_ASK_QUESTIONS_HERE'
export const INPUT_YOUR_QUESTION = 'INPUT_YOUR_QUESTION'
export const SUBMIT_QUESTION = 'SUBMIT_QUESTION'
export const YOUR_QUESTION_HAS_BEEN_SUBMITTED =
  'YOUR_QUESTION_HAS_BEEN_SUBMITTED'
export const OPEN_QUESTIONS = 'OPEN_QUESTIONS'
export const ANSWERED_QUESTIONS = 'ANSWERED_QUESTIONS'
export const ANSWER_OF_YOUR_ADVISOR = 'ANSWER_OF_YOUR_ADVISOR'
export const CLICK_YOU_QUESTION_TO_SHOW_ANSWER =
  'CLICK_YOU_QUESTION_TO_SHOW_ANSWER'
export const PLEASE_CHOOSE_A_DATE = 'PLEASE_CHOOSE_A_DATE'
export const PLEASE_TYPE_A_NUMBER = 'PLEASE_TYPE_A_NUMBER'
export const HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_7 =
  'HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_7'
export const HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_20 =
  'HOUR_MUST_BE_EQUAL_OR_GREATER_THAN_20'
export const YOUR_APPOINTMENT_HAS_BEEN_SUBMITTED =
  'YOUR_APPOINTMENT_HAS_BEEN_SUBMITTED'
export const PLEASE_PROPOSE_AT_LEAST_ONE_APPOINTMENT =
  'PLEASE_PROPOSE_AT_LEAST_ONE_APPOINTMENT'
export const SUBMIT = 'SUBMIT'
export const YOUR_APPOINTMENT_IS_AT = 'YOUR_APPOINTMENT_IS_AT'
export const THE_APPOINTMENT_LOCATION_IS = 'THE_APPOINTMENT_LOCATION_IS'
export const NO_LOCATION_PROVIDED_YET = 'NO_LOCATION_PROVIDED_YET'
export const YOUR_VISITATION_APPOINTMENT_PROPOSALS =
  'YOUR_VISITATION_APPOINTMENT_PROPOSALS'
export const WE_INFORM_YOU_ABOUT_A_FINAL_APPOINTMENT =
  'WE_INFORM_YOU_ABOUT_A_FINAL_APPOINTMENT'
export const O_CLOCK = 'O_CLOCK'
export const FROM = 'FROM'
export const TO = 'TO'
export const DATE = 'DATE'
export const CLICK_TO_DISPLAY_CALENDAR = 'CLICK_TO_DISPLAY_CALENDAR'
export const HOUR = 'HOUR'
export const REAL_ESTATE_IS_FINANCED_BY_OUR_PARTNER =
  'REAL_ESTATE_IS_FINANCED_BY_OUR_PARTNER'
export const HOW_DO_YOU_WANT_TO_FINANCE_THE_REAL_ESTATE =
  'HOW_DO_YOU_WANT_TO_FINANCE_THE_REAL_ESTATE'
export const I_TAKE_CARE_OF_THE_FINANCING = 'I_TAKE_CARE_OF_THE_FINANCING'
export const I_NEED_HELP_FOR_THE_FINANCING = 'I_NEED_HELP_FOR_THE_FINANCING'
export const OUR_FINANCING_OFFER = 'OUR_FINANCING_OFFER'
export const DOWNLOAD_AND_AGREE_DOCUMENTS = 'DOWNLOAD_AND_AGREE_DOCUMENTS'
export const DO_YOU_AGREE_WITH_THE_OFFER = 'DO_YOU_AGREE_WITH_THE_OFFER'
export const WE_ARE_PREPARING_THE_FINANCING_OFFER =
  'WE_ARE_PREPARING_THE_FINANCING_OFFER'
export const THANK_YOU_FOR_ACCEPTING_THE_OFFER =
  'THANK_YOU_FOR_ACCEPTING_THE_OFFER'
export const THANK_YOU_FOR_DENYING_THE_OFFER = 'THANK_YOU_FOR_DENYING_THE_OFFER'
export const UPLOAD_YOUR_FINANCING_DOCUMENTS_HERE =
  'UPLOAD_YOUR_FINANCING_DOCUMENTS_HERE'
export const I_HAVE_TAKEN_CARE_OF_FINANCING = 'I_HAVE_TAKEN_CARE_OF_FINANCING'
export const I_HAVE_RECEIVED_A_MATCHING_OFFER =
  'I_HAVE_RECEIVED_A_MATCHING_OFFER'
export const TO_FINISH_CONFIRM_THE_CONDITIONS =
  'TO_FINISH_CONFIRM_THE_CONDITIONS'
export const IF_YOU_WANNA_CHECK_YOUR_OFFERS_UPLOAD_THEM_HERE =
  'IF_YOU_WANNA_CHECK_YOUR_OFFERS_UPLOAD_THEM_HERE'
export const THANK_YOU_FOR_TAKING_CARE_OF_INVESTMENT =
  'THANK_YOU_FOR_TAKING_CARE_OF_INVESTMENT'
export const SADLY_AN_ERROR_OCCURED = 'SADLY_AN_ERROR_OCCURED'
export const THE_REQUIRED_RESOURCE_WAS_NOT_FOUND =
  'THE_REQUIRED_RESOURCE_WAS_NOT_FOUND'
export const BACK = 'BACK'
export const SHOW_MORE = 'SHOW_MORE'
export const SHOW_LESS = 'SHOW_LESS'
export const YES = 'YES'
export const NO = 'NO'
export const YOU_CAN_DOWNLOAD_ADDITIONAL_DOCUMENTS_HERE =
  'YOU_CAN_DOWNLOAD_ADDITIONAL_DOCUMENTS_HERE'
export const DOWNLOAD_THE_SELF_DISCLOSURE = 'DOWNLOAD_THE_SELF_DISCLOSURE'
export const UPLOAD_ALL_DOCUMENT_AND_SELF_DISCLOSURE =
  'UPLOAD_ALL_DOCUMENT_AND_SELF_DISCLOSURE'
export const THANK_YOU_FOR_THE_DOCUMENTS_YOU_WILL_BE_INFORMED =
  'THANK_YOU_FOR_THE_DOCUMENTS_YOU_WILL_BE_INFORMED'
export const WE_ARE_LOOKING_FOR_AN_FINANCING_OFFER =
  'WE_ARE_LOOKING_FOR_AN_FINANCING_OFFER'
export const YOU_CAN_ADD_ADDITIONAL_DOCUMENTS =
  'YOU_CAN_ADD_ADDITIONAL_DOCUMENTS'
export const YOUR_UPLOADED_DOCUMENTS = 'YOUR_UPLOADED_DOCUMENTS'
export const SEND_ALL_DOCUMENTS_BY_EMAIL = 'SEND_ALL_DOCUMENTS_BY_EMAIL'
export const SEND_ALL_DOCUMENTS_BY_POST = 'SEND_ALL_DOCUMENTS_BY_POST'
export const OR = 'OR'
export const STATUS = 'STATUS'
export const CHECKING = 'CHECKING'
export const DENIED = 'DENIED'
export const VALIDATED = 'VALIDATED'
export const DOWNLOAD_FINANCING_OFFER = 'DOWNLOAD_FINANCING_OFFER'
export const WE_PREPARE_THE_FINANCING_OFFER = 'WE_PREPARE_THE_FINANCING_OFFER'
export const WE_PREPARE_THE_SELF_DISCLOSURE = 'WE_PREPARE_THE_SELF_DISCLOSURE'
export const YOU_AGREE_TO_THE_NOTARY_FEE = 'YOU_AGREE_TO_THE_NOTARY_FEE'
export const I_AM_AWARE_AND_AGREE = 'I_AM_AWARE_AND_AGREE'
export const SOON_YOU_WILL_SEE_THE_BUY_CONTRACT_AND_NOTARY_APPOINTMENT =
  'SOON_YOU_WILL_SEE_THE_BUY_CONTRACT_AND_NOTARY_APPOINTMENT'
export const DOWNLOAD_YOUR_BUY_CONTRACT = 'DOWNLOAD_YOUR_BUY_CONTRACT'
export const NO_BUY_CONTRACT_AVAILABLE_YET = 'NO_BUY_CONTRACT_AVAILABLE_YET'
export const THANKS_FOR_CHOOSING_THE_BUY_CONTRACT_DRAFT =
  'THANKS_FOR_CHOOSING_THE_BUY_CONTRACT_DRAFT'
export const WE_ARE_LOOKING_FOR_ANOTHER_BUY_CONTRACT_DRAFT =
  'WE_ARE_LOOKING_FOR_ANOTHER_BUY_CONTRACT_DRAFT'
export const ACCEPT_BUY_CONTRACT_DRAFT = 'ACCEPT_BUY_CONTRACT_DRAFT'
export const DENY_BUY_CONTRACT_DRAFT = 'DENY_BUY_CONTRACT_DRAFT'
export const YOUR_NOTARY_APPOINTMENT_IS_AT_TIME =
  'YOUR_NOTARY_APPOINTMENT_IS_AT_TIME'
export const YOUR_NOTARY_APPOINTMENT_IS_AT_PLACE =
  'YOUR_NOTARY_APPOINTMENT_IS_AT_PLACE'
export const NO_NOTARY_APPOINTMENT_AVAILABLE_YET =
  'NO_NOTARY_APPOINTMENT_AVAILABLE_YET'
export const WE_ARE_SORRY_THAT_YOU_ARE_NOT_SATISIFIED =
  'WE_ARE_SORRY_THAT_YOU_ARE_NOT_SATISIFIED'
export const ACCEPT_NOTARY_APPOINTMENT = 'ACCEPT_NOTARY_APPOINTMENT'
export const I_WANT_TO_CHANGE_THE_NOTARY_APPOINTMENT =
  'I_WANT_TO_CHANGE_THE_NOTARY_APPOINTMENT'
export const WE_NEED_YOUR_AGREEMENT_FOR_THE_LOAN_CONTRACT =
  'WE_NEED_YOUR_AGREEMENT_FOR_THE_LOAN_CONTRACT'
export const I_RECEIVED_THE_LOAN_CONTRACT_AND_SIGNED_IT =
  'I_RECEIVED_THE_LOAN_CONTRACT_AND_SIGNED_IT'
export const YOU_CAN_UPLOAD_YOUR_LOAN_CONTRACTS =
  'YOU_CAN_UPLOAD_YOUR_LOAN_CONTRACTS'
export const UPLOAD_YOUR_LOAN_CONTRACT = 'UPLOAD_YOUR_LOAN_CONTRACT'
export const DOWNLOAD_YOUR_LOAN_CONTRACT = 'DOWNLOAD_YOUR_LOAN_CONTRACT'
export const WE_PREPARE_YOUR_LOAN_CONTRACT = 'WE_PREPARE_YOUR_LOAN_CONTRACT'
export const THANK_YOU_FOR_CONFIRMING_YOUR_LOAN_CONTRACT =
  'THANK_YOU_FOR_CONFIRMING_YOUR_LOAN_CONTRACT'
export const YOU_DID_NOT_YET_PARTICIPATE_THE_NOTARY_APPOINTMENT =
  'YOU_DID_NOT_YET_PARTICIPATE_THE_NOTARY_APPOINTMENT'
export const THANK_YOU_FOR_PARTICIPATING_THE_NOTARY_APPOINTMENT =
  'THANK_YOU_FOR_PARTICIPATING_THE_NOTARY_APPOINTMENT'
export const OWNERSHIP_TRANSFER_IS_IN_RPOGRESS =
  'OWNERSHIP_TRANSFER_IS_IN_RPOGRESS'
export const THE_BUY_JOURNEY_IS_COMPLETED = 'THE_BUY_JOURNEY_IS_COMPLETED'
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const FIRST_NAME_FORM_VALIDATION_ERROR =
  'FIRST_NAME_FORM_VALIDATION_ERROR'
export const LAST_NAME_FORM_VALIDATION_ERROR = 'LAST_NAME_FORM_VALIDATION_ERROR'
export const PHONE_REQUIRED_FORM_VALIDATION_ERROR =
  'PHONE_FORM_REQUIRED_VALIDATION_ERROR'
export const PHONE_FORMAT_FORM_VALIDATION_ERROR =
  'PHONE_FORMAT_VALIDATION_ERROR'
export const EMAIL_FORM_VALIDATION_ERROR = 'EMAIL_FORM_VALIDATION_ERROR'
export const COUNTRY_FORM_VALIDATION_ERROR = 'COUNTRY_FORM_VALIDATION_ERROR'
export const CITY_FORM_VALIDATION_ERROR = 'CITY_FORM_VALIDATION_ERROR'
export const STREET_FORM_VALIDATION_ERROR = 'STREET_FORM_VALIDATION_ERROR'
export const NUMBER_FORM_VALIDATION_ERROR = 'NUMBER_FORM_VALIDATION_ERROR'
export const ZIP_CODE_FORM_VALIDATION_ERROR = 'ZIP_CODE_FORM_VALIDATION_ERROR'
export const JOB_FORM_VALIDATION_ERROR = 'JOB_FORM_VALIDATION_ERROR'
export const SALARY_FORM_VALIDATION_ERROR = 'SALARY_FORM_VALIDATION_ERROR'
export const EQUITY_FORM_VALIDATION_ERROR = 'EQUITY_FORM_VALIDATION_ERROR'
export const REAL_ESTATES_COUNT_FORM_VALIDATION_ERROR =
  'REAL_ESTATES_COUNT_FORM_VALIDATION_ERROR'
export const INVESTMENT_VOLUME_FORM_VALIDATION_ERROR =
  'INVESTMENT_REGION_FORM_VALIDATION_ERROR'
export const INVESTMENT_REGION_FORM_VALIDATION_ERROR =
  'INVESTMENT_REGION_FORM_VALIDATION_ERROR'
export const INVESTMENT_STRATEGY_FORM_VALIDATION_ERROR =
  'INVESTMENT_STRATEGY_FORM_VALIDATION_ERROR'
export const INVESTMENT_GOALS_FORM_VALIDATION_ERROR =
  'INVESTMENT_GOALS_FORM_VALIDATION_ERROR'
export const INVESTMENT_OBJECT_TYPES_FORM_VALIDATION_ERROR =
  'INVESTMENT_OBJECT_TYPES_FORM_VALIDATION_ERROR'
export const INVESTMENT_REQUIREMENTS_FORM_VALIDATION_ERROR =
  'INVESTMENT_REQUIREMENTS_FORM_VALIDATION_ERROR'
export const FINANCIAL_VALUES_FORM_VALIDATION_ERROR =
  'FINANCIAL_VALUES_FORM_VALIDATION_ERROR'
export const REAL_ESTATES_TYPES = 'REAL_ESTATES_TYPES'
export const REAL_ESTATES_TYPES_FORM_VALIDATION_ERROR =
  'REAL_ESTATES_TYPES_FORM_VALIDATION_ERROR'
export const INVESTMENT_EFFORT_TYPES = 'INVESTMENT_EFFORT_TYPES'
export const INVESTMENT_EFFORT_TYPES_FORM_VALIDATION_ERROR =
  'INVESTMENT_EFFORT_TYPES_FORM_VALIDATION_ERROR'
export const PROFILE_SAVE_ERROR = 'PROFILE_SAVE_ERROR'
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS'
export const BIRTHDAY_FORM_VALIDATION_ERROR = 'BIRTHDAY_FORM_VALIDATION_ERROR'
export const BIRTHDAY_FORMAT_INCORRECT = 'BIRTHDAY_FORMAT_INCORRECT'
export const NEWSLETTER_FORM_VALIDATION_ERROR =
  'NEWSLETTER_FORM_VALIDATION_ERROR'
export const EMPLOYMENT_STATUS_IS_REQUIRED = 'EMPLOYMENT_STATUS_IS_REQUIRED'
export const YOU_ARE_ALREADY_SUBSCRIBED = 'YOU_ARE_ALREADY_SUBSCRIBED'
export const YOU_HAVE_BEEN_SUBSCRIBED = 'YOU_HAVE_BEEN_SUBSCRIBED'
export const DECLINE = 'DECLINE'
export const TRANSFER_OF_OWNERSHIP = 'TRANSFER_OF_OWNERSHIP'
export const AVATAR_SUCCESSFULLY_UPDATED = 'AVATAR_SUCCESSFULLY_UPDATED'
export const FINISH_STEP = 'FINISH_STEP'
export const REOPEN_STEP = 'REOPEN_STEP'
