<template>
  <div>
    <div class="flex flex-row">
      <button
        style="outline: none"
        class="py-4 px-2 bg-primary text-white text-xs w-full"
        v-for="(tab, index) in tabs"
        :key="index"
        :style="{ width: `${100 / tabs.length}%` }"
        @click.prevent="$emit('tab-change', () => onSelectTab(index))"
        :ref="
          el => {
            if (el) tabRefs[index] = el
          }
        "
      >
        <span :class="{ 'text-red-300': isTabInvalid(tab) }">
          {{ tab.text }}
        </span>
      </button>
    </div>
    <div
      class="border-b-4 border-white transition-all duration-500 ease-in-out -m-1"
      :style="{
        width: `${line.width}px`,
        transform: `translate3d(${line.offset}px, 0, 0)`,
      }"
    ></div>
    <component
      v-show="index === selectedTabIndex"
      v-for="(tab, index) in tabs"
      :key="index"
      :is="tab.contentComponent"
      :data="tab.props.data"
    />
  </div>
</template>

<script lang="ts">
import useResize from '@/composables/use-resize'
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  PropType,
  Component,
} from 'vue'
import { includes } from 'lodash'
import { UserModelInterface } from '@/models/user.model'

export interface TabInterface {
  text: string
  contentComponent: Component
  props: Record<string, object | UserModelInterface | null>
}

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Array<TabInterface>>,
      required: true,
    },
    defaultTabIndex: {
      type: Number,
      default: 0,
    },
    invalidTabs: {
      type: Array as PropType<Array<string>>,
      default: [],
    },
  },
  emits: ['tab-change'],
  setup(props) {
    const tabRefs = reactive<HTMLButtonElement[]>([])
    const line = reactive({ offset: 0, width: 0 })
    const selectedTabIndex = ref(props.defaultTabIndex)
    const { onResize } = useResize()

    const setTab = tabIndex => {
      line.width = tabRefs[tabIndex].clientWidth
      line.offset = tabRefs[tabIndex].offsetLeft + 4
      selectedTabIndex.value = tabIndex
    }

    const onSelectTab = tabIndex => {
      setTab(tabIndex)
    }

    const isTabInvalid = (tab: TabInterface) => {
      return includes(props.invalidTabs, tab.text)
    }

    onMounted(() => {
      setTab(props.defaultTabIndex)
    })

    onResize(() => {
      setTab(selectedTabIndex.value)
    })

    return {
      line,
      onSelectTab,
      tabRefs,
      selectedTabIndex,
      isTabInvalid,
    }
  },
})
</script>
