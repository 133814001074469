
import { defineComponent } from 'vue'
import { PROFILE } from '@/constants/router-constants'
import useTranslation from '@/composables/use-translation'
import { ARE_YOU_SURE_TO_LOGOUT } from '@/constants/translation-constants'
import { UserModule } from '@/store/modules/user.module'
import { SIGN_IN } from '@/constants/router-constants'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'
import { AVATAR_SUCCESSFULLY_UPDATED } from '@/constants/translation-constants'

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const { replace } = useRouter()
    const toast = useToast()
    const { getMessage } = useError()
    const uploadUserAvatar = event => {
      if (event.target.files.length) {
        const file: File = event.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        UserModule.updateAvatar(formData)
          .then(() => {
            toast.success(t(AVATAR_SUCCESSFULLY_UPDATED))
          })
          .catch(error => {
            toast.error(getMessage(error))
          })
      }
    }
    const logout = () => {
      const result = confirm(t(ARE_YOU_SURE_TO_LOGOUT))
      if (result === true) {
        UserModule.logout().finally(() => {
          replace({ name: SIGN_IN.name }).then(async () => {
            UserModule.SET_USER(null)
          })
        })
      }
    }

    return {
      PROFILE,
      logout,
      uploadUserAvatar,
    }
  },
})
