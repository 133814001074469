<template>
  <div>
    <p class="text-sm">{{ t(TC.YOUR_APPOINTMENT_IS_AT) }}:</p>
    <p class="text-sm font-bold">
      {{ new Date(step.final_appointment).toGermanDate() }}
      {{ t(TC.O_CLOCK) }}
    </p>
    <p class="text-sm mt-2">{{ t(TC.THE_APPOINTMENT_LOCATION_IS) }}:</p>
    <div v-if="step.actual_address" class="text-sm font-bold">
      <p>{{ step.actual_address.country }}</p>
      <p>{{ step.actual_address.postcode }} {{ step.actual_address.city }}</p>
      <p>
        {{ step.actual_address.street }} {{ step.actual_address.housenumber }}
      </p>
    </div>
    <div v-else>
      <p class="text-xs font-bold">{{ t(TC.NO_LOCATION_PROVIDED_YET) }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyStepVisitationModelInterface } from '@/models/buy-journey-steps/step-visitation/buy-journey-step-visitation.model'

export default defineComponent({
  props: {
    step: {
      type: Object as PropType<BuyJourneyStepVisitationModelInterface>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
})
</script>
