<template>
  <div
    v-if="
      buyJourney.step_buy_contract_and_notary_appointment.buy_contract_draft
    "
  >
    <transition name="fade" mode="out-in">
      <div
        v-if="hasDeclinedBuyContractDraft"
        class="flex flex-col items-center"
      >
        <p class="text-xs font-bold">
          {{ t(TC.WE_ARE_LOOKING_FOR_ANOTHER_BUY_CONTRACT_DRAFT) }}
        </p>
        <hr class="mt-4 w-1/2" />
      </div>
      <div v-else>
        <p class="text-xs">1. {{ t(TC.DOWNLOAD_YOUR_BUY_CONTRACT) }}</p>
        <FileList
          :documents="
            buyJourney.step_buy_contract_and_notary_appointment
              .buy_contract_draft.documents
          "
        />
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div
        v-if="hasAcceptedBuyContractDraft"
        class="flex flex-col items-center mt-2"
      >
        <p class="text-xs">
          {{ t(TC.THANKS_FOR_CHOOSING_THE_BUY_CONTRACT_DRAFT) }}
        </p>
        <hr class="mt-4 w-1/2" />
      </div>
      <div
        v-else-if="hasNotDecidedAboutBuyContractDraft"
        class="flex space-x-4 mt-2"
      >
        <Button @click="emitClicked(true)">{{
          t(TC.ACCEPT_BUY_CONTRACT_DRAFT)
        }}</Button>
        <Button @click="emitClicked(false)">{{
          t(TC.DENY_BUY_CONTRACT_DRAFT)
        }}</Button>
      </div>
    </transition>
  </div>
  <p v-else class="text-xs font-bold">
    {{ t(TC.NO_BUY_CONTRACT_AVAILABLE_YET) }}
  </p>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import FileList from '@/components/common/list/file-list.vue'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  emits: ['clicked'],
  components: { FileList, Button },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()
    const hasAcceptedBuyContractDraft = computed(
      () =>
        props.buyJourney.step_buy_contract_and_notary_appointment
          .buy_contract_draft.is_accepted_by_user === true
    )
    const hasDeclinedBuyContractDraft = computed(
      () =>
        props.buyJourney.step_buy_contract_and_notary_appointment
          .buy_contract_draft.is_accepted_by_user === false
    )
    const hasNotDecidedAboutBuyContractDraft = computed(
      () =>
        props.buyJourney.step_buy_contract_and_notary_appointment
          .buy_contract_draft.is_accepted_by_user === null
    )

    const emitClicked = (is_accepted_by_user: boolean) => {
      emit('clicked', { is_accepted_by_user })
    }

    return {
      hasAcceptedBuyContractDraft,
      hasDeclinedBuyContractDraft,
      hasNotDecidedAboutBuyContractDraft,
      emitClicked,
      t,
      TC,
    }
  },
})
</script>
