<template>
  <div
    class="inline-flex space-x-2 items-center"
    v-tippy
    :content="icon.help_text"
  >
    <img class="h-auto w-10" :src="icon.icon_file_path" />
    <span
      class="text-white font-bold text-xs"
      :class="dark ? 'text-primary' : ''"
      >{{ icon.value }}</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RealEstateIconModelInterface } from '@/models/real-estate-icon.model'

export default defineComponent({
  props: {
    icon: {
      type: Object as PropType<RealEstateIconModelInterface>,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
