
import VerticalDivider from '@/components/common/vertical-divider.vue'
import { defineComponent } from 'vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'

export default defineComponent({
  components: {
    VerticalDivider,
  },
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
})
