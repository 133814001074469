<template>
  <div class="flex justify-center items-center relative shadow-md rounded-full">
    <svg :width="size" :height="size">
      <circle
        class="text-light-gray"
        :cx="size / 2"
        :cy="size / 2"
        :r="size / 2 - strokeWidth"
        stroke="currentColor"
        :stroke-width="strokeWidth"
        :stroke-dasharray="zeroValue"
        :stroke-dashoffset="0"
        fill="white"
      />
      <circle
        class="text-gray"
        :cx="size / 2"
        :cy="size / 2"
        :r="size / 2 - strokeWidth"
        stroke="currentColor"
        :stroke-width="strokeWidth"
        :stroke-dasharray="zeroValue"
        :stroke-dashoffset="progressValue.progress"
        fill="white"
      />
    </svg>
    <p class="absolute text-2xs">{{ label }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 24,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
    progress: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const zeroValue = 2 * Math.PI * (props.size / 2 - props.strokeWidth)
    const progressValue = ref({ progress: zeroValue })

    watch(
      props,
      () => {
        const newProgress = (1 - props.progress) * zeroValue
        anime({
          targets: progressValue.value,
          progress: newProgress,
          duration: 500,
          easing: 'easeInOutQuad',
        })
      },
      { immediate: true }
    )

    return {
      zeroValue,
      progressValue,
    }
  },
})
</script>

<style lang="scss" scoped>
svg {
  transform: rotate(-90deg);
}
</style>
