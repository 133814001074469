
import { defineComponent, onMounted, PropType } from 'vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import CheckBox from '@/components/common/form/checkbox.vue'
import NotaryAppointment from '@/components/buy-journey/steps/step-buy-contract-and-notary-appointment/notary-appointment.vue'
import BuyContractDraft from '@/components/buy-journey/steps/step-buy-contract-and-notary-appointment/buy-contract-draft.vue'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: { CheckBox, NotaryAppointment, BuyContractDraft },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props) {
    const t = useTranslation()
    const toast = useToast()
    const { getMessage } = useError()

    const updateInitialStepValues = () => {
      if (
        !props.buyJourney.step_buy_contract_and_notary_appointment
          .has_visited_step
      ) {
        BuyJourneyModule.updateBuyContractAndNotaryAppointmentStep({
          buyJourneyId: props.buyJourney.id,
          stepId: props.buyJourney.step_buy_contract_and_notary_appointment.id,
          payload: {
            has_visited_step: true,
            is_expanded: true,
          },
        }).catch(error => {
          toast.error(getMessage(error))
        })
      }
    }

    const updateBuyContractAndNotaryAppointmentStep = (payload: object) => {
      BuyJourneyModule.updateBuyContractAndNotaryAppointmentStep({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_buy_contract_and_notary_appointment.id,
        payload: payload,
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    const updateNotaryAppointment = (payload: object) => {
      BuyJourneyModule.updateNotaryAppointment({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_buy_contract_and_notary_appointment.id,
        notaryAppointmentId:
          props.buyJourney.step_buy_contract_and_notary_appointment
            .notary_appointment.id,
        payload: payload,
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    const updateBuyContractDraft = (payload: object) => {
      BuyJourneyModule.updateBuyContractDraftDocuments({
        buyJourneyId: props.buyJourney.id,
        stepId: props.buyJourney.step_buy_contract_and_notary_appointment.id,
        buyContractDraftId:
          props.buyJourney.step_buy_contract_and_notary_appointment
            .buy_contract_draft.id,
        payload: payload,
      }).catch(error => {
        toast.error(getMessage(error))
      })
    }

    onMounted(() => {
      updateInitialStepValues()
    })

    return {
      updateBuyContractAndNotaryAppointmentStep,
      updateNotaryAppointment,
      updateBuyContractDraft,
      t,
      TC,
    }
  },
})
