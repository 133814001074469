import { computed, ComputedRef } from 'vue'

export interface UseNumbers {
  priceFormatted: ComputedRef<string>
}

export default function useNumbers(number: number): UseNumbers {
  function numberFormatted(x: number): string {
    return x.toGermanNumber()
  }
  const priceFormatted = computed((): string => {
    return numberFormatted(number)
  })
  return {
    priceFormatted,
  }
}
