
import { computed, defineComponent, watch } from 'vue'
import ErrorMessage from '@/components/common/form/error-message.vue'
import { useField } from 'vee-validate'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    modelValue: {
      type: String,
    },
    rules: [Object, String],
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputName = computed(() => props.name || ''.toUUID())
    const { value: v, handleInput, errorMessage, errors } = useField(
      inputName,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.value,
      }
    )

    const updateValue = event => {
      emit('update:modelValue', event.target.value)
      emit('input', event)
      handleInput(event.target.value)
    }

    watch(props, () => {
      handleInput(props.value)
    })

    return {
      updateValue,
      v,
      errorMessage,
      errors,
    }
  },
})
