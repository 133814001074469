
import { computed, defineComponent, PropType } from 'vue'
import AccordionProgress from '@/components/buy-journey/accordion-progress.vue'
import Accordion from '@/components/buy-journey/accordion.vue'
import StepExpose from '@/components/buy-journey/steps/step-expose.vue'
import StepCalculation from '@/components/buy-journey/steps/step-calculation.vue'
import StepQuestion from '@/components/buy-journey/steps/step-question.vue'
import StepVisitation from '@/components/buy-journey/steps/step-visitation.vue'
import StepFinancing from '@/components/buy-journey/steps/step-financing.vue'
import StepAdditionalDocuments from '@/components/buy-journey/steps/step-additional-documents.vue'
import StepBuyContractAndNotaryAppointment from '@/components/buy-journey/steps/step-buy-contract-and-notary-appointment.vue'
import StepLoanContract from '@/components/buy-journey/steps/step-loan-contract.vue'
import StepNotaryAppointment from '@/components/buy-journey/steps/step-transfer-of-ownership.vue'
import useTranslation from '@/composables/use-translation'
import * as Translations from '@/constants/translation-constants'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import { BaseStepModelInterface } from '@/models/buy-journey-steps/base/base-step.model'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'

export default defineComponent({
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  components: {
    Accordion,
    AccordionProgress,
    StepExpose,
    StepCalculation,
    StepQuestion,
    StepVisitation,
    StepAdditionalDocuments,
    StepBuyContractAndNotaryAppointment,
    StepLoanContract,
  },
  setup(props) {
    const t = useTranslation()

    const calculateProgress = (steps: Array<BaseStepModelInterface>) => {
      const totalSteps = steps.length
      const completedSteps = steps.filter(step => step.is_completed).length

      if (completedSteps > 0) {
        return Math.floor((completedSteps * 100) / totalSteps)
      }
      return 0
    }

    const sections = computed(() => [
      {
        title: t(Translations.OBJECT_PRESENTATION),
        steps: [
          {
            title: t(Translations.EXPOSE),
            component: StepExpose,
            model: props.buyJourney.step_expose,
            updateMethod: BuyJourneyModule.updateExposeStep,
          },
          {
            title: t(Translations.INVESTMENT_CALCULATION),
            component: StepCalculation,
            model: props.buyJourney.step_calculation,
            updateMethod: BuyJourneyModule.updateCalculationStep,
          },
        ],
        progress: calculateProgress([
          props.buyJourney.step_expose,
          props.buyJourney.step_calculation,
        ]),
        isUnblocked:
          props.buyJourney.step_expose.is_unblocked ||
          props.buyJourney.step_calculation.is_unblocked,
      },
      {
        title: t(Translations.OBJECT_CHECK),
        steps: [
          {
            title: t(Translations.QUESTIONS),
            component: StepQuestion,
            model: props.buyJourney.step_question,
            updateMethod: BuyJourneyModule.updateQuestionStep,
          },
          {
            title: t(Translations.VISITATION),
            component: StepVisitation,
            model: props.buyJourney.step_visitation,
            updateMethod: BuyJourneyModule.updateVisitationStep,
          },
          {
            title: t(Translations.FINANCING),
            component: StepFinancing,
            model: props.buyJourney.step_financing,
            updateMethod: BuyJourneyModule.updateFinancingStep,
          },
          {
            title: t(Translations.ADDITIONAL_DOCUMENTS),
            component: StepAdditionalDocuments,
            model: {
              id: 'additional',
              is_unblocked:
                props.buyJourney.real_estate.additional_document !== null,
              is_completed: false,
              is_completable: false,
              is_expanded: false,
            },
            updateMethod: null,
          },
        ],
        progress: calculateProgress([
          props.buyJourney.step_question,
          props.buyJourney.step_visitation,
          props.buyJourney.step_financing,
        ]),
        isUnblocked: props.buyJourney.step_question.is_unblocked,
      },
      {
        title: t(Translations.INVESTMENT),
        steps: [
          {
            title: t(Translations.BUY_CONTRACT_DRAFT_AND_NOTARY_APPOINTMENT),
            component: StepBuyContractAndNotaryAppointment,
            model: props.buyJourney.step_buy_contract_and_notary_appointment,
            updateMethod:
              BuyJourneyModule.updateBuyContractAndNotaryAppointmentStep,
          },
          {
            title: t(Translations.LOAN_CONTRACTS),
            component: StepLoanContract,
            model: props.buyJourney.step_loan_contract,
            updateMethod: BuyJourneyModule.updateLoanContractStep,
          },
          {
            title: t(Translations.TRANSFER_OF_OWNERSHIP),
            component: StepNotaryAppointment,
            model: props.buyJourney.step_transfer_of_ownership,
            updateMethod: BuyJourneyModule.updateNotaryAppointmentStep,
          },
        ],
        progress: calculateProgress(
          props.buyJourney.step_loan_contract.is_completable
            ? [
                props.buyJourney.step_buy_contract_and_notary_appointment,
                props.buyJourney.step_loan_contract,
                props.buyJourney.step_transfer_of_ownership,
              ]
            : [
                props.buyJourney.step_buy_contract_and_notary_appointment,
                props.buyJourney.step_transfer_of_ownership,
              ]
        ),
        isUnblocked:
          props.buyJourney.step_buy_contract_and_notary_appointment
            .is_unblocked,
      },
    ])
    return {
      sections,
    }
  },
})
