
import Form from '@/components/common/form/form.vue'
import Input from '@/components/common/form/input.vue'
import Button from '@/components/common/form/button.vue'
import Link from '@/components/common/link.vue'
import ErrorMessage from '@/components/common/form/error-message.vue'
import SpinnerLoader from '@/components/common/loader/spinner-loader.vue'
import useTranslation from '@/composables/use-translation.ts'
import { computed, defineComponent, ref } from 'vue'
import { UserModule } from '@/store/modules/user.module.ts'
import * as TC from '@/constants/translation-constants.ts'
import { string } from 'yup'
import { useRouter } from 'vue-router'
import { DASHBOARD, FORGOT_PASSWORD } from '@/constants/router-constants'
import { LANDING_PAGE_URL } from '@/constants/common.ts'
import { LoadingModule } from '@/store/modules/loading.module'
import { useForm } from 'vee-validate'

export default defineComponent({
  components: {
    Input,
    Button,
    Link,
    Form,
    ErrorMessage,
    SpinnerLoader,
  },
  setup() {
    const t = useTranslation()
    const router = useRouter()
    const { validate, values } = useForm()
    const errorMessage = ref('')
    const isLoading = computed(() => LoadingModule.isLoginLoading)
    const formData = {
      email: {
        type: 'email',
        name: 'email',
        label: t(TC.EMAIL),
        rules: string()
          .required(t(TC.EMAIL_IS_REQUIRED))
          .email(t(TC.EMAIL_MUST_BE_VALID)),
      },
      password: {
        type: 'password',
        name: 'password',
        label: t(TC.PASSWORD),
        rules: string().required(t(TC.PASSWORD_IS_REQUIRED)),
      },
    }
    const hasValues = computed(() => {
      return (
        (values.email?.length ?? 0) > 0 && (values.password?.length ?? 0) > 0
      )
    })

    const submitLoginForm = async () => {
      if (!hasValues.value) {
        return
      }

      errorMessage.value = ''
      const validationResult = await validate()
      if (validationResult.valid) {
        const { email, password } = values
        UserModule.login({
          email,
          password,
        })
          .then(async () => {
            const redirectTo =
              router.currentRoute.value.query.next?.toString() ?? DASHBOARD.path
            await router.push({ path: redirectTo })
          })
          .catch(() => {
            errorMessage.value = t(TC.WRONG_EMAIL_OR_PASSWORD)
          })
      }
    }

    return {
      submitLoginForm,
      formData,
      errorMessage,
      hasValues,
      forgotPasswordUrlPath: FORGOT_PASSWORD.path,
      landingPageUrl: LANDING_PAGE_URL,
      t,
      TC,
      isLoading,
    }
  },
})
