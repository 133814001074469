<template>
  <div class="text-xs mt-2" :class="disabled && 'opacity-50'">
    <ul class="relative border w-full p-2 h-32 overflow-y-auto">
      <li
        v-for="(document, index) of filesToUpload"
        :key="index"
        class="flex items-center"
      >
        <div class="ml-2 flex justify-center items-center overflow-x-hidden">
          <div
            @click="removeUploadItem(index)"
            class="flex justify-center items-center h-6 w-6"
          >
            <fa class="w-3 text-grey z-50" :icon="deleteIcon" />
          </div>
          <p class="ml-2 truncate">{{ document.name }}</p>
        </div>
      </li>
      <div @dragenter="onDragEnter" class="absolute top-0 left-0 w-full h-full">
        <div
          class="relative flex flex-col justify-center items-center w-full h-full"
        >
          <transition name="fade" mode="out-in">
            <div
              v-if="isDraggingOver"
              class="flex flex-col justify-center items-center bg-white bg-opacity-75 w-full h-full"
            >
              <p>Legen Sie Ihre Dateien nun ab.</p>
              <fa class="h-6 text-grey mt-2" :icon="uploadIcon" />
            </div>
            <div
              v-else-if="!filesToUpload.length"
              class="flex flex-col justify-center items-center w-full h-full"
            >
              <p>{{ placeholderText }}</p>
              <fa class="h-6 text-grey mt-2" :icon="uploadIcon" />
            </div>
          </transition>

          <input
            class="absolute left-0 top-0 w-full h-full outline-none opacity-0"
            type="file"
            multiple
            :disabled="disabled"
            @change="onFilesAdded"
            @dragleave="onDragLeave"
            @drop="onDrop"
          />
        </div>
      </div>
    </ul>
    <Button
      @click="$emit('on-upload', Array.from(filesToUpload), resetFileList)"
      class="mt-4"
      :disabled="!filesToUpload.length || disabled"
      >Hochladen</Button
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import * as Icons from '@/constants/icon-constants'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  emits: ['on-upload'],
  components: { Button },
  props: {
    placeholderText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const filesToUpload = ref<File[]>([])
    const isDraggingOver = ref(false)
    const onFilesAdded = event => {
      filesToUpload.value = filesToUpload.value.concat(
        Array.from(event.target.files)
      )
    }
    const removeUploadItem = (index: number) => {
      filesToUpload.value.splice(index, 1)
    }
    const onDragEnter = () => {
      isDraggingOver.value = true
    }
    const onDragLeave = () => {
      isDraggingOver.value = false
    }
    const onDrop = () => {
      isDraggingOver.value = false
    }
    const resetFileList = () => {
      filesToUpload.value = []
    }

    return {
      filesToUpload,
      onFilesAdded,
      removeUploadItem,
      onDragEnter,
      onDragLeave,
      onDrop,
      isDraggingOver,
      resetFileList,
      uploadIcon: Icons.UPLOAD,
      deleteIcon: Icons.TRASH,
    }
  },
})
</script>
