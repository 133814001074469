<template>
  <div class="flex flex-col">
    <p v-if="!hasCompletedSelfFinancing" class="text-xs font-bold">
      {{ t(TC.TO_FINISH_CONFIRM_THE_CONDITIONS) }}
    </p>

    <transition name="fade-in" mode="out-in">
      <div
        v-if="hasCompletedSelfFinancing"
        class="flex items-center space-x-2 mt-2"
      >
        <fa class="w-8 text-green" icon="check" />
        <p class="text-xs">
          {{ t(TC.THANK_YOU_FOR_TAKING_CARE_OF_INVESTMENT) }}
        </p>
      </div>
      <div v-else class="flex flex-col space-y-2 mt-2">
        <transition name="fade-in">
          <CheckBox
            v-if="!buyJourney.step_financing.has_taken_care_of_financing"
            @changed="emitTakenCare($event)"
            :label="t(TC.I_HAVE_TAKEN_CARE_OF_FINANCING)"
            name="taken-care"
          />
        </transition>
        <transition name="fade-in">
          <CheckBox
            v-if="
              !buyJourney.step_financing
                .has_received_external_offer_and_contract
            "
            @changed="emitReceivedOffer($event)"
            :label="t(TC.I_HAVE_RECEIVED_A_MATCHING_OFFER)"
            name="received-offer"
          />
        </transition>
      </div>
    </transition>

    <p class="text-xs mt-4">
      {{ t(TC.IF_YOU_WANNA_CHECK_YOUR_OFFERS_UPLOAD_THEM_HERE) }}
    </p>
    <div class="relative">
      <FileUploadList
        @on-upload="emitUpload"
        :placeholderText="t(TC.UPLOAD_YOUR_FINANCING_DOCUMENTS_HERE)"
      />
      <OverlaySpinnerLoader :isLoading="isUploading" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import CheckBox from '@/components/common/form/checkbox.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import OverlaySpinnerLoader from '@/components/common/loader/overlay-spinner-loader.vue'
import { LoadingModule } from '@/store/modules/loading.module'

export default defineComponent({
  emits: ['checked-taken-care', 'checked-received-offers', 'on-upload'],
  components: { CheckBox, FileUploadList, OverlaySpinnerLoader },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()
    const hasCompletedSelfFinancing = computed(
      () =>
        props.buyJourney.step_financing.has_taken_care_of_financing &&
        props.buyJourney.step_financing.has_received_external_offer_and_contract
    )
    const isUploading = computed(
      () => LoadingModule.isUploadFinancingDocumentsLoading
    )

    const emitTakenCare = has_taken_care_of_financing => {
      if (typeof has_taken_care_of_financing === 'boolean') {
        emit('checked-taken-care', {
          has_taken_care_of_financing,
        })
      }
    }

    const emitReceivedOffer = has_received_external_offer_and_contract => {
      if (typeof has_received_external_offer_and_contract === 'boolean') {
        emit('checked-received-offers', {
          has_received_external_offer_and_contract,
        })
      }
    }

    const emitUpload = (files: File[], resetCallback: Function) => {
      emit('on-upload', files, resetCallback)
    }

    return {
      emitTakenCare,
      emitReceivedOffer,
      emitUpload,
      isUploading,
      hasCompletedSelfFinancing,
      t,
      TC,
    }
  },
})
</script>
