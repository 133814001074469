<template>
  <h1
    class="font-poppins p-2 uppercase text-base md:text-2xl text-center text-light-red text-white md:font-light md:text-left"
  >
    {{ text }}
  </h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
})
</script>
