<template>
  <div
    class="flex justify-center py-8 w-full bg-primary md:p-5 md:m-0 md:text-primary"
  >
    <img
      class="h-20 md:h-24"
      src="@/assets/logo_mobile.svg"
      alt="Herzberg Immobilien"
    />
  </div>
</template>

<script lang="ts">
import useTranslation from '@/composables/use-translation'
import { defineComponent } from 'vue'
import * as TC from '@/constants/translation-constants.ts'

export default defineComponent({
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
})
</script>
