import { AddressModelInterface } from './address.model'
import { BaseDocumentGroupModelInterface } from './buy-journey-steps/base/base-document-group.model'
import { RealEstateIconModelInterface } from './real-estate-icon.model'
import { RealEstateImageModelInterface } from './real-estate-image.model'
import { RealEstateRatingModelInterface } from './real-estate-rating.model'

export interface RealEstateModelInterface {
  id: string
  name: string
  price: number
  letter_of_interest: string
  consulation_protocol_and_disclaimer: string
  cancellation_policy: string
  description: string
  plain_text_description: string
  has_financing_partner: boolean
  financing_partner_hint: string
  address: AddressModelInterface
  images: RealEstateImageModelInterface[]
  main_image: RealEstateImageModelInterface
  icons: RealEstateIconModelInterface[]
  ratings: RealEstateRatingModelInterface[]
  additional_document: BaseDocumentGroupModelInterface
}

export const RealEstateModelFunctions = {
  getPriceFormattedOf(model: RealEstateModelInterface) {
    return model.price.toGermanNumber()
  },
}
