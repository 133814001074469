<template>
  <p class="font-roboto text-xs text-light-gray text-center md:text-left mb-6">
    {{ text }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
    },
  },
})
</script>
