
import MainNavigation from '@/components/main-navigation/index.vue'
import MainHeader from '@/components/main-header/index.vue'
import Avatar from '@/components/profile/common/avatar.vue'
import { defineComponent, onMounted, computed } from 'vue'
import useResize from '@/composables/use-resize'
import useCrisp from '@/composables/use-crisp'
import { UserModule } from '@/store/modules/user.module'
import Footer from '@/components/dashboard/footer/index.vue'
import { useRoute } from 'vue-router'
import * as CommonConstants from '@/constants/common'
import * as Routes from '@/constants/router-constants'

export default defineComponent({
  components: {
    MainNavigation,
    MainHeader,
    Avatar,
    Footer,
  },
  setup() {
    const { isMobile, onResize } = useResize()
    const route = useRoute()
    const user = computed(() => UserModule.user)
    const hideHeaderOnMobile = computed(() => {
      return !route.meta.customHeader.showOn.includes(CommonConstants.MOBILE)
    })
    const hideHeaderOnDesktop = computed(() => {
      return !route.meta.customHeader.showOn.includes(CommonConstants.DESKTOP)
    })

    const hideTitleOnMobile = computed(() => {
      return !route.meta.showOnMobile && route.name === Routes.PROFILE.name
    })

    const {
      initCrisp,
      hideCrispButton,
      showCrispButton,
      hideCrispButtonOnChatClose,
      showCrispButtonOnChatClose,
    } = useCrisp()

    function hideOrShowCrispButton() {
      if (isMobile.value) {
        hideCrispButton()
        hideCrispButtonOnChatClose()
      } else {
        showCrispButton()
        showCrispButtonOnChatClose()
      }
    }

    onResize(() => {
      hideOrShowCrispButton()
    })

    onMounted(() => {
      initCrisp().then(() => {
        hideOrShowCrispButton()
      })
    })

    return {
      user,
      hideHeaderOnMobile,
      hideHeaderOnDesktop,
      hideTitleOnMobile,
      Routes,
    }
  },
})
