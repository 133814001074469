<template>
  <div class="flex flex-col px-4 pb-4 max-w-3xl mx-auto">
    <div class="flex items-center mt-2">
      <fa
        @click="$emit('back')"
        class="w-6 h-6 text-primary cursor-pointer"
        :icon="angleLeftIcon"
      />
      <p class="ml-4">{{ confirmationType }}</p>
    </div>
    <div
      class="border p-2 mt-2 font-roboto text-sm wysiwyg-content"
      v-html="text"
    ></div>
    <div class="mt-2">
      <CheckBox
        :label="t(TC.CONFIRM_CONDITIONS)"
        v-model="isChecked"
        name="confirm-conditions"
      />
    </div>
    <Button :disabled="!isChecked" @click="$emit('clicked')" class="mt-2">{{
      buttonLabel
    }}</Button>
  </div>
</template>

<script lang="ts">
import Button from '@/components/common/form/button.vue'
import CheckBox from '@/components/common/form/checkbox.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import { defineComponent, onMounted, ref } from 'vue'
import * as Icons from '@/constants/icon-constants'

export default defineComponent({
  name: 'ProposalConfirmation',
  components: { Button, CheckBox },
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    confirmationType: {
      type: String,
      required: true,
      default: '',
    },
    buttonLabel: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    const t = useTranslation()
    const isChecked = ref(false)

    onMounted(() => {
      const modalScrollElement: HTMLElement | null = document.querySelector(
        '.modal-scroll'
      )
      if (modalScrollElement) {
        modalScrollElement.scrollTo({ top: 0 })
      }
    })

    return {
      isChecked,
      angleLeftIcon: Icons.ANGLE_LEFT_ICON,
      t,
      TC,
    }
  },
})
</script>
