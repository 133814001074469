import Router from '@/router'
import { filter } from 'lodash'
import { RoutePlacementEnum } from '@/enums/route-placement-enum'
import { RouteRecordNormalized } from 'vue-router'

interface UseRouter {
  mainNavigationRoutes: RouteRecordNormalized[]
}

export default function useRouter(): UseRouter {
  const mainNavigationRoutes: Array<RouteRecordNormalized> = filter(
    Router.getRoutes(),
    (route: RouteRecordNormalized) => {
      return route.meta.placement == RoutePlacementEnum.MAIN_NAVIGATION
    }
  )

  return {
    mainNavigationRoutes,
  } as UseRouter
}
