
import { computed, defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import FileList from '@/components/common/list/file-list.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import FinancingOffer from '@/components/buy-journey/steps/step-financing/financing-offer.vue'

export default defineComponent({
  emits: ['on-upload'],
  components: { FileList, FileUploadList, FinancingOffer },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()
    const hasAcceptedFinancingOffer = computed(() => {
      return props.buyJourney.step_financing.financing_offer
        ?.is_accepted_by_user
    })

    const emitUpload = (files: File[], resetCallback: Function) => {
      emit('on-upload', files, resetCallback)
    }

    return {
      emitUpload,
      hasAcceptedFinancingOffer,
      t,
      TC,
    }
  },
})
