
import { defineComponent, computed, PropType } from 'vue'
import { UserModule } from '@/store/modules/user.module'
import useTranslation from '@/composables/use-translation.ts'
import * as TC from '@/constants/translation-constants.ts'
import Input from '@/components/common/form/input.vue'
import { string } from 'yup'
import { UserModelInterface } from '@/models/user.model'
import * as propertyConstants from '@/constants/property-constants.ts'

export default defineComponent({
  components: {
    Input,
  },
  props: {
    user: {
      type: Object as PropType<UserModelInterface>,
      required: true,
    },
  },
  setup() {
    const t = useTranslation()
    const isProfileEditable = computed(() => UserModule.isProfileEditable)
    const phoneNumberRegex = /(\+|[0]{1,2})[0-9]+/

    const formData = {
      firstName: {
        name: propertyConstants.FIRST_NAME,
        label: t(TC.FIRST_NAME),
        rules: string().required(t(TC.FIRST_NAME_FORM_VALIDATION_ERROR)),
      },
      lastName: {
        name: propertyConstants.LAST_NAME,
        label: t(TC.LAST_NAME),
        rules: string().required(t(TC.LAST_NAME_FORM_VALIDATION_ERROR)),
      },
      phone: {
        type: 'tel',
        name: propertyConstants.PHONE,
        label: t(TC.PHONE),
        rules: string()
          .required(t(TC.PHONE_REQUIRED_FORM_VALIDATION_ERROR))
          .matches(phoneNumberRegex, t(TC.PHONE_FORMAT_FORM_VALIDATION_ERROR)),
      },
      email: {
        type: 'email',
        name: propertyConstants.EMAIL,
        label: t(TC.EMAIL),
        rules: string().required(t(TC.EMAIL_FORM_VALIDATION_ERROR)),
      },
      birthday: {
        name: propertyConstants.BIRTHDAY,
        label: t(TC.BIRTHDAY),
        rules: string()
          .typeError(TC.BIRTHDAY_FORMAT_INCORRECT)
          .required(t(TC.BIRTHDAY_FORM_VALIDATION_ERROR))
          .test('validDate', t(TC.BIRTHDAY_FORMAT_INCORRECT), value => {
            const dateRegex = /\d{4}-\d{2}-\d{2}/
            return dateRegex.test(value ?? '')
          }),
      },
    }

    return {
      isProfileEditable,
      t,
      TC,
      formData,
    }
  },
})
