<template>
  <div>
    <div class="flex flex-col items-center pb-8 space-y-6">
      <Image class="w-95% max-w-xs pt-4" src="realestate-not-found.svg" />
      <p
        class="w-95% max-w-xs pb-2 text-primary font-bold text-lg font-poppins leading-tight text-center"
      >
        {{ t(TC.NO_INVESTMENTS_FOUND) }}
      </p>
      <div
        v-if="!wantsNewsletter"
        class="flex flex col justify-center w-98% pb-4"
      >
        <CheckAndSubmitCondition
          :conditionTitle="t(TC.ACTIVATE_NOTIFICATION)"
          :conditionDescription="t(TC.NOTIFICATION_DESCRIPTION)"
          :buttonText="t(TC.ACCEPT)"
        />
      </div>
      <div v-else class="flex justify-center">
        <p class="text-sm text-center text-light-gray">
          {{ t(TC.YOU_ARE_ALREADY_SUBSCRIBED) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Image from '@/components/common/image.vue'
import CheckAndSubmitCondition from '@/components/common/form/check-and-submit-condition.vue'
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants'
import { UserModule } from '@/store/modules/user.module'

export default defineComponent({
  components: {
    Image,
    CheckAndSubmitCondition,
  },
  setup() {
    const t = useTranslation()
    const wantsNewsletter = computed(
      () => UserModule.user?.user_search_profile?.wants_newsletter ?? {}
    )

    return {
      wantsNewsletter,
      t,
      TC,
    }
  },
})
</script>
