export interface AdvisorInterfaceModel {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  description: string
  job: string
  avatar: string
}

export const AdvisorModelFunctions = {
  getFullName(model: AdvisorInterfaceModel | undefined): string {
    return `${model?.first_name} ${model?.last_name}`
  },
}
