<template>
  <slot v-if="!isLoading"></slot>

  <div
    v-else
    class="skeleton-loader max-w-full flex mx-auto px-4 overflow-x-scroll"
  >
    <div :class="`w-${itemWidth} h-${itemHeight}`">
      <Skeletor :class="`w-${itemWidth}`" height="100%" />
    </div>
    <div
      class="flex"
      :class="`w-${itemWidth} h-${itemHeight} ml-${itemDistance}`"
    >
      <Skeletor :class="`w-${itemWidth}`" height="100%" />
    </div>
    <div
      class="flex"
      :class="`w-${itemWidth} h-${itemHeight} ml-${itemDistance}`"
    >
      <Skeletor height="100%" :class="`w-${itemWidth}`" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Skeletor } from 'vue-skeletor'

export default defineComponent({
  components: { Skeletor },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    itemHeight: {
      type: Number,
      default: 32,
    },
    itemWidth: {
      type: Number,
      default: 32,
    },
    itemDistance: {
      type: Number,
      default: 4,
    },
  },
})
</script>
