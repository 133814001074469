
import { computed, defineComponent, PropType } from 'vue'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import CheckBox from '@/components/common/form/checkbox.vue'
import FileUploadList from '@/components/common/list/file-upload-list.vue'
import OverlaySpinnerLoader from '@/components/common/loader/overlay-spinner-loader.vue'
import { LoadingModule } from '@/store/modules/loading.module'

export default defineComponent({
  emits: ['checked-taken-care', 'checked-received-offers', 'on-upload'],
  components: { CheckBox, FileUploadList, OverlaySpinnerLoader },
  props: {
    buyJourney: {
      type: Object as PropType<BuyJourneyModelInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const t = useTranslation()
    const hasCompletedSelfFinancing = computed(
      () =>
        props.buyJourney.step_financing.has_taken_care_of_financing &&
        props.buyJourney.step_financing.has_received_external_offer_and_contract
    )
    const isUploading = computed(
      () => LoadingModule.isUploadFinancingDocumentsLoading
    )

    const emitTakenCare = has_taken_care_of_financing => {
      if (typeof has_taken_care_of_financing === 'boolean') {
        emit('checked-taken-care', {
          has_taken_care_of_financing,
        })
      }
    }

    const emitReceivedOffer = has_received_external_offer_and_contract => {
      if (typeof has_received_external_offer_and_contract === 'boolean') {
        emit('checked-received-offers', {
          has_received_external_offer_and_contract,
        })
      }
    }

    const emitUpload = (files: File[], resetCallback: Function) => {
      emit('on-upload', files, resetCallback)
    }

    return {
      emitTakenCare,
      emitReceivedOffer,
      emitUpload,
      isUploading,
      hasCompletedSelfFinancing,
      t,
      TC,
    }
  },
})
