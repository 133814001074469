
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  emits: ['click'],
  props: {
    type: {
      type: String as PropType<'primary' | 'secondary' | 'alert'>,
      default: 'primary',
    },
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const backgroundClass = computed(() => {
      switch (props.type) {
        case 'primary':
          return 'bg-primary'
        case 'alert':
          return 'bg-alert'
        case 'secondary':
          return 'bg-secondary'
        default:
          return 'bg-primary'
      }
    })

    return {
      backgroundClass,
    }
  },
})
