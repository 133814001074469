
import { defineComponent, ref } from 'vue'
import * as TC from '@/constants/translation-constants.ts'
import useTranslation from '@/composables/use-translation'
import Textarea from '@/components/common/form/textarea.vue'
import Button from '@/components/common/form/button.vue'

export default defineComponent({
  components: { Textarea, Button },
  emits: ['click'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const t = useTranslation()
    const questionText = ref('')

    const setQuestionText = event => {
      questionText.value = event.target.value
    }

    const resetTextArea = () => {
      questionText.value = ''
    }

    return {
      questionText,
      setQuestionText,
      resetTextArea,
      t,
      TC,
    }
  },
})
