<template>
  <div
    class="flex items-center text-sm px-3 py-3 w-full h-full"
    :class="{ 'border border-collapse border-light-blue': withBorder }"
  >
    <div>
      <label class="text-sm">
        <div class="grid checkbox__input">
          <input
            class="mr-3 leading-tight bg-primary checked:bg-primary checked:border-transparent w-4 h-4 opacity-0"
            type="checkbox"
            :name="name"
            :disabled="disabled"
            :checked="checked || value"
            :rules="rules"
            @input="updateValue"
          />
          <div
            v-if="modelValue || value"
            class="rounded-full w-5 h-5 border-6 my-auto"
            aria-hidden="true"
            focusable="false"
          ></div>
          <div
            v-else
            class="rounded-full w-5 h-5 border-1 my-auto"
            aria-hidden="true"
            focusable="false"
          ></div>
          <p
            class="col-start-2 row-start-1 text-xs break-words"
            :class="{ truncate: truncateText }"
          >
            {{ label }}
          </p>
        </div>
      </label>
    </div>
    <ErrorMessage :text="errorMessage" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import ErrorMessage from '@/components/common/form/error-message.vue'
import { useField } from 'vee-validate'

export default defineComponent({
  components: {
    ErrorMessage,
  },
  props: {
    label: {
      type: String,
    },
    checked: {
      type: Boolean,
      required: false,
    },
    modelValue: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    rules: [Object, String],
    truncateText: {
      type: Boolean,
      default: false,
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputName = computed(() => props.name || ''.toUUID())
    const { value, handleInput, errorMessage } = useField(
      inputName,
      props.rules,
      {
        validateOnValueUpdate: false,
        validateOnMount: false,
        initialValue: props.checked,
      }
    )

    const updateValue = event => {
      emit('changed', event.target.checked)
      emit('update:modelValue', event.target.checked)
      handleInput(event.target.checked)
    }

    watch(props, () => {
      handleInput(props.checked)
    })

    return {
      updateValue,
      value,
      errorMessage,
      inputName,
    }
  },
})
</script>

<style lang="scss" scoped>
.checkbox__input {
  display: grid;
  grid-template-areas: 'checkbox';

  > div,
  input {
    grid-area: checkbox;
  }
}
</style>
