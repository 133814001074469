<template>
  <div
    class="
           px-4 mx-auto
           md:max-w-full w-full max-w-3xl
           "
  >
    <vertical-list-skeleton-loader
      :isLoading="isLoading"
      :itemHeight="64"
      :itemDistance="6"
    >
      <div
        v-if="proposals.length > 0"
        class="md:flex md:flex-row md:flex-wrap md:flex-start"
      >
        <ProposalCard
          class="md:w-72 md:h-96 md:mx-3 md:my-3"
          v-for="proposal in proposals"
          :key="proposal.id"
          :proposal="proposal"
          @open="openModal"
        />
      </div>
      <ProposalsNotFoundPlaceholder v-else />
    </vertical-list-skeleton-loader>

    <router-view v-slot="{ Component }">
      <transition name="fade-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount } from 'vue'
import ProposalCard from '@/components/proposals/proposal-card/index.vue'
import { BuyJourneyModule } from '@/store/modules/buy-journey.module'
import { useRouter } from 'vue-router'
import { ProposalModelInterface } from '@/models/proposal.model'
import { PROPOSAL_DETAIL } from '@/constants/router-constants'
import ProposalsNotFoundPlaceholder from '@/components/proposals/proposals-not-found-placeholder/index.vue'
import { LoadingModule } from '@/store/modules/loading.module'
import { useToast } from 'vue-toastification'
import useError from '@/composables/use-error'

export default defineComponent({
  components: {
    ProposalCard,
    ProposalsNotFoundPlaceholder,
  },
  setup() {
    const { push } = useRouter()
    const isLoading = computed(() => LoadingModule.isProposalsListLoading)
    const toast = useToast()
    const { getMessage } = useError()

    const proposals = computed(() => BuyJourneyModule.proposals)
    const openModal = (proposal: ProposalModelInterface) => {
      // Persist scroll position, before routing. Reset in `closeModal`
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
      push({
        name: PROPOSAL_DETAIL.name,
        params: { proposal_id: proposal.id },
      })
    }

    onBeforeMount(async () => {
      BuyJourneyModule.getProposals().catch(error => {
        toast.error(getMessage(error))
      })
    })

    return {
      proposals,
      openModal,
      isLoading,
    }
  },
})
</script>
