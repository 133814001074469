<template>
  <div class="bg-primary py-8 space-y-8">
    <HeaderWithLogoAuthScreen />
    <div class="w-full flex flex-col justify-center">
      <p class="text-center text-white text-sm">{{ t(TC.LOGIN) }}</p>
    </div>
  </div>
  <div class="w-full max-w-3xl mx-auto px-8 mt-12">
    <SignInForm />
  </div>
</template>

<script lang="ts">
import SignInForm from '@/components/authorization/sign-in-form.vue'
import HeaderWithLogoAuthScreen from '@/components/main-header/header-with-logo-auth-screen.vue'
import useTranslation from '@/composables/use-translation'
import { defineComponent } from 'vue'
import * as TC from '@/constants/translation-constants'

export default defineComponent({
  components: {
    SignInForm,
    HeaderWithLogoAuthScreen,
  },
  setup() {
    const t = useTranslation()
    return {
      t,
      TC,
    }
  },
})
</script>
