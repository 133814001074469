import { BuyContractDraftDocumentGroupPayloadInterface } from '@/models/buy-journey-steps/step-buy-contract-and-notary-appointment/buy-contract-draft-document-group.model'
import { BuyJourneyStepBuyContractAndNotaryAppointmentPayloadInterface } from '@/models/buy-journey-steps/step-buy-contract-and-notary-appointment/buy-journey-step-buy-contract-and-notary-appointment.model'
import { NotaryAppointmentPatchPayloadInterface } from '@/models/buy-journey-steps/step-buy-contract-and-notary-appointment/notary-appointment.model'
import { BuyJourneyStepCalculationPatchPayloadInterface } from '@/models/buy-journey-steps/step-calculation/buy-journey-step-calculation.model'
import { BuyJourneyStepExposePatchPayloadInterface } from '@/models/buy-journey-steps/step-expose/buy-journey-step-expose.model'
import { BuyJourneyStepFinancingPayloadInterface } from '@/models/buy-journey-steps/step-financing/buy-journey-step-financing.model'
import { FinancingOfferDocumentGroupPayloadInterface } from '@/models/buy-journey-steps/step-financing/financing-offer-document-group.model'
import { BuyJourneyStepLoanContractPayloadInterface } from '@/models/buy-journey-steps/step-loan-contract/buy-journey-step-loan-contract.model'
import { BuyJourneyStepTransferOfOwnershipPayloadInterface } from '@/models/buy-journey-steps/step-transfer-of-ownership/buy-journey-step-transfer-of-ownership.model'
import { BuyJourneyStepQuestionPayloadInterface } from '@/models/buy-journey-steps/step-question/buy-journey-step-question.model'
import { QuestionAnswerPayloadInterface } from '@/models/buy-journey-steps/step-question/question-answer.model'
import { BuyJourneyStepVisitationPayloadInterface } from '@/models/buy-journey-steps/step-visitation/buy-journey-step-visitation.model'
import { UserAppointmentPayloadInterface } from '@/models/buy-journey-steps/step-visitation/user-appointment.model'
import { BuyJourneyModelInterface } from '@/models/buy-journey.model'
import { ProposalModelInterface } from '@/models/proposal.model'
import { request, buildURL } from '@/utils/http/request'

export class BuyJourneyAPI {
  static async getProposals(): Promise<ProposalModelInterface[]> {
    const { data } = await request.get(
      buildURL()
        .join('proposals')
        .get()
    )
    return data
  }

  static async getProposal(id: string): Promise<ProposalModelInterface> {
    const { data } = await request.get(
      buildURL()
        .join('proposals')
        .join(id)
        .get()
    )
    return data
  }

  static async createBuyJourney(
    proposalId: string
  ): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('proposals')
        .join(proposalId)
        .join('buy-journey')
        .get()
    )
    return data
  }

  static async getBuyJourneys(): Promise<BuyJourneyModelInterface[]> {
    const { data } = await request.get(
      buildURL()
        .join('buy-journey')
        .get()
    )
    return data
  }

  static async getBuyJourney(id: string): Promise<BuyJourneyModelInterface> {
    const { data } = await request.get(
      buildURL()
        .join('buy-journey')
        .join(id)
        .get()
    )
    return data
  }

  static async updateExposeStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepExposePatchPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-expose')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateCalculationStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepCalculationPatchPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-calculation')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async createUserCalculationDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-calculation')
        .join(input.stepId)
        .join('user-calculation-documents')
        .get(),
      input.payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    return data
  }

  static async updateQuestionStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepQuestionPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-question')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateQuestionAnswer(input: {
    buyJourneyId: string
    stepId: string
    questionId: string
    payload: QuestionAnswerPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-question')
        .join(input.stepId)
        .join('question-answer')
        .join(input.questionId)
        .get(),
      input.payload
    )
    return data
  }

  static async createQuestionAnswer(input: {
    buyJourneyId: string
    stepId: string
    payload: QuestionAnswerPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-question')
        .join(input.stepId)
        .join('question-answer')
        .get(),
      input.payload
    )
    return data
  }

  static async createUserVisitationAppointment(input: {
    buyJourneyId: string
    stepId: string
    payload: UserAppointmentPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-visitation')
        .join(input.stepId)
        .join('visitation-appointment')
        .get(),
      input.payload
    )
    return data
  }

  static async updateVisitationStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepVisitationPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-visitation')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateFinancingStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepFinancingPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-financing')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async createUserSelfDisclosureDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-financing')
        .join(input.stepId)
        .join('user-documents')
        .get(),
      input.payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    return data
  }
  static async createOwnFinancingDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-financing')
        .join(input.stepId)
        .join('own-financing-offer')
        .get(),
      input.payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    return data
  }

  static async updateFinancingOfferDocuments(input: {
    buyJourneyId: string
    stepId: string
    financingOfferId: string
    payload: FinancingOfferDocumentGroupPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-financing')
        .join(input.stepId)
        .join('financing-offer')
        .join(input.financingOfferId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateBuyContractAndNotaryAppointmentStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepBuyContractAndNotaryAppointmentPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-buy-contract-and-notary-appointment')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateNotaryAppointment(input: {
    buyJourneyId: string
    stepId: string
    notaryAppointmentId: string
    payload: NotaryAppointmentPatchPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-buy-contract-and-notary-appointment')
        .join(input.stepId)
        .join('notary-appointment')
        .join(input.notaryAppointmentId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateBuyContractDraftDocuments(input: {
    buyJourneyId: string
    stepId: string
    buyContractDraftId: string
    payload: BuyContractDraftDocumentGroupPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-buy-contract-and-notary-appointment')
        .join(input.stepId)
        .join('buy-contract-draft')
        .join(input.buyContractDraftId)
        .get(),
      input.payload
    )
    return data
  }

  static async updateLoanContractStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepLoanContractPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-loan-contract')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }

  static async createSelfFinancedDocuments(input: {
    buyJourneyId: string
    stepId: string
    payload: FormData
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.post(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-loan-contract')
        .join(input.stepId)
        .join('own-loan-contract')
        .get(),
      input.payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    return data
  }

  static async updateNotaryAppointmentStep(input: {
    buyJourneyId: string
    stepId: string
    payload: BuyJourneyStepTransferOfOwnershipPayloadInterface
  }): Promise<BuyJourneyModelInterface> {
    const { data } = await request.patch(
      buildURL()
        .join('buy-journey')
        .join(input.buyJourneyId)
        .join('step-transfer-of-ownership')
        .join(input.stepId)
        .get(),
      input.payload
    )
    return data
  }
}
