<template>
  <div
    class="flex justify-center items-center min-h-screen bg-primary text-white"
  >
    <div class="flex flex-col space-y-10 text-center">
      <header-with-logo-auth-screen />
      <h1>{{ t(TC.THE_REQUIRED_RESOURCE_WAS_NOT_FOUND) }}</h1>
      <p @click="backToWebsite" class="cursor-pointer font-bold">
        {{ t(TC.GO_BACK) }}
      </p>
    </div>
  </div>
</template>

<script>
import useTranslation from '@/composables/use-translation'
import * as TC from '@/constants/translation-constants.ts'
import * as Routes from '@/constants/router-constants'
import HeaderWithLogoAuthScreen from '@/components/main-header/header-with-logo-auth-screen'

export default {
  name: '404',
  components: { HeaderWithLogoAuthScreen },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    backToWebsite() {
      if (this.$router.hasRoute(this.prevRoute.name)) {
        this.$router.back()
        return
      }
      console.log(Routes.DASHBOARD.name)
      this.$router.replace({ path: Routes.DASHBOARD.path })
    },
  },
  setup() {
    const t = useTranslation()

    return {
      t,
      TC,
    }
  },
}
</script>

<style scoped></style>
